import React from 'react'
import { Close } from '@mui/icons-material'
import { Box } from '@mui/system'
import { Typography } from '@mui/material'
import moment from 'moment'

export default function ViewChargePopover(props) {
    const { setPopoverVisible, charge } = props

    return (
        <Box
            position={'fixed'}
            top={'0'}
            left={'0'}
            width={'100%'}
            height={'100%'}
            backgroundColor={'rgba(0, 0, 0, 0.5)'}
            sx={{
                backdropFilter: 'blur(5px)',
            }}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            zIndex={'2'}
        >
            <Box
                width={'100%'}
                height={'100%'}
                position={'absolute'}
                top={'0'}
                left={'0'}
                onClick={() => setPopoverVisible(false)}
            ></Box>
            <Box
                zIndex={'1'}
                position={'relative'}
                width={'auto'}
                height={'auto'}
                backgroundColor={'white'}
                borderRadius={'10px'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                padding={'5vh'}
            >
                <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                    alignItems={'flex-start'}
                    width={'100%'}
                    marginBottom={'10px'}
                >
                    <Close
                        position={'static'}
                        color={'black'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={() => setPopoverVisible(false)}
                    />
                </Box>
                {/*TODO: Form Validation */}
                <Box textAlign={'center'}>
                    <Box mb={'2.5vh'}>
                        <Typography variant="h3">Charge Details</Typography>
                    </Box>
                </Box>
                <Box>
                    <Box>
                        <Typography variant="h5">
                            Charge ID: {charge.charge_id}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h5">
                            Charge Amount:{' '}
                            {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                currency: 'USD',
                            }).format(charge.amount)}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h5">
                            Service Date:{' '}
                            {moment
                                .utc(charge.date_serviced)

                                .format('MM/DD/YYYY')}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="h5">
                            Notes: {charge.service_performed}
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
