import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
    titleContainer: {
        marginTop: 12,
    },
    reportTitle: {
        marginTop: 25,
        fontSize: 12,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    howToPay: {
        fontSize: 12,
        textAlign: 'center',
    },
})

const InvoiceThankYouMsg = () => (
    <View style={styles.titleContainer}>
        <Text style={styles.howToPay}>
            Checks may be made out to All Round Contracting LLC and mailed to
            the PO Box listed above. You may also use the Venmo
            “@allroundcontractingllc” or zelle 651-239-4444. Credit card
            payments may be made on www.allroundcontractingllc.com. Please reach
            out with any questions.
        </Text>
        <Text style={styles.reportTitle}>*** Thank You ***</Text>
    </View>
)

export default InvoiceThankYouMsg
