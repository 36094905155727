import React from 'react'
import GoogleMapReact from 'google-map-react'

export default function FooterMap() {
    const handleApiLoaded = (map, maps) => {
        const triangleCoords = [
            //winter snow removal map
            { lng: -93.086623, lat: 44.812532 },
            { lng: -93.086874, lat: 44.849662 },
            { lng: -93.086723, lat: 44.856477 },
            { lng: -93.107517, lat: 44.854471 },
            { lng: -93.104945, lat: 44.885357 },
            { lng: -93.067807, lat: 44.884084 },
            { lng: -93.067075, lat: 44.902957 },
            { lng: -93.011331, lat: 44.901024 },
            { lng: -92.974528, lat: 44.937847 },
            { lng: -92.890263, lat: 44.939012 },
            { lng: -92.890327, lat: 44.890661 },
            { lng: -92.903756, lat: 44.851244 },
            { lng: -92.91275, lat: 44.806793 },
            { lng: -92.953452, lat: 44.824754 },
            { lng: -93.00351, lat: 44.831103 },
            { lng: -93.012196, lat: 44.876218 },
            { lng: -93.017901, lat: 44.876666 },
            { lng: -93.012829, lat: 44.836528 },
            { lng: -93.02505, lat: 44.792987 },

            /* 
            Summer map
            
            { lat: 44.808039, lng: -93.190637 },
            { lat: 44.882781, lng: -93.139563 },
            { lat: 44.912303, lng: -93.101564 },
            { lat: 44.915775, lng: -93.056211 },
            { lat: 44.885676, lng: -93.019846 },
            { lat: 44.893202, lng: -92.99574 },
            { lat: 44.937182, lng: -92.960601 },
            { lat: 44.937761, lng: -92.886646 },
            { lat: 44.816735, lng: -92.886238 },
            { lat: 44.82543, lng: -92.997783 },
            { lat: 44.878148, lng: -93.011266 },
            { lat: 44.802241, lng: -93.02475 }, */
        ]

        var bermudaTriangle = new maps.Polygon({
            paths: triangleCoords,
            strokeColor: '#FF0000',
            strokeOpacity: 0.8,
            strokeWeight: 2,
            fillColor: '#FF0000',
            fillOpacity: 0.35,
        })
        bermudaTriangle.setMap(map)
    }

    const defaultProps = {
        center: {
            lat: 44.859276,
            lng: -93.033886,
        },
        zoom: 10,
    }

    return (
        <GoogleMapReact
            bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_API_KEY,
            }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
        ></GoogleMapReact>
    )
}
