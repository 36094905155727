import React, { useState } from 'react'

import { Drawer, List, ListItem, ListItemText } from '@mui/material'
import { Fragment } from 'react'

import { IconButton, Link } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { Close } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import { Button } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'

function NavBarDrawer(props) {
    const { dashboardLink } = props
    const { isAuthenticated, loginWithRedirect, logout } = useAuth0()

    const theme = useTheme()

    const [openDrawer, setOpenDrawer] = useState(false)
    return (
        <Fragment>
            <Drawer
                open={openDrawer}
                variant="temporary"
                onClose={() => setOpenDrawer(false)}
                bgcolor={theme.palette.primary.textWhite}
            >
                <Close
                    onClick={() => setOpenDrawer(false)}
                    style={{ cursor: 'pointer' }}
                />
                <List>
                    <ListItem onClick={() => setOpenDrawer(false)}>
                        <ListItemText>
                            <Link
                                sx={{
                                    color: theme.palette.primary.textBlack,
                                }}
                                href="/"
                            >
                                Home
                            </Link>
                        </ListItemText>
                    </ListItem>
                    <ListItem onClick={() => setOpenDrawer(false)}>
                        <ListItemText>
                            <Link
                                sx={{
                                    color: theme.palette.primary.textBlack,
                                }}
                                href="/services"
                            >
                                Services
                            </Link>
                        </ListItemText>
                    </ListItem>
                    <ListItem onClick={() => setOpenDrawer(false)}>
                        <ListItemText>
                            <Link
                                sx={{
                                    color: theme.palette.primary.textBlack,
                                }}
                                href="/orderService"
                            >
                                Order a Service
                            </Link>
                        </ListItemText>
                    </ListItem>
                    <ListItem onClick={() => setOpenDrawer(false)}>
                        <ListItemText>
                            <Link
                                sx={{
                                    color: theme.palette.primary.textBlack,
                                }}
                                href="/contactUs"
                            >
                                Contact Us
                            </Link>
                        </ListItemText>
                    </ListItem>

                    {isAuthenticated ? (
                        <ListItem onClick={() => setOpenDrawer(false)}>
                            <ListItemText>
                                <Link
                                    sx={{
                                        color: theme.palette.primary.textBlack,
                                    }}
                                    href={dashboardLink}
                                >
                                    Dashboard
                                </Link>
                            </ListItemText>
                        </ListItem>
                    ) : null}

                    {isAuthenticated ? (
                        <ListItem onClick={() => setOpenDrawer(false)}>
                            <Fragment>
                                <Button
                                    variant="text"
                                    color="secondary"
                                    onClick={() =>
                                        logout({
                                            logoutParams: {
                                                returnTo:
                                                    window.location.origin,
                                            },
                                        })
                                    }
                                >
                                    Logout
                                </Button>
                            </Fragment>
                        </ListItem>
                    ) : (
                        <Button
                            variant="text"
                            color="secondary"
                            onClick={() =>
                                loginWithRedirect({
                                    //redirectURI
                                    authorizationParams: {
                                        redirect_uri:
                                            window.location.origin +
                                            '/dashboard',
                                    },
                                })
                            }
                        >
                            Login
                        </Button>
                    )}
                </List>
            </Drawer>
            <IconButton
                color="white"
                onClick={() => setOpenDrawer(!openDrawer)}
            >
                <MenuIcon
                    sx={{
                        color: theme.palette.primary.textWhite,
                    }}
                />
            </IconButton>
        </Fragment>
    )
}
export default NavBarDrawer
