import { createTheme } from '@mui/material/styles'
import { THEME_COLORS } from './utils/Constant/Constant'

const theme = createTheme({
    components: {
        MuiButton: {
            variants: [
                {
                    props: { size: 'small' },
                    style: {
                        padding: '5px 10px',
                        fontSize: '0.75rem',
                    },
                },
                {
                    props: { size: 'medium' },
                    style: {
                        padding: '10px 20px',
                        fontSize: '1rem',
                    },
                },
                {
                    props: { size: 'large' },
                    style: {
                        padding: '15px 30px',
                        fontSize: '1.25rem',
                    },
                },
            ],
        },
    },
    palette: {
        background: {
            default: THEME_COLORS.background,
        },
        primary: {
            ...THEME_COLORS,
        },
        secondary: {
            main: '#a55d2a',
            grayscale: 'rgba(0, 0, 0, 0.3)',
            text: '#000000',
        },
    },
    typography: {
        fontFamily: 'Roboto, sans-serif',
    },
    font: {
        fontWeight: {
            light: 100,
            regular: 200,
            medium: 300,
            heavy: 400,
            title: 500,
            bold: 700,
        },
    },
})

export default theme
