import React, { useCallback, useEffect } from 'react'
import { Box } from '@mui/system'
import { CircularProgress, Typography } from '@mui/material'
import axios from 'axios'
import { useState } from 'react'
import { Grid } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'
import CurrencyFormat from 'react-currency-format'
import { Paper } from '@mui/material'
import { List } from '@mui/material'
import { ListItem } from '@mui/material'
import { ListItemText } from '@mui/material'
import {
    AccountBox,
    ArrowDropDown,
    ArrowDropUp,
    DesignServices,
} from '@mui/icons-material'
import { Message } from '@mui/icons-material'
import { Receipt } from '@mui/icons-material'
import { MonetizationOn } from '@mui/icons-material'
import { Payment } from '@mui/icons-material'
import { ListItemButton } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import CustomerInvoices from '../../utils/Components/CustomerViews/CustomerInvoices/CustomerInvoices'
import CustomerPayments from '../../utils/Components/CustomerViews/CustomerPayments/CustomerPayments'
import CustomerCharges from '../../utils/Components/CustomerViews/CustomerCharges/CustomerCharges'
import CustomerMessages from '../../utils/Components/CustomerViews/CustomerMessages/CustomerMessages'
import CustomerInfoEditable from '../../utils/Components/CustomerViews/CustomerInfoEditable/CustomerInfoEditable'
import Badge from '@mui/material/Badge'
import OrderService from '../../utils/Components/OrderService/OrderService'

const CustomerDashboard = (props) => {
    const { getAccessTokenSilently } = useAuth0()
    const { customerID } = props
    const [balance, setBalance] = useState(0)
    const [pendingCharges, setPendingCharges] = useState(0)
    const [toggle, setToggle] = useState('Invoices')
    const [isLoading, setIsLoading] = useState(true)
    const location = useLocation()
    const [customerData, setCustomerData] = useState({})
    const [open, setOpen] = useState(true)
    const theme = useTheme()

    const isMobile = window.innerWidth < 600

    const handleMarkAsRead = (message) => {
        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()
            axios
                .post(
                    `${process.env.REACT_APP_BACKEND_DB}/markMessageAsRead`,
                    {
                        message_id: message.message_id,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then(() => {
                    getCustomerData()
                })
                .catch((error) => {
                    console.error(error)
                })
        }
        getJWT()
    }

    const getCustomerData = useCallback(() => {
        const calculateBalance = (invoices) => {
            let total = 0
            invoices.forEach((invoice) => {
                if (invoice.invoice_status === 'unpaid') {
                    total = total + parseInt(invoice.total_amount)
                }
            })

            setBalance(total)
        }

        const calculateOpenCharges = (charges) => {
            let total = 0
            charges.forEach((charge) => {
                if (charge.charge_status === 'pending') {
                    total = total + parseInt(charge.amount)
                }
            })
            setPendingCharges(total)
        }

        const getJWT = async () => {
            setIsLoading(true)
            const accessToken = await getAccessTokenSilently()
            axios
                .get(
                    `${process.env.REACT_APP_BACKEND_DB}/getCustomerDetails/${customerID}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then((response) => {
                    calculateBalance(response.data.invoices)
                    calculateOpenCharges(response.data.charges)
                    setCustomerData(response.data)
                    setIsLoading(false)
                })
                .catch((error) => {
                    console.error(error)
                })
        }
        getJWT()
    }, [customerID, getAccessTokenSilently])

    const handleToggle = (event, newToggle) => {
        ReactGA.event({
            category: 'Customer Dashboard',
            action: `Viewing ${newToggle}`,
        })
        setToggle(newToggle)
    }

    const renderList = () => {
        //if the user is on mobile, render the list in a drawer
        if (window.innerWidth < 600) {
            return open ? (
                <List sx={{ padding: 0 }}>
                    <ListItem
                        onClick={() => setOpen(!open)}
                        sx={{
                            backgroundColor:
                                theme.palette.primary.supportingWhite,
                        }}
                    >
                        {customerData?.user?.first_name &&
                        customerData?.user?.last_name ? (
                            <Typography fontWeight={'bold'}>
                                {customerData?.user?.first_name}{' '}
                                {customerData?.user?.last_name}
                            </Typography>
                        ) : customerData?.user?.first_name ? (
                            <Typography fontWeight={'bold'}>
                                {customerData?.user?.first_name}
                            </Typography>
                        ) : (
                            <Typography fontWeight={'bold'}>
                                Customer Dashboard
                            </Typography>
                        )}
                    </ListItem>
                    <ListItem
                        sx={{
                            backgroundColor:
                                theme.palette.primary.supportingWhite,
                        }}
                    >
                        <Typography>
                            Open Invoices:{' '}
                            {isLoading ? (
                                <CircularProgress size={'1.2rem'} />
                            ) : (
                                <CurrencyFormat
                                    value={balance}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    decimalScale={2}
                                />
                            )}
                        </Typography>
                    </ListItem>
                    <ListItem
                        border
                        sx={{
                            backgroundColor:
                                theme.palette.primary.supportingWhite,
                            //add a 1px black line on the bottom of the list item
                        }}
                    >
                        <Typography>
                            Pending Charges:{' '}
                            {isLoading ? (
                                <CircularProgress size={'1.2rem'} />
                            ) : (
                                <CurrencyFormat
                                    value={pendingCharges}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    decimalScale={2}
                                />
                            )}
                        </Typography>
                    </ListItem>
                    <ListItemButton
                        onClick={() => handleToggle(null, 'Invoices')}
                        sx={{
                            backgroundColor:
                                toggle === 'Invoices'
                                    ? theme.palette.primary.backup
                                    : null,
                            color:
                                toggle === 'Invoices'
                                    ? theme.palette.primary.textWhite
                                    : theme.palette.primary.textBlack,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.textBlack,
                            },
                        }}
                    >
                        <Receipt />
                        <ListItemText
                            sx={{
                                paddingLeft: '4px',
                            }}
                            primary="Invoices"
                        />
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => handleToggle(null, 'Charges')}
                        sx={{
                            backgroundColor:
                                toggle === 'Charges'
                                    ? theme.palette.primary.backup
                                    : null,
                            color:
                                toggle === 'Charges'
                                    ? theme.palette.primary.textWhite
                                    : theme.palette.primary.textBlack,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.textBlack,
                            },
                        }}
                    >
                        <MonetizationOn />
                        <ListItemText
                            sx={{
                                paddingLeft: '4px',
                            }}
                            primary="Charges"
                        />
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => handleToggle(null, 'Payments')}
                        sx={{
                            backgroundColor:
                                toggle === 'Payments'
                                    ? theme.palette.primary.backup
                                    : null,
                            color:
                                toggle === 'Payments'
                                    ? theme.palette.primary.textWhite
                                    : theme.palette.primary.textBlack,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.textBlack,
                            },
                        }}
                    >
                        <Payment />
                        <ListItemText
                            sx={{
                                paddingLeft: '4px',
                            }}
                            primary="Payments"
                        />
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => handleToggle(null, 'Messages')}
                        sx={{
                            backgroundColor:
                                toggle === 'Messages'
                                    ? theme.palette.primary.backup
                                    : null,
                            color:
                                toggle === 'Messages'
                                    ? theme.palette.primary.textWhite
                                    : theme.palette.primary.textBlack,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.textBlack,
                            },
                        }}
                    >
                        <Message />
                        <ListItemText
                            sx={{
                                paddingLeft: '4px',
                            }}
                            primary="Messages"
                        />
                        {
                            //if the user has unread messages, display a badge
                            customerData?.messages?.filter(
                                (message) => message.read_status === false
                            ).length > 0 ? (
                                <Badge
                                    badgeContent={
                                        customerData?.messages?.filter(
                                            (message) =>
                                                message.read_status === false
                                        ).length
                                    }
                                    color="error"
                                />
                            ) : null
                        }
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => handleToggle(null, 'Account')}
                        sx={{
                            backgroundColor:
                                toggle === 'Account'
                                    ? theme.palette.primary.backup
                                    : null,
                            color:
                                toggle === 'Account'
                                    ? theme.palette.primary.textWhite
                                    : theme.palette.primary.textBlack,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.textBlack,
                            },
                        }}
                    >
                        <AccountBox />
                        <ListItemText
                            sx={{
                                paddingLeft: '4px',
                            }}
                            primary="Account"
                        />
                    </ListItemButton>
                    <ListItem onClick={() => setOpen(!open)}>
                        <Grid container>
                            <Grid item xs={11}>
                                <Typography>Collapse</Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <ArrowDropUp />
                            </Grid>
                        </Grid>
                    </ListItem>
                </List>
            ) : (
                <ListItem onClick={() => setOpen(!open)}>
                    <Grid container>
                        <Grid item xs={11}>
                            <Typography>View More</Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <ArrowDropDown />
                        </Grid>
                    </Grid>
                </ListItem>
            )
        } else {
            return (
                <List sx={{ padding: 0 }}>
                    <ListItem
                        sx={{
                            backgroundColor:
                                theme.palette.primary.supportingWhite,
                        }}
                    >
                        {customerData?.user?.first_name &&
                        customerData?.user?.last_name ? (
                            <Typography fontWeight={'bold'}>
                                {customerData?.user?.first_name}{' '}
                                {customerData?.user?.last_name}
                            </Typography>
                        ) : customerData?.user?.first_name ? (
                            <Typography fontWeight={'bold'}>
                                {customerData?.user?.first_name}
                            </Typography>
                        ) : (
                            <Typography fontWeight={'bold'}>
                                Customer Dashboard
                            </Typography>
                        )}
                    </ListItem>
                    <ListItem
                        sx={{
                            backgroundColor:
                                theme.palette.primary.supportingWhite,
                        }}
                    >
                        <Typography>
                            Open Invoices:{' '}
                            {isLoading ? (
                                <CircularProgress size={'1.2rem'} />
                            ) : (
                                <CurrencyFormat
                                    value={balance}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    decimalScale={2}
                                />
                            )}
                        </Typography>
                    </ListItem>{' '}
                    <ListItem
                        sx={{
                            backgroundColor:
                                theme.palette.primary.supportingWhite,
                        }}
                    >
                        <Typography>
                            Pending Charges:{' '}
                            {isLoading ? (
                                <CircularProgress size={'1.2rem'} />
                            ) : (
                                <CurrencyFormat
                                    value={pendingCharges}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                    prefix={'$'}
                                    decimalScale={2}
                                />
                            )}
                        </Typography>
                    </ListItem>
                    <ListItemButton
                        onClick={() => handleToggle(null, 'Invoices')}
                        sx={{
                            backgroundColor:
                                toggle === 'Invoices'
                                    ? theme.palette.primary.backup
                                    : null,
                            color:
                                toggle === 'Invoices'
                                    ? theme.palette.primary.textWhite
                                    : theme.palette.primary.textBlack,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.textBlack,
                            },
                        }}
                    >
                        <Receipt />
                        <ListItemText
                            sx={{
                                paddingLeft: '4px',
                            }}
                            primary="Invoices"
                        />
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => handleToggle(null, 'Charges')}
                        sx={{
                            backgroundColor:
                                toggle === 'Charges'
                                    ? theme.palette.primary.backup
                                    : null,
                            color:
                                toggle === 'Charges'
                                    ? theme.palette.primary.textWhite
                                    : theme.palette.primary.textBlack,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.textBlack,
                            },
                        }}
                    >
                        <MonetizationOn />
                        <ListItemText
                            sx={{
                                paddingLeft: '4px',
                            }}
                            primary="Charges"
                        />
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => handleToggle(null, 'Payments')}
                        sx={{
                            backgroundColor:
                                toggle === 'Payments'
                                    ? theme.palette.primary.backup
                                    : null,
                            color:
                                toggle === 'Payments'
                                    ? theme.palette.primary.textWhite
                                    : theme.palette.primary.textBlack,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.textBlack,
                            },
                        }}
                    >
                        <Payment />
                        <ListItemText
                            sx={{
                                paddingLeft: '4px',
                            }}
                            primary="Payments"
                        />
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => handleToggle(null, 'Messages')}
                        sx={{
                            backgroundColor:
                                toggle === 'Messages'
                                    ? theme.palette.primary.backup
                                    : null,
                            color:
                                toggle === 'Messages'
                                    ? theme.palette.primary.textWhite
                                    : theme.palette.primary.textBlack,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.textBlack,
                            },
                        }}
                    >
                        <Message />
                        <ListItemText
                            sx={{
                                paddingLeft: '4px',
                            }}
                            primary="Messages"
                        />
                        {
                            //if the user has unread messages, display a badge
                            customerData?.messages?.filter(
                                (message) => message.read_status === false
                            ).length > 0 ? (
                                <Badge
                                    badgeContent={
                                        customerData?.messages?.filter(
                                            (message) =>
                                                message.read_status === false
                                        ).length
                                    }
                                    color="error"
                                />
                            ) : null
                        }
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => handleToggle(null, 'Account')}
                        sx={{
                            backgroundColor:
                                toggle === 'Account'
                                    ? theme.palette.primary.backup
                                    : null,
                            color:
                                toggle === 'Account'
                                    ? theme.palette.primary.textWhite
                                    : theme.palette.primary.textBlack,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.textBlack,
                            },
                        }}
                    >
                        <AccountBox />
                        <ListItemText
                            sx={{
                                paddingLeft: '4px',
                            }}
                            primary="Account"
                        />
                    </ListItemButton>
                    <ListItemButton
                        onClick={() => handleToggle(null, 'Order A Service')}
                        sx={{
                            backgroundColor:
                                toggle === 'Order A Service'
                                    ? theme.palette.primary.backup
                                    : null,
                            color:
                                toggle === 'Order A Service'
                                    ? theme.palette.primary.textWhite
                                    : theme.palette.primary.textBlack,
                            '&:hover': {
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.textBlack,
                            },
                        }}
                    >
                        <DesignServices />
                        <ListItemText
                            sx={{
                                paddingLeft: '4px',
                            }}
                            primary="Order A Service"
                        />
                    </ListItemButton>
                </List>
            )
        }
    }

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
        })
    }, [location])

    useEffect(() => {
        getCustomerData()
    }, [customerID, getAccessTokenSilently, toggle, getCustomerData])

    return (
        <Box
            display={'flex'}
            flexDirection={'row'}
            height={'100%'}
            minHeight={isMobile ? '100vh' : `calc(100vh - 64px)`}
        >
            <Helmet>
                <title>Customer Dashboard</title>
                <meta
                    name="description"
                    content="Customer Dashboard outlining charges, invoices and payments for All Round Contracting LLC"
                />

                <meta property="og:title" content="Customer Dashboard" />
                <meta
                    property="og:description"
                    content="Customer Dashboard outlining charges, invoices and payments for All Round Contracting LLC"
                />
                <meta
                    property="og:image"
                    content="https://allroundcontractingllc.com/static/media/ARC.fce879f1b4533f68cf02.png"
                />
                <meta property="og:type" content="website" />
                <meta
                    property="og:url"
                    content="https://allroundcontractingllc.com/dashboard"
                />
                <meta name="twitter:title" content="Customer Dashboard" />
                <meta
                    name="twitter:description"
                    content="Customer Dashboard outlining charges, invoices and payments for All Round Contracting LLC"
                />
                <meta
                    name="twitter:image"
                    content="https://allroundcontractingllc.com/static/media/ARC.fce879f1b4533f68cf02.png"
                />
                <meta name="twitter:card" content="summary_large_image" />
                <link
                    rel="canonical"
                    href="https://allroundcontractingllc.com/dashboard"
                />
            </Helmet>
            <Grid container>
                <Grid item xs={12} sm={2}>
                    <Paper
                        sx={{
                            height: '100%',
                            borderRadius: 0,
                        }}
                    >
                        {renderList()}
                    </Paper>
                </Grid>
                <Grid xs={12} sm={10} item>
                    <Box width={'100%'} padding={2}>
                        {toggle === 'Account' && (
                            <CustomerInfoEditable customerID={customerID} />
                        )}
                        {toggle === 'Messages' && (
                            <CustomerMessages
                                messages={customerData.messages}
                                isLoading={isLoading}
                                handleMarkAsRead={handleMarkAsRead}
                            />
                        )}
                        {toggle === 'Invoices' && (
                            <CustomerInvoices
                                invoices={customerData.invoices}
                                isLoading={isLoading}
                            />
                        )}
                        {toggle === 'Charges' && (
                            <CustomerCharges
                                charges={customerData.charges}
                                isLoading={isLoading}
                            />
                        )}
                        {toggle === 'Payments' && (
                            <CustomerPayments
                                payments={customerData.payments}
                                isLoading={isLoading}
                            />
                        )}
                        {toggle === 'Order A Service' && <OrderService />}
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}

export default CustomerDashboard
