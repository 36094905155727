import React, { useEffect, useState, useCallback, useRef } from 'react'
import {
    CircularProgress,
    Typography,
    Container,
    Button,
    Box,
} from '@mui/material'
import axios from 'axios'
import { useLocation } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'

const InteractionPage = () => {
    const [message, setMessage] = useState('')
    const [loading, setLoading] = useState(true)
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const action = queryParams.get('action')
    const token = queryParams.get('token')
    const theme = useTheme()
    const hasCalledAPI = useRef(false) // Step 2: Create a ref to track API call

    const handleAction = useCallback(
        async (action) => {
            if (hasCalledAPI.current) return // Step 3: Check if API call has been made
            hasCalledAPI.current = true // Set the flag to true after the call is made

            setLoading(true)
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BACKEND_DB}/reEngageUser/${action}`,
                    { token }
                )
                setMessage(response.data.message)
            } catch (error) {
                setMessage('Error: ' + error.response.data.message)
            } finally {
                setLoading(false)
            }
        },
        [token]
    )

    useEffect(() => {
        if (action && token) {
            handleAction(action)
        }
    }, [action, token, handleAction])

    return (
        <Container style={{ textAlign: 'center', padding: '20px' }}>
            {loading ? (
                <Box
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '100vh',
                    }}
                >
                    <CircularProgress />
                </Box>
            ) : message.startsWith('Error') ? (
                <Box padding={2}>
                    <Box padding={2}>
                        <Typography
                            variant="h4"
                            color={theme.palette.primary.errorRed}
                        >
                            We're sorry, something went wrong.
                        </Typography>
                    </Box>

                    <Typography
                        variant="body1"
                        color={theme.palette.primary.textWhite}
                    >
                        {message}
                    </Typography>
                    <Box padding={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() =>
                                (window.location.href = '/contactUs')
                            }
                        >
                            Contact Us
                        </Button>
                    </Box>
                </Box>
            ) : (
                <Box padding={2}>
                    <Box padding={2}>
                        <Typography
                            variant="h4"
                            color={theme.palette.primary.successGreen}
                        >
                            Success!
                        </Typography>
                    </Box>
                    <Box padding={2}>
                        <Typography
                            variant="h4"
                            color={theme.palette.primary.textWhite}
                        >
                            {message}
                        </Typography>
                    </Box>
                </Box>
            )}
        </Container>
    )
}

export default InteractionPage
