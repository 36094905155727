//This will be the customer interactions table. It will display all of the interactions that a customer has had with the contractor.
//It will display the following:
// - Date
// - Interaction Type
// - Interaction Notes
// - Interaction Amount
// - Interaction Status
//
//The table should be a MUI table
// The data will be populated from the database. The customer ID will be passed in as a prop.
//
// */

import React, { useState, useEffect } from 'react'
import axios from 'axios'

import { useAuth0 } from '@auth0/auth0-react'
import { Box } from '@mui/system'
import { Typography, Button, Card, CardContent, Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import AddJobPopover from '../../modals/AddJobPopover/AddJobPopover'
import CustomPopover from '../../modals/CustomPopover/CustomPopover'
import EditCustomerJob from './EditCustomerJob'
import moment from 'moment'
import CircularProgress from '@mui/material/CircularProgress'

export default function Jobs(props) {
    const { getAccessTokenSilently } = useAuth0()
    const theme = useTheme()
    const { customerID } = props
    const [
        addJobInteractionPopoverVisible,
        setAddJobInteractionPopoverVisible,
    ] = useState(false)

    const [jobs, setJobs] = useState()
    const [editJobPopoverVisible, setEditJobPopoverVisible] = useState(false)
    const [viewJobPopoverVisible, setViewJobPopoverVisible] = useState(false)

    const [jobInFocus, setjobInFocus] = useState()

    const [fetchStatus, setFetchStatus] = useState('loading')

    const clickViewJob = (job) => {
        setjobInFocus(job)
        setViewJobPopoverVisible(true)
    }

    const handleEditJob = (job) => {
        setjobInFocus(job)
        setEditJobPopoverVisible(true)
    }

    const filteredJobs = jobs

    useEffect(() => {
        if (addJobInteractionPopoverVisible || editJobPopoverVisible) {
            return
        }

        const getJobs = async () => {
            setFetchStatus('loading')
            const accessToken = await getAccessTokenSilently()

            axios
                .get(
                    `${process.env.REACT_APP_BACKEND_DB}/getJobs/${customerID}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then((response) => {
                    setJobs(response.data)
                    setFetchStatus('success')
                })
        }
        getJobs()
    }, [
        addJobInteractionPopoverVisible,
        customerID,
        getAccessTokenSilently,
        editJobPopoverVisible,
    ])

    if (fetchStatus === 'loading') {
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    padding={2}
                >
                    <Typography
                        variant="h4"
                        color={theme.palette.primary.textWhite}
                    >
                        Jobs
                    </Typography>
                </Box>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Box>
            {addJobInteractionPopoverVisible && (
                <AddJobPopover
                    setPopoverVisible={setAddJobInteractionPopoverVisible}
                    customerID={customerID}
                />
            )}

            {editJobPopoverVisible && (
                <CustomPopover
                    setPopoverVisible={setEditJobPopoverVisible}
                    title={'Edit Job'}
                >
                    <EditCustomerJob
                        job={jobInFocus}
                        setPopoverVisible={setEditJobPopoverVisible}
                    />
                </CustomPopover>
            )}
            {viewJobPopoverVisible && (
                <CustomPopover
                    setPopoverVisible={setViewJobPopoverVisible}
                    title={'View Job'}
                >
                    <EditCustomerJob
                        job={jobInFocus}
                        setPopoverVisible={setViewJobPopoverVisible}
                    />
                </CustomPopover>
            )}

            <Box
                display={'flex'}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                padding={2}
            >
                <Typography
                    variant="h4"
                    color={theme.palette.primary.textWhite}
                >
                    Jobs
                </Typography>
            </Box>
            <Box
                display={'flex'}
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                padding={2}
            >
                <Box padding>
                    <Button
                        variant="contained"
                        size="small"
                        onClick={() =>
                            setAddJobInteractionPopoverVisible(
                                !addJobInteractionPopoverVisible
                            )
                        }
                    >
                        Add Job
                    </Button>
                </Box>
            </Box>
            {!filteredJobs?.length ? (
                <Typography
                    variant="body1"
                    textAlign={'center'}
                    color={theme.palette.primary.textWhite}
                >
                    No Results Found
                </Typography>
            ) : null}
            {filteredJobs
                ?.sort((a, b) => {
                    return new Date(b.payment_date) - new Date(a.payment_date)
                })
                .map((job, index) => (
                    <Card
                        key={index}
                        style={{
                            margin: '10px',
                            border: '1px solid black',
                            //hover effect
                        }}
                    >
                        <CardContent
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'lightgray',
                                    cursor: 'pointer',
                                },
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                position: 'relative',
                            }}
                        >
                            <Grid container>
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    onClick={() => clickViewJob(job)}
                                >
                                    <Typography
                                        variant="h6"
                                        style={{
                                            fontWeight: 'bold',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        Job Status: {job.job_status}
                                    </Typography>
                                    <Typography>
                                        Job Start Date:{' '}
                                        {moment
                                            .utc(job.start_date)
                                            .format('MM/DD/YYYY')}
                                    </Typography>
                                    <Typography>
                                        Job End Date:{' '}
                                        {moment
                                            .utc(job.end_date)
                                            .format('MM/DD/YYYY')}
                                    </Typography>

                                    <Typography>
                                        Job Description: {job.job_description}
                                    </Typography>
                                </Grid>

                                <Grid
                                    container
                                    item
                                    xs={12}
                                    sm={6}
                                    justifyContent={'right'}
                                    alignItems={'flex-end'}
                                >
                                    <Grid item xs={12}>
                                        <Typography
                                            display={'flex'}
                                            flexDirection={'row'}
                                            justifyContent={'right'}
                                            alignItems={'flex-end'}
                                            variant="h6"
                                            style={{
                                                fontWeight: 'bold',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            Job Notes: {job.job_notes}
                                        </Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Box
                                            display={'flex'}
                                            flexDirection={'row'}
                                            alignItems={'flex-end'}
                                            justifyContent={'right'}
                                            height={'100%'}
                                        >
                                            <Box padding>
                                                <Button
                                                    variant="contained"
                                                    size="small"
                                                    onClick={() =>
                                                        handleEditJob(job)
                                                    }
                                                >
                                                    Edit
                                                </Button>
                                            </Box>

                                            <Box padding>
                                                <Button
                                                    onClick={() =>
                                                        clickViewJob(job)
                                                    }
                                                    variant="contained"
                                                    size="small"
                                                >
                                                    View Job
                                                </Button>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                ))}
        </Box>
    )
}
