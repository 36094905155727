import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { CircularProgress } from '@mui/material'
import Grid from '@mui/material/Grid'
import moment from 'moment'
import { useTheme } from '@mui/material/styles'
import ViewPaymentPopover from '../../../modals/ViewPaymentPopover/ViewPaymentPopover'
import { DatePicker } from 'antd'

const CustomerPayments = ({ payments, isLoading }) => {
    const theme = useTheme()
    const [viewPaymentPopover, setViewPaymentPopover] = React.useState(false)
    const [viewPayment, setViewPayment] = React.useState()
    const [dateRange, setDateRange] = React.useState()
    const { RangePicker } = DatePicker

    const handleViewPayment = (payment) => {
        setViewPayment(payment)
        setViewPaymentPopover(true)
    }

    if (!payments || isLoading) {
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    padding={2}
                >
                    <Typography
                        variant="h4"
                        color={theme.palette.primary.textWhite}
                    >
                        Payments
                    </Typography>
                </Box>
                <CircularProgress />
            </Box>
        )
    }

    const filteredPayments = payments.filter((payment) => {
        if (!dateRange) {
            return true
        }
        return (
            new Date(payment.payment_date) >= dateRange[0] &&
            new Date(payment.payment_date) <= dateRange[1]
        )
    })

    if (filteredPayments.length === 0) {
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    padding={2}
                >
                    <Typography
                        variant="h4"
                        color={theme.palette.primary.textWhite}
                    >
                        Payments
                    </Typography>
                </Box>
                <Typography
                    variant="h6"
                    color={theme.palette.primary.textWhite}
                >
                    No payments found for this date range.
                </Typography>
            </Box>
        )
    }

    return (
        <Grid container>
            {viewPaymentPopover && (
                <ViewPaymentPopover
                    setPopoverVisible={setViewPaymentPopover}
                    payment={viewPayment}
                />
            )}
            <Grid item xs={12}>
                <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    padding={2}
                >
                    <Typography
                        variant="h4"
                        color={theme.palette.primary.textWhite}
                    >
                        Payments
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Box
                    display={'flex'}
                    flexDirection="row"
                    justifyContent="center"
                    alignItems="center"
                    padding={2}
                >
                    <Typography
                        variant="h6"
                        color={theme.palette.primary.textWhite}
                        style={{ marginRight: '10px' }}
                    >
                        Date Range:
                    </Typography>

                    <RangePicker onChange={(dates) => setDateRange(dates)} />
                </Box>
            </Grid>
            <Grid item xs={12}>
                {filteredPayments
                    ?.sort((a, b) => {
                        return (
                            new Date(b.payment_date) - new Date(a.payment_date)
                        )
                    })
                    .map((payment, index) => (
                        <Card
                            key={index}
                            style={{
                                margin: '10px',
                                padding: '10px',
                                border: '1px solid black',
                                //hover effect
                            }}
                        >
                            <CardContent
                                sx={{
                                    '&:hover': {
                                        backgroundColor: 'lightgray',
                                        cursor: 'pointer',
                                    },
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-start',
                                    padding: '10px',
                                    position: 'relative',
                                }}
                                onClick={() => handleViewPayment(payment)}
                            >
                                <Grid container>
                                    <Grid item xs={12} sm={9}>
                                        <Typography
                                            variant="h6"
                                            style={{
                                                fontWeight: 'bold',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            Payment #{payment.payment_id}
                                        </Typography>
                                        <Typography>
                                            Amount: {payment.payment_amount}
                                        </Typography>
                                        <Typography>
                                            Date:{' '}
                                            {moment
                                                .utc(payment.payment_date)
                                                .format('MM/DD/YYYY')}
                                        </Typography>
                                        <Typography>
                                            Method: {payment.payment_method}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                        <Typography
                                            variant="h6"
                                            style={{
                                                fontWeight: 'bold',
                                                marginBottom: '10px',
                                            }}
                                        >
                                            Notes
                                        </Typography>
                                        <Typography>
                                            {payment.payment_notes}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </CardContent>
                        </Card>
                    ))}
            </Grid>
        </Grid>
    )
}

export default CustomerPayments
