import React from 'react'
import AddContractorModal from './AddContractor/AddContractorModal'
import { Typography } from '@mui/material'
import UnregisteredUsers from './UnregisteredUsers/UnregisteredUsers'

export default function AdminDashboard() {
    const [addContractorModalVisible, setAddContractorModalVisible] =
        React.useState(false)

    return (
        <div>
            <h1>Admin Dashboard</h1>
            <button
                onClick={() =>
                    setAddContractorModalVisible(!addContractorModalVisible)
                }
            >
                Add Contractor
            </button>
            <Typography variant="h3">Unregistered Users</Typography>
            <UnregisteredUsers></UnregisteredUsers>
            {/* Add components for admin-specific actions, which includes all actions available to customers and contractors */}
            {addContractorModalVisible && (
                <AddContractorModal
                    addContractorModalVisible={addContractorModalVisible}
                    setAddContractorModalVisible={setAddContractorModalVisible}
                ></AddContractorModal>
            )}
        </div>
    )
}
