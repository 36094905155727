/*This will be the Routes page. A contractor will use this page to view and create routes.

It will take in a prop called contractorID which will be the ID of the contractor that is logged in.

It will display a list of routes that the contractor has created. It will display the following information:
- Route Name
- Route Date
- Route Status
- Route Customer Count
- Route Total

It will also have a button to create a new route. This button will open a modal that will allow the contractor to create a new route.
The modal should display a table with all of the customers that the contractor has that have an open job. 
The contractor should be able to select customers from this table to add to the route.


*/

import {
    Box,
    Typography,
    Button,
    useTheme,
    CardContent,
    ToggleButton,
    ToggleButtonGroup,
    CircularProgress,
    Card,
    Grid,
} from '@mui/material'
import moment from 'moment'

import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

export default function Routes({
    isLoading,
    routes,
    setToRouteDetail,
    setToCreateNewRoute,
}) {
    const theme = useTheme()
    const location = useLocation()

    const [routeFilter, setRouteFilter] = useState('all')

    const filteredRoutes = routes?.filter((route) => {
        if (routeFilter === 'all') {
            return true
        }
        return route.route_status === routeFilter
    })

    const handleFilterChange = (event, newFilter) => {
        if (newFilter !== null) {
            setRouteFilter(newFilter)
        }
    }

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
            title: 'Routes',
        })
    }, [location])

    if (!routes || isLoading)
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    padding={2}
                >
                    <Typography
                        variant="h4"
                        color={theme.palette.primary.textWhite}
                    >
                        Routes
                    </Typography>
                </Box>
                <CircularProgress />
            </Box>
        )
    return (
        <Box>
            <Box
                display={'flex'}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                padding={2}
            >
                <Typography
                    variant="h4"
                    color={theme.palette.primary.textWhite}
                >
                    Routes
                </Typography>
            </Box>
            <Box
                display={'flex'}
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                padding={2}
            >
                <Typography
                    variant="h6"
                    color={theme.palette.primary.textWhite}
                    style={{ marginRight: '10px' }}
                >
                    Filter by:
                </Typography>

                <ToggleButtonGroup
                    value={routeFilter}
                    exclusive
                    onChange={handleFilterChange}
                    style={{ backgroundColor: 'black' }}
                >
                    <ToggleButton
                        value="all"
                        style={{
                            backgroundColor:
                                routeFilter === 'all' ? 'gray' : 'black',
                            color: routeFilter === 'all' ? 'white' : 'gray',
                        }}
                    >
                        All
                    </ToggleButton>
                    <ToggleButton
                        value="Scheduled"
                        style={{
                            backgroundColor:
                                routeFilter === 'Scheduled' ? 'gray' : 'black',
                            color:
                                routeFilter === 'Scheduled' ? 'white' : 'gray',
                        }}
                    >
                        Scheduled
                    </ToggleButton>
                    <ToggleButton
                        value="In Progress"
                        style={{
                            backgroundColor:
                                routeFilter === 'In Progress'
                                    ? 'gray'
                                    : 'black',
                            color:
                                routeFilter === 'In Progress'
                                    ? 'white'
                                    : 'gray',
                        }}
                    >
                        In Progress
                    </ToggleButton>
                </ToggleButtonGroup>
                <Box
                    display={'flex'}
                    flexDirection="row"
                    justifyContent="center"
                    padding={2}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setToCreateNewRoute()}
                    >
                        New Route
                    </Button>
                </Box>
            </Box>
            {!filteredRoutes.length ? (
                <Typography
                    variant="body1"
                    textAlign={'center'}
                    color={theme.palette.primary.textWhite}
                >
                    No Results Found
                </Typography>
            ) : null}

            {filteredRoutes
                ?.sort((a, b) => {
                    return b.route_id - a.route_id
                })
                .map((route, index) => (
                    <Card
                        key={index}
                        style={{
                            margin: '10px',
                            padding: '10px',
                            border: '1px solid black',
                            //hover effect
                        }}
                    >
                        <CardContent
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'lightgray',
                                    cursor: 'pointer',
                                },
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                padding: '10px',
                                position: 'relative',
                            }}
                            onClick={() => setToRouteDetail(route)}
                        >
                            <Grid container>
                                <Grid item xs={12} sm={9}>
                                    <Typography
                                        style={{
                                            fontSize: '1.5rem',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        Route #{route.route_id}
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontSize: '1rem',

                                            fontWeight: 'medium',
                                        }}
                                    >
                                        {route.route_name}
                                    </Typography>
                                    <Typography
                                        style={{
                                            fontWeight: 'light',
                                            fontSize: '1rem',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        Generated:{' '}
                                        {moment
                                            .utc(route?.date_generated)
                                            .format('MM/DD/YYYY')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            fontWeight: 'bold',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        {route.route_status}
                                    </Typography>
                                    <Typography>
                                        Customers:{' '}
                                        {route.route_customers?.length}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                ))}
        </Box>
    )
}
