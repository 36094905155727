//This will be the landing page after a user does auth0 authentication registration.
//Auth0 will place the customer_sub and email in the database.
//The customer will be redirected to this page to finish registration.
//The customer will be required to enter their phone number and address.
//The customer should also fill in their first and last name
//Upon filling out all the required fields, the customer will be redirected to the customer dashboard.
//Upon submission of the form the component should make a call to the backend to update the customer table.

import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
    TextField,
    Button,
    Typography,
    FormControl,
    Tooltip,
    CircularProgress,
} from '@mui/material'
import { Box } from '@mui/system'
import { useAuth0 } from '@auth0/auth0-react'
import { useNavigate } from 'react-router-dom'
import { Grid } from '@mui/material'
import Success from '../FormStatusIcons/Success'
import Failed from '../FormStatusIcons/Failed'
import Loading from '../Loading/Loading'

export default function FinishRegistration() {
    const { getAccessTokenSilently } = useAuth0()
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [error, setError] = useState(null)
    const [submitting, setSubmitting] = useState(false)
    const { user } = useAuth0()
    const [formStatus, setFormStatus] = useState('')
    const [register] = useState(false)
    const [searchData, setSearchData] = useState({
        phone_number: '',
        email: '',
    })
    const [hasLoadedRegistrationData, setHasLoadedRegistrationData] =
        useState(false)
    const [searching, setSearching] = useState(false)
    const [returnedSearchData, setReturnedSearchData] = useState()
    const navigate = useNavigate()

    //A function to remove all non numeric characters from a string
    const removeNonNumeric = (string) => {
        return string.replace(/\D/g, '')
    }

    const handleSubmitSearch = () => {
        if (!searchData.phone_number) {
            setError('Please fill out all fields')
        } else {
            setSearching(true)

            const getJWT = async () => {
                const accessToken = await getAccessTokenSilently()

                axios
                    .get(
                        `${
                            process.env.REACT_APP_BACKEND_DB
                        }/searchSync/${removeNonNumeric(
                            searchData.phone_number
                        )}/${user?.email}`,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    )
                    .then((response) => {
                        setSearching(false)

                        if (response.data.length === 1) {
                            setReturnedSearchData(response.data[0])
                        } else if (response.data.length > 1) {
                            setReturnedSearchData(
                                'More than 1 account found with that phone number'
                            )
                        } else {
                            setReturnedSearchData('No account found')
                        }
                    })
            }
            getJWT()
        }
    }

    const handleSyncSubmit = () => {
        //Update the returnedSearchData with the customer_sub
        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()

            axios
                .post(
                    `${process.env.REACT_APP_BACKEND_DB}/updateUserWithSub`,
                    {
                        customer_sub: user.sub,
                        user_id: returnedSearchData.user_id,
                        has_finished_registration: true,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then((response) => {
                    setFormStatus('success')
                    setTimeout(() => {
                        setFormStatus('idle')
                        navigate(`/dashboard/${response?.data?.user_id}`)
                    }, 2000)
                })
        }
        getJWT()
    }

    /*
    TODO: unused function right now. Decide if we want users to be able to log in even
    if they cant find a previously created account. Right now this is NOT allowed.
    const handleRegister = () => {
        setReturnedSearchData(null)
        setRegister(true)
    }
    */

    const handleSubmit = () => {
        if (!firstName || !lastName || !phone || !address) {
            setError('Please fill out all fields')
        } else {
            setSubmitting(true)

            const getJWT = async () => {
                const accessToken = await getAccessTokenSilently()

                axios
                    .post(
                        `${process.env.REACT_APP_BACKEND_DB}/addUser`,
                        {
                            first_name: firstName,
                            last_name: lastName,
                            address: address,
                            email: user.email,
                            phone_number: phone,
                            customer_sub: user.sub,

                            role: 'customer',

                            has_finished_registration: true,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    )
                    .then((response) => {
                        setSubmitting(false)
                        setFormStatus('success')
                        navigate(`/dashboard/${response.data.user_id}`)
                    })
                    .catch((error) => {
                        setSubmitting(false)
                        setFormStatus('error')
                        setError(error)
                    })
            }
            getJWT()
        }
    }

    useEffect(() => {
        setHasLoadedRegistrationData(false)
        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()

            axios
                .get(
                    `${process.env.REACT_APP_BACKEND_DB}/getSingleUserBySub/${user?.sub}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then((response) => {
                    if (response?.data?.has_finished_registration) {
                        navigate(`/dashboard/${response.data.user_id}`)
                    }
                    setHasLoadedRegistrationData(true)
                })
        }
        getJWT()
    }, [getAccessTokenSilently, navigate, user?.sub])

    return hasLoadedRegistrationData ? (
        <Box
            height="100vh"
            display="flex"
            flexDirection="column"
            direction="column"
            alignItems="center"
            justifyContent="center"
        >
            <Box
                p="5vh"
                direction="column"
                alignItems="center"
                justifyContent="center"
                backgroundColor="white"
                borderRadius="10px"
            >
                <Box display="flex" flexDirection="column">
                    {!returnedSearchData ? (
                        !register ? (
                            <Box>
                                <Typography variant="h3" textAlign={'center'}>
                                    Sync your account
                                </Typography>
                                <Typography
                                    variant="body1"
                                    textAlign={'center'}
                                >
                                    Enter in the fields to see if we can find
                                    your account
                                </Typography>

                                <FormControl required={true}>
                                    <Box p="1vh">
                                        <Grid container direction="row">
                                            <Grid
                                                item
                                                xs={6}
                                                md={12}
                                                padding={2}
                                            >
                                                <TextField
                                                    required={true}
                                                    label={'Phone Number'}
                                                    type="tel"
                                                    helperText={
                                                        error &&
                                                        !searchData.phone_number &&
                                                        'Please fill out all fields'
                                                    }
                                                    error={
                                                        error &&
                                                        !searchData.phone_number
                                                    }
                                                    fullWidth={true}
                                                    variant="outlined"
                                                    value={
                                                        searchData?.phone_number
                                                    }
                                                    onChange={(e) =>
                                                        //set the search data to include the changed searchData.email
                                                        setSearchData({
                                                            ...searchData,
                                                            phone_number:
                                                                removeNonNumeric(
                                                                    e.target
                                                                        .value
                                                                ),
                                                        })
                                                    }
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}
                                                md={12}
                                                padding={2}
                                            >
                                                <Tooltip title="You can only link your account with the email you signed up with">
                                                    <TextField
                                                        required={true}
                                                        label={'Email'}
                                                        fullWidth={true}
                                                        variant="outlined"
                                                        value={
                                                            user?.email || ''
                                                        }
                                                        disabled
                                                    />
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                    <Box p="1vh">
                                        {searching ? (
                                            <div>Searching...</div>
                                        ) : (
                                            <Button
                                                className="finishRegistrationSubmit"
                                                variant="contained"
                                                type="submit"
                                                onClick={handleSubmitSearch}
                                            >
                                                Search
                                            </Button>
                                        )}
                                    </Box>
                                </FormControl>
                            </Box>
                        ) : (
                            <Box>
                                <Typography variant="h3">
                                    Finish Registration
                                </Typography>
                                <FormControl required={true}>
                                    <Box p="1vh">
                                        <TextField
                                            required={true}
                                            label={'First Name'}
                                            helperText={
                                                error &&
                                                !firstName &&
                                                'Please fill out all fields'
                                            }
                                            error={error && !firstName}
                                            fullWidth={true}
                                            variant="outlined"
                                            value={firstName}
                                            onChange={(e) =>
                                                setFirstName(e.target.value)
                                            }
                                        />
                                    </Box>
                                    <Box p="1vh">
                                        <TextField
                                            required={true}
                                            className="finishRegistrationInput"
                                            fullWidth={true}
                                            helperText={
                                                error &&
                                                !lastName &&
                                                'Please fill out all fields'
                                            }
                                            error={error && !lastName}
                                            label={'Last Name'}
                                            variant="outlined"
                                            value={lastName}
                                            onChange={(e) =>
                                                setLastName(e.target.value)
                                            }
                                        />
                                    </Box>
                                    <Box p="1vh">
                                        <TextField
                                            required={true}
                                            fullWidth={true}
                                            error={error && !phone}
                                            helperText={
                                                error &&
                                                !phone &&
                                                'Please fill out all fields'
                                            }
                                            label={'Phone Number'}
                                            variant="outlined"
                                            type="tel"
                                            value={phone}
                                            onChange={(e) =>
                                                setPhone(
                                                    removeNonNumeric(
                                                        e.target.value
                                                    )
                                                )
                                            }
                                        />
                                    </Box>
                                    <Box p="1vh">
                                        <TextField
                                            required={true}
                                            fullWidth={true}
                                            error={error && !address}
                                            helperText={
                                                error &&
                                                !address &&
                                                'Please fill out all fields'
                                            }
                                            label={'Address'}
                                            variant="outlined"
                                            value={address}
                                            onChange={(e) =>
                                                setAddress(e.target.value)
                                            }
                                        />
                                    </Box>
                                    <Box p="5vh">
                                        {submitting ? (
                                            <Box p={1}>
                                                <CircularProgress size={24} />{' '}
                                                Submitting...
                                            </Box>
                                        ) : (
                                            <Button
                                                className="finishRegistrationSubmit"
                                                variant="contained"
                                                type="submit"
                                                onClick={handleSubmit}
                                            >
                                                Submit
                                            </Button>
                                        )}

                                        {
                                            //If formStatus is success, show the success icon
                                            formStatus === 'success' ? (
                                                <Success
                                                    title={'Registered!'}
                                                />
                                            ) : //If formStatus is error, show the error icon
                                            formStatus === 'error' ? (
                                                <Failed error={error} />
                                            ) : //If formStatus is loading, show the loading icon
                                            null
                                        }
                                    </Box>
                                </FormControl>
                            </Box>
                        )
                    ) : (
                        <Box>
                            {returnedSearchData === 'No account found' ? (
                                <Grid container>
                                    <Grid item xs={12} md={12}>
                                        <Failed
                                            error={
                                                'No account found with that info. You can try again, or wait for an admin to sync your account manually.'
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={12}
                                        display={'flex'}
                                        flexDirection={'row'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <Button
                                            onClick={() => {
                                                setReturnedSearchData(null)
                                            }}
                                        >
                                            Try Again
                                        </Button>

                                        {/*
                                            TODO: Decide if we want users to be able to log in even
                                            if they cant find a previously created account. Right now this is NOT allowed.
                                            
                                            <Grid item xs={6} md={6}>
                                                <Button
                                                    onClick={handleRegister}
                                                >
                                                    Proceed anyway
                                                </Button>
                                            </Grid>
                                            */}
                                    </Grid>
                                </Grid>
                            ) : returnedSearchData ===
                              'More than 1 account found with that phone number' ? (
                                <Box>
                                    <Failed
                                        error={'More than 1 account found'}
                                    />
                                    <Button
                                        onClick={() => {
                                            setReturnedSearchData(null)
                                        }}
                                    >
                                        Try Again
                                    </Button>
                                </Box>
                            ) : (
                                <Box>
                                    <Typography
                                        variant="h3"
                                        textAlign={'center'}
                                    >
                                        <Success title={'Account Found'} />
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        textAlign={'center'}
                                    >
                                        Name: {returnedSearchData.first_name}{' '}
                                        {returnedSearchData.last_name}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        textAlign={'center'}
                                    >
                                        Phone Number:{' '}
                                        {returnedSearchData.phone_number}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        textAlign={'center'}
                                    >
                                        Email: {returnedSearchData.email}
                                    </Typography>
                                    <Typography
                                        variant="body1"
                                        textAlign={'center'}
                                    >
                                        Address: {returnedSearchData.address}
                                    </Typography>
                                    <Box
                                        display={'flex'}
                                        flexDirection={'column'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <Box
                                            display={'flex'}
                                            flexDirection={'row'}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                        >
                                            <Button
                                                variant="contained"
                                                type="submit"
                                                onClick={handleSyncSubmit}
                                            >
                                                Sync with this account
                                            </Button>
                                            <Button
                                                onClick={() => {
                                                    setReturnedSearchData(null)
                                                }}
                                            >
                                                Cancel
                                            </Button>
                                        </Box>

                                        {formStatus === 'success' ? (
                                            <Box width={'50%'} padding={1}>
                                                <Success
                                                    title={'Synced!'}
                                                ></Success>{' '}
                                            </Box>
                                        ) : null}
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
        </Box>
    ) : (
        <Loading />
    )
}
