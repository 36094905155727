import React, { useEffect } from 'react'
import {
    Box,
    Typography,
    Grid,
    Card,
    CardContent,
    Button,
    CardActionArea,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { Helmet } from 'react-helmet'
import { Warehouse, Cottage, Fort, Home } from '@mui/icons-material'
import Car from '../../utils/images/car-top-view-icon.svg'
import { useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'

export default function SnowplowingPricing() {
    const theme = useTheme()
    const location = useLocation()
    const navigate = useNavigate()

    const [driveways] = useState([
        {
            size: 'Small',
            details: '~1 Car Driveway',
            perPush: 40,
            monthly: 150,
            seasonalRegular: 750,
            seasonalDiscounted: 700,
        },
        {
            size: 'Medium',
            details: '~2 Car Driveway',
            perPush: 50,
            monthly: 200,
            seasonalRegular: 1000,
            seasonalDiscounted: 950,
        },
        {
            size: 'Large',
            details: '~4 Car Driveway',
            perPush: 60,
            monthly: 250,
            seasonalRegular: 1250,
            seasonalDiscounted: 1200,
        },
    ])

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
            title: 'Snowplow Pricing - Inver Grove Heights',
        })
    }, [location])

    return (
        <Grid container spacing={2}>
            <Helmet>
                <meta
                    name="description"
                    content="Snowplow Pricing - Inver Grove Heights"
                />
                <meta name="title" content="Pricing - Snow Removal"></meta>
                <meta
                    property="og:image"
                    content="https://allroundcontractingllc.com/static/media/ARC.fce879f1b4533f68cf02.png"
                />
                <meta
                    property="og:title"
                    content="Snowplow Pricing - Inver Grove Heights"
                />
                <meta
                    property="og:description"
                    content="Snowplow Pricing - Inver Grove Heights"
                />
                <meta
                    property="og:url"
                    content="https://allroundcontractingllc.com"
                />
                <meta property="og:type" content="website" />
                <title id="Snowplow Pricing - Inver Grove Heights">
                    Snowplow Pricing - Inver Grove Heights
                </title>

                <link
                    rel="canonical"
                    href="https://allroundcontractingllc.com"
                />
            </Helmet>
            <Grid item xs={12}>
                <Box
                    padding={2}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100%'}
                >
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'100%'}
                        padding={2}
                    >
                        <Box
                            textAlign={'center'}
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            width={'100%'}
                        >
                            <Typography
                                variant="h1"
                                component="div"
                                //make smaller
                                sx={{ fontSize: '3rem' }}
                                textAlign={'center'}
                                padding={1}
                                color={theme.palette.primary.textWhite}
                            >
                                How much is Inver Grove Heights Snow Removal?
                            </Typography>
                            <Typography
                                variant="h2"
                                component="div"
                                textAlign={'center'}
                                sx={{ fontSize: '1.25rem' }}
                                padding={1}
                                color={theme.palette.primary.textWhite}
                            >
                                {`Standard residential snowplowing prices range from $40 to $60 per service, $150 to $250 monthly, or $750 to $1250 for the season for Inver Grove Heights Snow Removal. We offer a wide range of services to fit your needs!`}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Grid item xs={12}>
                <Grid container spacing={2}>
                    {driveways.map((driveway, index) => (
                        <Grid
                            item
                            xs={12}
                            md={4}
                            key={index}
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Card
                                key={index}
                                sx={{
                                    flex: '1 1 30%',
                                    margin: '10px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                }}
                            >
                                <CardContent
                                    sx={{
                                        flexGrow: 1,
                                        display: 'flex',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Box padding={1} sx={{ flexGrow: 1 }}>
                                        <Typography
                                            variant="h6"
                                            component="div"
                                            textAlign={'left'}
                                            sx={{
                                                flexGrow: 2,
                                                fontWeight: 'bold',
                                                fontSize: '1.5rem',
                                            }}
                                            color={
                                                theme.palette.primary.textBlack
                                            }
                                        >
                                            {driveway.size}
                                        </Typography>
                                        <Typography
                                            variant="body1"
                                            component="div"
                                            textAlign={'left'}
                                            sx={{
                                                flexGrow: 1,
                                                fontWeight: 'lighter',
                                                fontSize: '1rem',
                                            }}
                                            color={
                                                theme.palette.primary.textBlack
                                            }
                                        >
                                            {driveway.details}
                                        </Typography>
                                        <Box
                                            display={'flex'}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                            flexDirection={'column'}
                                            padding={2}
                                        >
                                            {driveway.size === 'Small' ? (
                                                <>
                                                    <Cottage
                                                        style={{
                                                            fontSize: '5rem',
                                                        }}
                                                    />

                                                    <Box
                                                        backgroundColor={'grey'}
                                                        padding={1}
                                                    >
                                                        <img
                                                            src={Car}
                                                            alt="Car Top View Icon"
                                                            //make smaller
                                                            height={'50px'}
                                                        />
                                                    </Box>
                                                </>
                                            ) : driveway.size === 'Medium' ? (
                                                <>
                                                    <Warehouse
                                                        style={{
                                                            fontSize: '7rem',
                                                        }}
                                                    />
                                                    <Box
                                                        display={'flex'}
                                                        justifyContent={
                                                            'center'
                                                        }
                                                        alignItems={'center'}
                                                        flexDirection={'row'}
                                                        backgroundColor={'grey'}
                                                    >
                                                        <Box padding={0.5}>
                                                            <img
                                                                src={Car}
                                                                //make smaller

                                                                height={'50px'}
                                                                alt="Car Top View Icon"
                                                            />
                                                        </Box>
                                                        <Box padding={0.5}>
                                                            <img
                                                                src={Car}
                                                                height={'50px'}
                                                                alt="Car Top View Icon"
                                                            />
                                                        </Box>
                                                    </Box>
                                                </>
                                            ) : driveway.size === 'Large' ? (
                                                <>
                                                    <Fort
                                                        style={{
                                                            fontSize: '7rem',
                                                        }}
                                                    />
                                                    <Box
                                                        display={'flex'}
                                                        justifyContent={
                                                            'center'
                                                        }
                                                        alignItems={'center'}
                                                        flexDirection={'column'}
                                                        backgroundColor={'grey'}
                                                    >
                                                        <Box
                                                            display={'flex'}
                                                            justifyContent={
                                                                'center'
                                                            }
                                                            alignItems={
                                                                'center'
                                                            }
                                                            flexDirection={
                                                                'row'
                                                            }
                                                        >
                                                            <Box padding={0.5}>
                                                                <img
                                                                    src={Car}
                                                                    height={
                                                                        '50px'
                                                                    }
                                                                    alt="Car Top View Icon"
                                                                />
                                                            </Box>
                                                            <Box padding={0.5}>
                                                                <img
                                                                    src={Car}
                                                                    height={
                                                                        '50px'
                                                                    }
                                                                    alt="Car Top View Icon"
                                                                />
                                                            </Box>
                                                        </Box>
                                                        <Box
                                                            display={'flex'}
                                                            justifyContent={
                                                                'center'
                                                            }
                                                            alignItems={
                                                                'center'
                                                            }
                                                            flexDirection={
                                                                'row'
                                                            }
                                                        >
                                                            <Box padding={0.5}>
                                                                <img
                                                                    src={Car}
                                                                    height={
                                                                        '50px'
                                                                    }
                                                                    alt="Car Top View Icon"
                                                                />
                                                            </Box>
                                                            <Box padding={0.5}>
                                                                <img
                                                                    src={Car}
                                                                    height={
                                                                        '50px'
                                                                    }
                                                                    alt="Car Top View Icon"
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </>
                                            ) : (
                                                <>
                                                    <Home
                                                        style={{
                                                            fontSize: '5rem',
                                                        }}
                                                    />
                                                    <Car
                                                        style={{
                                                            fontSize: '2rem',
                                                        }}
                                                    />
                                                </>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box sx={{ marginTop: 'auto' }}>
                                        <Box
                                            display={'flex'}
                                            flexDirection={'column'}
                                            sx={{ marginTop: 'auto' }}
                                        >
                                            <Typography
                                                variant="body1"
                                                component="div"
                                                textAlign={'left'}
                                                paddingTop={1}
                                                sx={{ flexGrow: 1 }}
                                                color={
                                                    theme.palette.primary
                                                        .textBlack
                                                }
                                            >
                                                {`$${driveway.perPush} / Push`}
                                            </Typography>
                                            <Typography
                                                variant="body1"
                                                component="div"
                                                textAlign={'left'}
                                                sx={{ flexGrow: 1 }}
                                                color={
                                                    theme.palette.primary
                                                        .textBlack
                                                }
                                            >
                                                {`$${driveway.monthly} / Month`}
                                            </Typography>
                                            <Box
                                                display={'flex'}
                                                flexDirection={'row'}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    component="div"
                                                    textAlign={'left'}
                                                    sx={{
                                                        flexGrow: 1,
                                                        fontWeight: 'lighter',
                                                        textDecoration:
                                                            'line-through',
                                                    }}
                                                    color={
                                                        theme.palette.primary
                                                            .textBlack
                                                    }
                                                >
                                                    {`$${driveway.seasonalRegular} / Season`}
                                                </Typography>
                                            </Box>
                                            <Box
                                                display={'flex'}
                                                flexDirection={'row'}
                                            >
                                                <Typography
                                                    variant="body1"
                                                    component="div"
                                                    textAlign={'left'}
                                                    sx={{
                                                        flexGrow: 1,
                                                        fontWeight: 'bold',
                                                    }}
                                                    color={
                                                        theme.palette.primary
                                                            .textBlack
                                                    }
                                                >
                                                    {` $${driveway.seasonalDiscounted} / Season`}
                                                </Typography>
                                                <Typography paddingLeft={1}>
                                                    <span
                                                        style={{
                                                            fontWeight: 'bold',
                                                            color: 'red',
                                                        }}
                                                    >
                                                        Sale
                                                    </span>
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Box>
                                </CardContent>
                                <CardActionArea sx={{ flexShrink: 0 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        onClick={() => {
                                            navigate('/orderService')
                                        }}
                                    >
                                        Place a Deposit
                                    </Button>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Box
                    padding={2}
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100%'}
                >
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'100%'}
                        padding={2}
                    >
                        <Box
                            textAlign={'center'}
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            width={'100%'}
                        >
                            <Typography
                                variant="h3"
                                component="div"
                                //make smaller
                                sx={{ fontSize: '2rem' }}
                                textAlign={'center'}
                                padding={1}
                                color={theme.palette.primary.textWhite}
                            >
                                {`Residential & Commercial Snowplowing Services`}
                            </Typography>
                            <Typography
                                variant="body1"
                                component="div"
                                textAlign={'center'}
                                padding={1}
                                color={theme.palette.primary.textWhite}
                            >
                                {`For any sized property, contact us for a free estimate on your Inver Grove Heights snow removal needs. We offer a wide range of services to fit your property and the prices above are just for standard homes!`}
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    navigate('/contactUs')
                                }}
                            >
                                Contact Us
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}
