import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

const borderColor = '#3778C2'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#3778C2',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 12,
        fontStyle: 'bold',
    },
    description: {
        width: '85%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    total: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
    totalCancelled: {
        width: '25%',
        textAlign: 'right',
        paddingRight: 8,
    },
})

const InvoiceTableFooter = ({ invoice }) => {
    const total = invoice.charges
        .map((charge) => charge.amount)
        .reduce((accumulator, currentValue) => accumulator + currentValue, 0)
    return invoice.invoice_status === 'paid' ? (
        <View style={styles.row}>
            <Text style={styles.description}>Balance Due</Text>
            <Text style={styles.total}>PAID</Text>
        </View>
    ) : invoice.invoice_status === 'cancelled' ? (
        <View style={styles.row}>
            <Text style={styles.description}>Balance Due</Text>
            <Text style={styles.totalCancelled}>CANCELLED</Text>
        </View>
    ) : (
        <View style={styles.row}>
            <Text style={styles.description}>Balance Due</Text>
            <Text style={styles.total}>
                {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(total)}
            </Text>
        </View>
    )
}

export default InvoiceTableFooter
