import React, { useState } from 'react'
import { Typography, Grid, Tooltip, TextField } from '@mui/material'
import { Box, Button } from '@mui/material'
import Close from '@mui/icons-material/Close'
import axios from 'axios'
import Success from '../../Components/FormStatusIcons/Success'
import Failed from '../../Components/FormStatusIcons/Failed'
import { useTheme } from '@mui/material/styles'
import { useAuth0 } from '@auth0/auth0-react'
import ReactGA from 'react-ga4'

export default function EditCustomerPopover(props) {
    const { getAccessTokenSilently } = useAuth0()
    const { setEditUserPopover, customer } = props
    const theme = useTheme()

    const [firstName, setFirstName] = useState(customer?.first_name)
    const [lastName, setLastName] = useState(customer?.last_name)
    const [address, setAddress] = useState(customer?.address)
    const [phoneNumber, setPhoneNumber] = useState(customer?.phone_number)
    const [savedModal, setSavedModal] = useState(false)
    const [errorModal, setErrorModal] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [phoneErrorMessage, setPhoneErrorMessage] = useState('')

    var validatePhone = function (phone) {
        phone = phone.replace(/\D/g, '')

        if (!phone.match(/\d{10,10}/)) {
            return 'Phone number must be 10 digits. Ex: 123 456 7890'
        } else {
            return ''
        }
    }

    const handleSubmit = () => {
        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()

            setSubmitting(true)
            const phoneError = validatePhone(phoneNumber)
            if (
                phoneError ===
                'Phone number must be 10 digits. Ex: 123 456 7890'
            ) {
                setPhoneErrorMessage(phoneError)
                setSubmitting(false)
            } else {
                setPhoneErrorMessage('')
                axios
                    .post(
                        `${process.env.REACT_APP_BACKEND_DB}/updateUser`,
                        {
                            user_id: customer?.user_id,
                            first_name: firstName,
                            last_name: lastName,
                            address: address,
                            phone_number: phoneNumber,
                            customer_sub: customer?.customer_sub,
                            role: customer?.role,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    )
                    .then((response) => {
                        setSubmitting(false)
                        if (response.status === 200) {
                            ReactGA.event({
                                category: 'User',
                                action: 'User Updated',
                            })

                            setSavedModal(true)
                            setTimeout(() => {
                                setSavedModal(false)
                                setEditUserPopover(false)
                            }, 1500)
                        } else {
                            setErrorModal(true)
                            setTimeout(() => {
                                setErrorModal(false)
                            }, 5000)
                        }
                    })
            }
        }
        getJWT()
        //Display a toast that says the user has been updated for 1 second
    }

    return (
        <Box
            position={'fixed'}
            top={'0'}
            left={'0'}
            width={'100%'}
            height={'100%'}
            backgroundColor={'rgba(0, 0, 0, 0.5)'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            zIndex={'2'}
            sx={{ backdropFilter: 'blur(5px)' }}
        >
            <Box
                width={'100%'}
                height={'100%'}
                position={'absolute'}
                top={'0'}
                left={'0'}
                zIndex={'2'}
                onClick={() => setEditUserPopover(false)}
            ></Box>
            <Box
                zIndex={'3'}
                width={'auto'}
                backgroundColor={theme.palette.primary.textWhite}
                borderRadius={2}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
                alignItems={'center'}
                padding={'5vh'}
            >
                <Box className="addUserPopoverCancelPlacer">
                    <Close
                        className="addUserPopoverCancel"
                        onClick={() => setEditUserPopover(false)}
                    />
                </Box>
                <Box sx={{ mb: 2.5 }}>
                    <Typography
                        variant="h3"
                        color={theme.palette.primary.textBlack}
                    >
                        Edit Profile
                    </Typography>
                </Box>

                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="First Name"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Last Name"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Tooltip title="Email cannot be changed here, please contact an admin.">
                            <TextField
                                fullWidth
                                label="Email"
                                value={customer?.email}
                                disabled
                            />
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            fullWidth
                            label="Phone Number"
                            value={phoneNumber}
                            onChange={(e) => setPhoneNumber(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Address"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </Grid>

                    <Grid
                        container
                        spacing={2}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid
                            container
                            item
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            xs={12}
                            sx={{ marginTop: '1rem' }}
                        >
                            <Button
                                onClick={handleSubmit}
                                disabled={submitting}
                                variant="contained"
                                sx={{
                                    color: theme.palette.primary.textWhite,
                                    backgroundColor:
                                        theme.palette.primary.backup,
                                }}
                            >
                                Submit
                            </Button>
                            {savedModal ? (
                                <Success title={'User Updated!'} />
                            ) : null}
                            {errorModal ? <Failed /> : null}
                            {phoneErrorMessage ? (
                                <Failed error={phoneErrorMessage} />
                            ) : null}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
