import React, { useState } from 'react'
import axios from 'axios'
import { Close } from '@mui/icons-material'
import { Box } from '@mui/system'
import {
    Input,
    InputLabel,
    Typography,
    Button,
    CircularProgress,
} from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import Success from '../../Components/FormStatusIcons/Success'

export default function AddPricingPopover(props) {
    const { getAccessTokenSilently } = useAuth0()
    const { setPopoverVisible, user_id, currentPricing } = props
    const [pricing, setPricing] = useState({})
    const [formStatus, setFormStatus] = useState('')

    const handleSubmit = () => {
        setFormStatus('submitting')
        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()

            if (currentPricing) {
                axios
                    .put(
                        `${process.env.REACT_APP_BACKEND_DB}/updatePricing/${user_id}`,
                        {
                            user_id: user_id,
                            less_than_six:
                                pricing.less_than_six ||
                                currentPricing.less_than_six,
                            six_to_twelve:
                                pricing.six_to_twelve ||
                                currentPricing.six_to_twelve,
                            greater_than_twelve:
                                pricing.greater_than_twelve ||
                                currentPricing.greater_than_twelve,
                            monthly: pricing.monthly || currentPricing.monthly,
                            salting_price:
                                pricing.salting_price ||
                                currentPricing.salting_price,
                        },

                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    )
                    .then(() => {
                        setFormStatus('submitted')
                        setTimeout(() => {
                            setPopoverVisible(false)
                        }, 1500)
                    })
            } else {
                axios
                    .post(
                        `${process.env.REACT_APP_BACKEND_DB}/addPricing`,
                        {
                            user_id: user_id,
                            less_than_six:
                                pricing?.less_than_six ||
                                currentPricing?.less_than_six,
                            six_to_twelve:
                                pricing?.six_to_twelve ||
                                currentPricing?.six_to_twelve,
                            greater_than_twelve:
                                pricing?.greater_than_twelve ||
                                currentPricing?.greater_than_twelve,
                            monthly:
                                pricing?.monthly || currentPricing?.monthly,
                            salting_price:
                                pricing?.salting_price ||
                                currentPricing?.salting_price,
                        },

                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    )
                    .then(() => {
                        setFormStatus('submitted')
                        setTimeout(() => {
                            setPopoverVisible(false)
                        }, 1500)
                    })
            }
        }
        getJWT()
    }

    return (
        <Box
            position={'fixed'}
            top={'0'}
            left={'0'}
            width={'100%'}
            height={'100%'}
            backgroundColor={'rgba(0, 0, 0, 0.5)'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            zIndex={'2'}
        >
            <Box
                width={'100%'}
                height={'100%'}
                position={'absolute'}
                top={'0'}
                left={'0'}
                onClick={() => setPopoverVisible(false)}
            ></Box>
            <Box
                zIndex={'1'}
                position={'relative'}
                width={'auto'}
                height={'auto'}
                backgroundColor={'white'}
                borderRadius={'10px'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                padding={'5vh'}
                overflow={'scroll'}
            >
                <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                    alignItems={'flex-start'}
                    width={'100%'}
                    marginBottom={'10px'}
                >
                    <Close
                        position={'static'}
                        color={'black'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={() => setPopoverVisible(false)}
                    />
                </Box>

                <Box textAlign={'center'}>
                    <Box mb={'2.5vh'}>
                        <Typography variant="h3">Add Pricing</Typography>
                        <Typography variant="body1">
                            Modify one or more fields to update pricing
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Box>
                        <InputLabel>Less than 6"</InputLabel>
                        <Input
                            type="number"
                            placeholder={
                                currentPricing?.less_than_six
                                    ? '$' + currentPricing?.less_than_six
                                    : '$0.00'
                            }
                            onChange={(e) =>
                                setPricing({
                                    ...pricing,
                                    less_than_six: e.target.value,
                                })
                            }
                        />
                    </Box>
                    <Box>
                        <InputLabel>6" to 12"</InputLabel>
                        <Input
                            type="number"
                            placeholder={
                                currentPricing?.six_to_twelve
                                    ? '$' + currentPricing?.six_to_twelve
                                    : '$0.00'
                            }
                            onChange={(e) =>
                                setPricing({
                                    ...pricing,
                                    six_to_twelve: e.target.value,
                                })
                            }
                        />
                    </Box>
                    <Box>
                        <InputLabel>12" + </InputLabel>
                        <Input
                            type="number"
                            placeholder={
                                currentPricing?.greater_than_twelve
                                    ? '$' + currentPricing?.greater_than_twelve
                                    : '$0.00'
                            }
                            onChange={(e) =>
                                setPricing({
                                    ...pricing,
                                    greater_than_twelve: e.target.value,
                                })
                            }
                        />
                    </Box>
                    <Box>
                        <InputLabel>Monthly</InputLabel>
                        <Input
                            type="number"
                            placeholder={
                                currentPricing?.monthly
                                    ? '$' + currentPricing?.monthly
                                    : '$0.00'
                            }
                            onChange={(e) =>
                                setPricing({
                                    ...pricing,
                                    monthly: e.target.value,
                                })
                            }
                        />
                    </Box>
                    <Box>
                        <InputLabel>Salting</InputLabel>
                        <Input
                            type="number"
                            placeholder={
                                currentPricing?.salting_price
                                    ? '$' + currentPricing?.salting_price
                                    : '$0.00'
                            }
                            onChange={(e) =>
                                setPricing({
                                    ...pricing,
                                    salting_price: e.target.value,
                                })
                            }
                        />
                    </Box>
                    <Box mt={'2.5vh'}>
                        <Button onClick={handleSubmit}>Submit</Button>
                    </Box>
                    <Box>
                        {formStatus === 'submitting' && (
                            <Typography>
                                <CircularProgress size={24} />
                                Submitting...
                            </Typography>
                        )}
                        {formStatus === 'submitted' && (
                            <Success title={'Pricing Added'} />
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
