//This will be a component with MUI InputLabels and Input components
//It will have options to set the service_performed, date_serviced, amount and is_paid
//It should post all charges to the charges table using the user_id passed in as a prop
//There should be the ability to add an unlimited number of charges for the user by clicking a + button
//There should be the ability to delete a charge by clicking a - button
//There should be the ability to update a charge by clicking an update button
//Once all the charges have been added, there is a submit button that will send all the charges to the database

import React, { useEffect, useState } from 'react'
import axios from 'axios'
import {
    TextField,
    Button,
    Typography,
    FormControl,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    InputLabel,
    Grid,
    Checkbox,
    InputAdornment,
    OutlinedInput,
    FormHelperText,
} from '@mui/material'
import { Box } from '@mui/system'
import { useAuth0 } from '@auth0/auth0-react'
import { DatePicker } from '@mui/x-date-pickers'

export default function SingleUserBulkAddCharges(props) {
    const { getAccessTokenSilently } = useAuth0()
    const { user_id } = props
    const [charges, setCharges] = useState([])
    const [service_performed, setService_performed] = useState('')
    const [date_serviced, setDate_serviced] = useState('')
    const [amount, setAmount] = useState(0)
    const [is_paid, setIs_paid] = useState(false)
    const [error, setError] = useState(null)
    const [submitting, setSubmitting] = useState(false)

    const removeACharge = (charge) => {
        setCharges(charges.filter((c) => c !== charge))
    }

    const handleSubmit = () => {
        setSubmitting(true)

        if (!service_performed || !date_serviced || !amount) {
            setError('Please fill out all fields')
            setSubmitting(false)
            return
        } else {
            setError(null)
            charges.push({
                service_performed,
                date_serviced,
                amount,
                is_paid,
            })
        }
        setService_performed('')
        setDate_serviced('')
        setAmount('')
        setIs_paid(false)
    }

    const formSubmit = () => {
        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()

            charges.forEach((charge) => {
                if (user_id) {
                    axios
                        .post(
                            `${process.env.REACT_APP_BACKEND_DB}/addCharge`,
                            {
                                user_id: user_id,
                                amount: charge.amount,
                                service_date: charge.date_serviced,
                                service_performed: charge.service_performed,
                                is_paid: charge.has_been_invoiced,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            }
                        )
                        .then((response) => {
                            removeACharge(charge)
                        })
                } else {
                    console.log('no user id')
                }
            })
        }
        getJWT()
    }

    //update the charges table with the new charge
    useEffect(() => {}, [charges])

    return (
        <Box
            display="flex"
            flexDirection="column"
            direction="column"
            alignItems="center"
            justifyContent="center"
            p={'1vh'}
        >
            <Box display="flex" flexDirection="column" width={'100%'}>
                <Typography variant="h5">Add Charges</Typography>
                <TextField
                    label="Service Performed"
                    sx={{ p: '1vh' }}
                    variant="outlined"
                    value={service_performed}
                    required={true}
                    error={error && !service_performed}
                    onChange={(e) => setService_performed(e.target.value)}
                />
                <FormControl>
                    <DatePicker
                        sx={{ p: '1vh' }}
                        value={date_serviced}
                        error={error && !date_serviced}
                        onChange={(e) => setDate_serviced(e.target.value)}
                    />

                    {error && !date_serviced && (
                        <FormHelperText error id="accountId-error">
                            {error}
                        </FormHelperText>
                    )}
                </FormControl>

                <FormControl fullWidth variant="filled" sx={{ p: '1vh' }}>
                    <InputLabel htmlFor="filled-adornment-amount">
                        Amount
                    </InputLabel>
                    <OutlinedInput
                        id="filled-adornment-amount"
                        value={amount}
                        required={true}
                        error={error && !amount}
                        type="number"
                        onChange={(e) => setAmount(e.target.value)}
                        startAdornment={
                            <InputAdornment position="start">$</InputAdornment>
                        }
                    />
                    {error && !amount && (
                        <FormHelperText error id="accountId-error">
                            {error}
                        </FormHelperText>
                    )}
                </FormControl>
                <Box
                    display="flex"
                    flexDirection="row"
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid container>
                        <Grid
                            item
                            xs={4}
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                        >
                            <InputLabel>Is Paid?</InputLabel>
                        </Grid>
                        <Grid item xs={1} />

                        <Grid item xs={6}>
                            <Checkbox
                                checked={is_paid}
                                onChange={(e) => setIs_paid(e.target.checked)}
                            />
                        </Grid>
                        <Grid item xs={1} />
                    </Grid>
                </Box>
                <Button onClick={handleSubmit}>Add</Button>
            </Box>
            {charges.length > 0 && (
                <Box display="flex" flexDirection="column">
                    <Typography variant="h5" textAlign={'center'}>
                        Charges to Add
                    </Typography>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Service Performed</TableCell>
                                    <TableCell>Date Serviced</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Is Paid</TableCell>
                                    <TableCell>Remove</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {charges.map((charge) => (
                                    <TableRow key={charge.service_performed}>
                                        <TableCell>
                                            {charge.service_performed}
                                        </TableCell>
                                        <TableCell>
                                            {charge.date_serviced}
                                        </TableCell>
                                        <TableCell>{charge.amount}</TableCell>
                                        <TableCell>
                                            {charge.has_been_invoiced
                                                ? 'Yes'
                                                : 'No'}
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                onClick={() =>
                                                    removeACharge(charge)
                                                }
                                            >
                                                Remove
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Button disabled={submitting} onClick={formSubmit}>
                        Submit
                    </Button>
                </Box>
            )}
        </Box>
    )
}
