//This will be the forbidden page. It should have as simple a design as possible, and
//should be easy to navigate back to the home page from here.
//It should display 403 in the center of the page, and have a button to
//navigate back to the home page

import React, { useEffect } from 'react'
import { Typography, Box } from '@mui/material'
import { Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'

export default function Error403() {
    const theme = useTheme()
    const location = useLocation()

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
            title: '403 Forbidden',
        })
    }, [location])

    return (
        <Box
            p={'10vh'}
            container
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
            bgcolor={theme.palette.primary.supportingWhite}
        >
            <Typography
                variant="h2"
                component="div"
                textAlign={'center'}
                sx={{ flexGrow: 1, fontWeight: 'bold' }}
            >
                403
            </Typography>

            <Typography
                variant="h4"
                component="div"
                textAlign={'center'}
                sx={{ flexGrow: 1, fontWeight: 'bold' }}
            >
                Forbidden
            </Typography>

            <Typography
                variant="h6"
                component="div"
                textAlign={'center'}
                sx={{ flexGrow: 1, fontWeight: 'bold' }}
            >
                We're sorry, but you do not have access to this page.
            </Typography>

            <Button variant="contained" href="/" sx={{ fontWeight: 'bold' }}>
                Return to Home Page
            </Button>
        </Box>
    )
}
