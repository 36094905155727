import React, { useEffect } from 'react'

import { Box } from '@mui/system'
import { PDFViewer } from '@react-pdf/renderer'
import EstimatePDF from './EstimateTemplateFiles/Estimate'
import axios from 'axios'
import { useState } from 'react'
import { useTheme } from '@mui/material/styles'
import { useParams } from 'react-router-dom'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { Typography } from '@mui/material'
import { Button } from '@mui/material'
import ReactGA from 'react-ga4'

export default function ViewPDFEstimate() {
    const { view_token } = useParams()
    const [estimate, setEstimate] = useState({
        charges: [],
        notes: '',
    })

    const [loading, setLoading] = useState(false)
    const [viewEstimate, setViewEstimate] = useState(false)

    const theme = useTheme()

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            title: `/ViewEstimate/${view_token}`,
            page: 'View Estimate',
        })
    }, [view_token])

    useEffect(() => {
        setLoading(true)

        view_token &&
            axios
                .get(
                    `${process.env.REACT_APP_BACKEND_DB}/getEstimateByToken/${view_token}`
                )
                .then((response) => {
                    setEstimate(response.data)
                    setLoading(false)
                })
    }, [view_token])

    return (
        <Box p={'5vh'} minHeight={'80vh'}>
            <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
            >
                <Box
                    width={'100%'}
                    height={'100%'}
                    p={4}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        bgcolor={theme.palette.primary.supportingWhite}
                        borderRadius={5}
                        width={'100%'}
                        height={'100%'}
                        p={4}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <PDFDownloadLink
                            document={<EstimatePDF estimateData={estimate} />}
                            fileName={
                                estimate.address +
                                '_estimate_id_' +
                                estimate.estimate_id +
                                '.pdf'
                            }
                        >
                            {loading ? (
                                <Typography variant="h6">
                                    Loading document...
                                </Typography>
                            ) : (
                                <Typography variant="h6">
                                    Download Estimate
                                </Typography>
                            )}
                        </PDFDownloadLink>
                        <Box padding={2}>
                            <Button
                                variant="contained"
                                sx={{
                                    color: theme.palette.primary.backup,
                                }}
                                padding={2}
                                onClick={() => {
                                    setViewEstimate(!viewEstimate)
                                }}
                            >
                                {viewEstimate
                                    ? 'Hide Estimate'
                                    : 'View Estimate'}
                            </Button>
                        </Box>
                    </Box>
                </Box>

                {viewEstimate ? (
                    <PDFViewer
                        //if the screen size is less than 600 px, make the width 400 px, if it's more than 600 px make it 800 px
                        width={window.innerWidth < 600 ? 400 : 800}
                        height={window.innerHeight}
                        showToolbar={true}
                    >
                        <EstimatePDF estimateData={estimate} />
                    </PDFViewer>
                ) : null}
            </Box>
        </Box>
    )
}
