//This will be a page that will allow the user to submit information to receive a contact
//This page will have a form that will ask for the following information:
//First Name
//Last Name
//Email
//Phone Number
//Address
//describing the project
//The form will have a submit button that will send the information to the backend
//The backend will then send the information to the database
//The backend will then send the information to the email address of the user
//The user will then receive an email in their email
//The user will then be redirected to the home page
//The whole page should use MUI components
import React, { useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router'
import { Alert } from '@mui/material'

import { Box, Button, TextField, Typography } from '@mui/material'
import axios from 'axios'
import Success from '../FormStatusIcons/Success'
import { Helmet } from 'react-helmet'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'
import Loading from '../Loading/Loading'
import { Grid } from '@mui/material'
import FooterMap from '../Footer/FooterMap'

export default function ContactUs() {
    const navigate = useNavigate()
    const theme = useTheme()
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [address, setAddress] = useState('')
    const [projectDescription, setProjectDescription] = useState('')
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [currentTime] = useState(new Date())
    const location = useLocation()

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
        })
    }, [location])

    const cleanEmail = (email) => {
        let cleanedEmail = email.trim()
        return cleanedEmail
    }

    const handleSubmit = () => {
        if (
            !firstName ||
            !lastName ||
            !email ||
            !phone ||
            !address ||
            !projectDescription
        ) {
            setError(true)

            return
        }

        setSubmitting(true)
        axios
            .post(`${process.env.REACT_APP_BACKEND_DB}/contactUs`, {
                first_name: firstName,
                last_name: lastName,
                email: email,
                phone: phone,
                address: address,
                project_description: projectDescription,
            })
            .then((response) => {
                if (response.data === 'Email sent') {
                    setSubmitting(false)
                    setSuccess(true)

                    ReactGA.event({
                        category: 'Contact Us',
                        action: 'Contact request sent',
                    })

                    setTimeout(() => {
                        setSuccess(false)
                        navigate('/')
                    }, 1500)
                }
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            justifyContent={'center'}
            minHeight={'100vh'}
            height={'auto'}
        >
            <Helmet>
                <title>Contact Us - All Round Contracting LLC</title>
                <meta
                    name="description"
                    content="Fill out the form to get in touch with All Round Contracting LLC!"
                />

                <meta property="og:title" content="Contact Us" />
                <meta
                    property="og:description"
                    content="Fill out the form to get in touch with All Round Contracting LLC!"
                />
                <meta
                    property="og:image"
                    content="https://allroundcontractingllc.com/static/media/ARC.fce879f1b4533f68cf02.png"
                />
                <meta
                    property="og:url"
                    content="https://allroundcontractingllc.com/contactUs"
                />
                <meta property="og:type" content="website" />
                <meta name="twitter:title" content="Contact Us" />
                <meta
                    name="twitter:description"
                    content="Fill out the form to get in touch with All Round Contracting LLC!"
                />
                <meta
                    name="twitter:image"
                    content="https://allroundcontractingllc.com/static/media/ARC.fce879f1b4533f68cf02.png"
                />
                <meta name="twitter:card" content="summary_large_image" />
                <link
                    rel="canonical"
                    href="https://allroundcontractingllc.com/contactUs"
                />
            </Helmet>

            <Grid container backgroundColor={theme.palette.primary.textWhite}>
                <Grid item xs={12} md={6}>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        height={'auto'}
                        width={'100%'}
                        padding={1}
                    >
                        <Box height={'50vh'} width={'50vw'} p={2}>
                            <FooterMap></FooterMap>
                        </Box>
                        <Box
                            paddingLeft={1}
                            paddingRight={1}
                            //align the texts to the left and stack them vertically
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'flex-start'}
                            width={'100%'}
                        >
                            <Typography
                                variant={'h3'}
                                style={{ fontWeight: 'bold' }}
                                fontSize={'1.5rem'}
                                textAlign={'center'}
                                color={theme.palette.primary.textBlack}
                            >
                                All Round Contracting LLC
                            </Typography>
                        </Box>
                        <Box
                            paddingLeft={1}
                            paddingRight={1}
                            paddingTop={2}
                            //align the texts to the left and stack them vertically
                            display={'flex'}
                            flexDirection={'column'}
                            alignItems={'flex-start'}
                            width={'100%'}
                        >
                            <Typography
                                variant={'h3'}
                                fontSize={'1.5rem'}
                                textAlign={'left'}
                                fontWeight={'medium'}
                                width={'100%'}
                                color={theme.palette.primary.textBlack}
                            >
                                PO Box 2621
                            </Typography>
                            <Typography
                                variant={'h3'}
                                fontSize={'1.5rem'}
                                fontWeight={'medium'}
                                textAlign={'left'}
                                width={'100%'}
                                color={theme.palette.primary.textBlack}
                            >
                                Inver Grove Heights, MN 55076
                            </Typography>
                        </Box>
                        <Box
                            paddingLeft={1}
                            paddingRight={1}
                            paddingTop={2}
                            width={'100%'}
                        >
                            <Typography
                                variant={'h3'}
                                fontSize={'1.5rem'}
                                fontWeight={'light'}
                                textAlign={'left'}
                                color={theme.palette.primary.textBlack}
                            >
                                (651) 239-4444
                            </Typography>
                        </Box>
                        <Box paddingLeft={1} paddingRight={1} width={'100%'}>
                            <Typography
                                variant={'h3'}
                                fontSize={'1.5rem'}
                                fontWeight={'light'}
                                textAlign={'left'}
                                color={theme.palette.primary.textBlack}
                            >
                                contactus@allroundcontractingllc.com
                            </Typography>
                        </Box>
                    </Box>
                </Grid>

                <Grid item xs={12} md={6}>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        height={'auto'}
                        width={'100%'}
                    >
                        <Box padding={2}>
                            <Typography
                                variant={'h2'}
                                fontSize={'1.5rem'}
                                textAlign={'center'}
                                color={theme.palette.primary.textBlack}
                                padding={1}
                            >
                                Please fill out the required information and
                                we'll get back to you as soon as possible.
                            </Typography>
                        </Box>
                        <Grid container width={'100%'} padding={5}>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                padding={1}
                                width={'100%'}
                            >
                                <TextField
                                    color="primary"
                                    fullWidth
                                    label="First Name"
                                    variant="outlined"
                                    value={firstName}
                                    helperText={
                                        !firstName && error
                                            ? 'Please enter your first name'
                                            : null
                                    }
                                    error={!firstName && error}
                                    onChange={(e) =>
                                        setFirstName(e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                padding={1}
                                width={'100%'}
                            >
                                <TextField
                                    fullWidth
                                    label="Last Name"
                                    variant="outlined"
                                    value={lastName}
                                    helperText={
                                        !lastName && error
                                            ? 'Please enter your last name'
                                            : null
                                    }
                                    error={!lastName && error}
                                    onChange={(e) =>
                                        setLastName(e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                padding={1}
                                width={'100%'}
                            >
                                <TextField
                                    fullWidth
                                    label="Email"
                                    variant="outlined"
                                    value={email}
                                    helperText={
                                        !email && error
                                            ? 'Please enter your email'
                                            : null
                                    }
                                    error={!email && error}
                                    onChange={(e) =>
                                        setEmail(cleanEmail(e.target.value))
                                    }
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                md={6}
                                padding={1}
                                width={'100%'}
                            >
                                <TextField
                                    fullWidth
                                    label="Phone Number"
                                    type="tel"
                                    variant="outlined"
                                    value={phone}
                                    helperText={
                                        !phone && error
                                            ? 'Please enter your phone'
                                            : null
                                    }
                                    error={!phone && error}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} padding={1} width={'100%'}>
                                <TextField
                                    fullWidth
                                    label="Address"
                                    variant="outlined"
                                    value={address}
                                    helperText={
                                        !address && error
                                            ? 'Please enter your address'
                                            : null
                                    }
                                    error={!address && error}
                                    onChange={(e) => setAddress(e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} padding={1} width={'100%'}>
                                <TextField
                                    fullWidth
                                    label="Project Description"
                                    variant="outlined"
                                    multiline
                                    rows={4}
                                    helperText={
                                        !projectDescription && error
                                            ? 'Please describe your project'
                                            : null
                                    }
                                    error={!projectDescription && error}
                                    value={projectDescription}
                                    onChange={(e) =>
                                        setProjectDescription(e.target.value)
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} padding={1} width={'100%'}>
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                >
                                    {
                                        //if sunday, display the following message
                                        currentTime.getDay() === 0 ? (
                                            <Alert severity="info">
                                                For requests submitted on
                                                Sunday, we will typically
                                                respond Monday morning.
                                            </Alert>
                                        ) : //if saturday,
                                        currentTime.getDay() === 6 ? (
                                            currentTime.getHours() >= 9 &&
                                            currentTime.getHours() <
                                                12 ? null : (
                                                <Alert severity="info">
                                                    For requests submitted on
                                                    Saturday after 12pm, we will
                                                    typically respond Monday
                                                    morning.
                                                </Alert>
                                            )
                                        ) : //if after hours, display the following message
                                        currentTime.getHours() >= 8 &&
                                          currentTime.getHours() <= 18 ? (
                                            <Alert severity="info">
                                                During normal business hours we
                                                try and respond by email, phone,
                                                or text within 24 hours. For a
                                                faster response, give us a call!
                                            </Alert>
                                        ) : (
                                            //if the current time is between 5pm and 8am, display the following message
                                            <Alert severity="info">
                                                For requests submitted after
                                                hours, we will typically respond
                                                the following business day.
                                            </Alert>
                                        )
                                    }
                                </Box>
                            </Grid>
                            <Grid item xs={12} padding={1} width={'100%'}>
                                <Box
                                    padding={1}
                                    display={'flex'}
                                    flexDirection={'column'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    width={'100%'}
                                >
                                    {!submitting ? (
                                        <Button
                                            className="signUpButton"
                                            variant="contained"
                                            disabled={success}
                                            onClick={handleSubmit}
                                            sx={{
                                                backgroundColor:
                                                    theme.palette.primary
                                                        .backup,
                                                color: theme.palette.primary
                                                    .textWhite,
                                            }}
                                        >
                                            Submit
                                        </Button>
                                    ) : (
                                        <Loading />
                                    )}
                                    {success && (
                                        <Success
                                            title={'Contact request sent!'}
                                        />
                                    )}
                                    <Box padding={1}>
                                        <Typography
                                            variant={'h3'}
                                            fontSize={'1.5rem'}
                                            textAlign={'center'}
                                        >
                                            Thank you for considering All Round
                                            Contracting LLC!
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    )
}
//
