//This file will be a popover that will allow the user to add a user,
//then add charges to that user, and then create an invoice for that user all in a single popover.
//This will be used by the contractor dashboard.
//
//The popover will have the following fields:
// - First Name
// - Last Name
// - Email Address
// - Phone Number
// - Address

//A variable number of charges with the fields below:
// - Charge Amount
// - Charge Date
// - Service Performed
// - Due Date

//The popover will also have a "Submit" button that will send the data to the database and close the popover.
//The popover will also have a "Cancel" button that will close the popover.
//

import React, { useState } from 'react'

import axios from 'axios'
import { Close } from '@mui/icons-material'
import { Box } from '@mui/system'
import { Input, InputLabel, Typography, Button } from '@mui/material'
import Success from '../../Components/FormStatusIcons/Success'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import Charges from '../../Components/Charges/Charges'
import { useAuth0 } from '@auth0/auth0-react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import PlacesAutocomplete from 'react-places-autocomplete'
import moment from 'moment'
import Checkbox from '@mui/material/Checkbox'
import { useTheme } from '@mui/material/styles'

export default function AddUserChargeInvoicePopover(props) {
    const { getAccessTokenSilently } = useAuth0()
    const theme = useTheme()

    const { setPopoverVisible, contractorID } = props

    const [user_id, setUserID] = useState('')
    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('')
    const [email_address, setEmailAddress] = useState('')
    const [sendSignUpEmail, setSendSignUpEmail] = useState(false)

    const [phone_number, setPhoneNumber] = useState('')
    const [address, setAddress] = useState('')
    const [formStatus, setFormStatus] = useState('Awaiting User')

    const [charges, setCharges] = useState([])
    const [listOfChargesToInvoice, setListOfChargesToInvoice] = useState([])
    const [sendEmailNotification, setSendEmailNotification] = useState(false)
    const [chargeCardFee, setChargeCardFee] = useState(true)

    const [chargeAmount, setChargeAmount] = useState('')
    const [chargeDate, setChargeDate] = useState(dayjs(new Date()))
    const [servicePerformed, setServicePerformed] = useState('')

    //A function to clean an entered email number of any spaces at the beginning or end
    const cleanEmail = (email) => {
        let cleanedEmail = email.trim()
        return cleanedEmail
    }

    //A function to clean the phone number of any non numeric characters
    const cleanPhoneNumber = (phoneNumber) => {
        let cleanedPhoneNumber = phoneNumber.replace(/\D/g, '')
        return cleanedPhoneNumber
    }

    const handleSelect = async (value) => {
        setAddress(value)
    }

    const handleAddUserSubmit = () => {
        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()

            setFormStatus('Generating User')

            axios
                .post(
                    `${process.env.REACT_APP_BACKEND_DB}/addUser`,
                    {
                        first_name: first_name,
                        last_name: last_name,
                        email: email_address,
                        phone_number: phone_number,
                        address: address,
                        owned_by_contractor_user_id: contractorID,
                        role: 'customer',
                        sendSignUpEmail: sendSignUpEmail,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then((response) => {
                    setUserID(response.data.user_id)
                    setFormStatus('User Successfully Generated')
                    setTimeout(() => {
                        setFormStatus('User Generated')
                    }, 2000)
                })
        }
        getJWT()
    }

    const handleAddChargesSubmit = () => {
        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()

            setFormStatus('Posting Charges')

            charges.forEach((charge) => {
                axios
                    .post(
                        `${process.env.REACT_APP_BACKEND_DB}/addCharge`,
                        {
                            user_id: user_id,
                            amount: charge.amount,
                            service_date: charge.date_serviced,
                            service_performed: charge.service_performed,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    )
                    .then((response) => {
                        //get all charges for that user
                        axios
                            .get(
                                `${process.env.REACT_APP_BACKEND_DB}/getCharges/${user_id}`,
                                {
                                    headers: {
                                        Authorization: `Bearer ${accessToken}`,
                                    },
                                }
                            )
                            .then((response) => {
                                setCharges(response.data)
                            })

                        setFormStatus('Charges Successfully Posted')
                        setTimeout(() => {
                            setFormStatus('Charges Posted')
                        }, 2000)
                    })
            })
        }
        getJWT()
    }

    const handleAddInvoiceSubmit = async () => {
        const accessToken = await getAccessTokenSilently()

        setFormStatus('Generating Invoice')
        //Get the list of charges
        //Loop through the list of charges and add them to the invoice
        //Add the invoice to the database
        //Update the invoice list
        let totalAmount = 0
        let listOfChargeIDs = []
        var date = new Date()
        date.setDate(date.getDate() + 15)
        listOfChargesToInvoice.forEach((charge) => {
            totalAmount += charge.amount
            listOfChargeIDs.push(charge.charge_id)
        })

        axios
            .post(
                `${process.env.REACT_APP_BACKEND_DB}/createInvoice`,
                {
                    user_id: user_id,
                    total_amount: totalAmount,
                    charges: listOfChargeIDs,
                    date_generated: new Date(),
                    invoice_status: 'unpaid',
                    due_date: date,
                    send_email_notification: sendEmailNotification,
                    charge_card_fee: chargeCardFee,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )
            .then(() => {
                setFormStatus('Invoice Successfully Generated')
                setTimeout(() => {
                    setFormStatus('Invoice Generated')
                    setPopoverVisible(false)

                    //Set the popover to be invisible
                }, 2000)
            })
    }

    const addCharge = () => {
        const charge = {
            amount: chargeAmount,
            date_serviced: chargeDate,
            service_performed: servicePerformed,
        }
        setCharges([...charges, charge])
    }

    const removeCharge = (index) => {
        const newCharges = [...charges]
        newCharges.splice(index, 1)
        setCharges(newCharges)
    }

    return (
        <Box
            position={'fixed'}
            top={'0'}
            left={'0'}
            width={'100%'}
            height={'100%'}
            backgroundColor={'rgba(0, 0, 0, 0.5)'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            zIndex={'2'}
            sx={{ backdropFilter: 'blur(5px)' }}
        >
            <Box
                width={'auto'}
                height={'100%'}
                position={'absolute'}
                top={'0'}
                left={'0'}
                onClick={() => setPopoverVisible(false)}
            ></Box>
            <Box
                zIndex={'1'}
                position={'relative'}
                width={'auto'}
                height={'auto'}
                maxHeight={'95vh'}
                maxWidth={'95vw'}
                backgroundColor={'white'}
                borderRadius={'10px'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                padding={'5vh'}
                overflow={'auto'}
            >
                <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                    alignItems={'flex-start'}
                    width={'100%'}
                    marginBottom={'10px'}
                >
                    <Close
                        position={'static'}
                        color={'black'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={() => setPopoverVisible(false)}
                    />
                </Box>
                {formStatus === 'Awaiting User' ? (
                    <Box>
                        <Box textAlign={'center'}>
                            <Box mb={'2.5vh'}>
                                <Typography variant="h3">Add User</Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Box>
                                <InputLabel>First Name:</InputLabel>
                                <Input
                                    type="text"
                                    placeholder="First Name"
                                    onChange={(e) =>
                                        setFirstName(e.target.value)
                                    }
                                />
                            </Box>
                            <Box>
                                <InputLabel>Last Name:</InputLabel>
                                <Input
                                    type="text"
                                    placeholder="Last Name"
                                    onChange={(e) =>
                                        setLastName(e.target.value)
                                    }
                                />
                            </Box>
                            <Box>
                                <InputLabel>Email Address:</InputLabel>
                                <Input
                                    type="text"
                                    placeholder="Email Address"
                                    onChange={(e) =>
                                        setEmailAddress(
                                            cleanEmail(e.target.value)
                                        )
                                    }
                                />
                            </Box>
                            <Box>
                                <InputLabel>Phone Number:</InputLabel>
                                <Input
                                    type="tel"
                                    placeholder="Phone Number"
                                    onChange={(e) =>
                                        setPhoneNumber(
                                            cleanPhoneNumber(e.target.value)
                                        )
                                    }
                                />
                            </Box>
                            <Box paddingBottom={6}>
                                <InputLabel>Address:</InputLabel>
                                <PlacesAutocomplete
                                    value={address}
                                    onChange={setAddress}
                                    onSelect={handleSelect}
                                >
                                    {({
                                        getInputProps,
                                        suggestions,
                                        getSuggestionItemProps,
                                        loading,
                                    }) => (
                                        <Box>
                                            <Input
                                                {...getInputProps({
                                                    placeholder: 'Address',
                                                })}
                                            />
                                            <Box
                                                sx={{
                                                    //rounded corners
                                                    borderRadius: '5px',
                                                    position: 'absolute',
                                                    zIndex: '2',
                                                    backgroundColor:
                                                        theme.palette.primary
                                                            .textWhite,
                                                    width: 'auto',
                                                    maxHeight: '20vh',
                                                    overflowY: 'scroll',
                                                    padding: '0.5rem',
                                                }}
                                            >
                                                {loading ? (
                                                    <Box>...loading</Box>
                                                ) : null}

                                                {suggestions.map(
                                                    (suggestion) => {
                                                        const style =
                                                            suggestion.active
                                                                ? {
                                                                      backgroundColor:
                                                                          '#41b6e6',
                                                                      cursor: 'pointer',
                                                                  }
                                                                : {
                                                                      backgroundColor:
                                                                          theme
                                                                              .palette
                                                                              .primary
                                                                              .textWhite,
                                                                      cursor: 'pointer',
                                                                  }
                                                        return (
                                                            <Box
                                                                {...getSuggestionItemProps(
                                                                    suggestion,
                                                                    { style }
                                                                )}
                                                            >
                                                                {
                                                                    suggestion.description
                                                                }
                                                            </Box>
                                                        )
                                                    }
                                                )}
                                            </Box>
                                        </Box>
                                    )}
                                </PlacesAutocomplete>
                            </Box>

                            <Box>
                                <Checkbox
                                    value={sendSignUpEmail}
                                    onChange={(e) =>
                                        setSendSignUpEmail(e.target.checked)
                                    }
                                />
                            </Box>

                            <Box>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleAddUserSubmit}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    <></>
                )}
                {formStatus === 'Generating User' ? (
                    <Typography variant="h6">Generating User</Typography>
                ) : (
                    <></>
                )}
                {formStatus === 'User Generated' ? (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <Box textAlign={'center'}>
                            <Box mb={'2.5vh'}>
                                <Typography variant="h3">
                                    Log Charges
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Box>
                                <InputLabel>Charge Amount:</InputLabel>
                                <Input
                                    type="number"
                                    placeholder="Charge Amount"
                                    onChange={(e) =>
                                        setChargeAmount(e.target.value)
                                    }
                                />
                            </Box>
                            <Box>
                                <InputLabel>Charge Date:</InputLabel>
                                <DatePicker
                                    value={chargeDate}
                                    onChange={(e) =>
                                        setChargeDate(e.target.value)
                                    }
                                />
                            </Box>
                            <Box>
                                <InputLabel>Service Performed:</InputLabel>
                                <Input
                                    type="text"
                                    placeholder="Service Performed"
                                    onChange={(e) =>
                                        setServicePerformed(e.target.value)
                                    }
                                />
                            </Box>

                            <Box>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={addCharge}
                                >
                                    Add Charge
                                </Button>
                            </Box>
                        </Box>
                        <Box textAlign={'center'}>
                            <Box mb={'2.5vh'}>
                                <Typography variant="h3">Charges</Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Charge Amount</TableCell>
                                        <TableCell>Charge Date</TableCell>
                                        <TableCell>Service Performed</TableCell>

                                        <TableCell>Remove</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {charges.map((charge, index) => {
                                        return (
                                            <TableRow
                                                key={index}
                                                sx={{
                                                    '&:last-child td, &:last-child th':
                                                        {
                                                            border: 0,
                                                        },
                                                }}
                                            >
                                                <TableCell>
                                                    {charge.amount}
                                                </TableCell>
                                                <TableCell>
                                                    {moment
                                                        .utc(
                                                            charge.date_serviced
                                                        )

                                                        .format('MM/DD/YYYY')}
                                                </TableCell>

                                                <TableCell>
                                                    {charge.service_performed}
                                                </TableCell>

                                                <TableCell>
                                                    <Button
                                                        onClick={() =>
                                                            removeCharge(index)
                                                        }
                                                    >
                                                        Remove
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </Box>
                        <Box>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleAddChargesSubmit}
                            >
                                Post Charges
                            </Button>
                        </Box>
                    </Box>
                ) : (
                    <></>
                )}
                {formStatus === 'Posting Charges' ? (
                    <Typography variant="h6">Posting Charges</Typography>
                ) : (
                    <></>
                )}
                {formStatus === 'Charges Posted' ? (
                    <Box>
                        <Box textAlign={'center'}>
                            <Box mb={'2.5vh'}>
                                <Typography variant="h3">
                                    Generate Invoice
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Box>
                                <Charges
                                    customerID={user_id}
                                    isCustomer={false}
                                    listOfCharges={listOfChargesToInvoice}
                                    setListOfCharges={setListOfChargesToInvoice}
                                    isInvoices
                                ></Charges>
                            </Box>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                width={'auto'}
                                height={'auto'}
                                padding={2}
                            >
                                <Box>
                                    <Typography variant="body1">
                                        Charge Card Fee?
                                    </Typography>
                                </Box>
                                <ToggleButtonGroup
                                    value={chargeCardFee}
                                    exclusive
                                    onChange={
                                        //Toggle the charge card fee
                                        (event, newAlignment) => {
                                            setChargeCardFee(newAlignment)
                                        }
                                    }
                                    aria-label="text alignment"
                                    size={'small'}
                                >
                                    <ToggleButton value={true}>
                                        Yes
                                    </ToggleButton>
                                    <ToggleButton value={false}>
                                        No
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                width={'auto'}
                                height={'auto'}
                                padding={2}
                            >
                                <Box>
                                    <Typography variant="body1">
                                        Email Notification?
                                    </Typography>
                                </Box>
                                <ToggleButtonGroup
                                    value={sendEmailNotification}
                                    exclusive
                                    onChange={
                                        //Toggle the charge card fee
                                        (event, newAlignment) => {
                                            setSendEmailNotification(
                                                newAlignment
                                            )
                                        }
                                    }
                                    aria-label="text alignment"
                                    size={'small'}
                                >
                                    <ToggleButton value={true}>
                                        Yes
                                    </ToggleButton>
                                    <ToggleButton value={false}>
                                        No
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                            <Box>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={handleAddInvoiceSubmit}
                                >
                                    Create Invoice
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                ) : (
                    <></>
                )}

                {formStatus === 'User Successfully Generated' ? (
                    <Success title={'User Generated'}></Success>
                ) : (
                    <></>
                )}
                {formStatus === 'Charges Successfully Posted' ? (
                    <Success title={'Charges Generated'}></Success>
                ) : (
                    <></>
                )}
                {formStatus === 'Invoice Successfully Generated' ? (
                    <Success title={'Invoice Generated'}></Success>
                ) : (
                    <></>
                )}
            </Box>
        </Box>
    )
}
