import React, { useEffect } from 'react'

import { Box } from '@mui/system'
import { PDFViewer } from '@react-pdf/renderer'
import EstimatePDF from './EstimateTemplateFiles/Estimate'
import axios from 'axios'
import { useState } from 'react'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useTheme } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { Typography } from '@mui/material'
import { Button } from '@mui/material'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'
export default function GeneratePDFEstimate(props) {
    //Should return a mock invoice in PDF format
    const { getAccessTokenSilently } = useAuth0()
    const location = useLocation()

    const { estimate_id } = props
    const [estimate, setEstimate] = useState({
        charges: [],
        notes: '',
    })

    const [loading, setLoading] = useState(false)
    const [viewEstimate, setViewEstimate] = useState(false)

    const theme = useTheme()
    let navigate = useNavigate()

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
            title: 'Generate Estimate',
        })
    }, [location])

    useEffect(() => {
        setLoading(true)

        getAccessTokenSilently().then((token) => {
            estimate_id &&
                axios
                    .get(
                        `${process.env.REACT_APP_BACKEND_DB}/getEstimate/${estimate_id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        }
                    )
                    .then((response) => {
                        setEstimate(response.data)
                        setLoading(false)
                    })
        })
    }, [estimate_id, getAccessTokenSilently])

    return (
        <Box p={'5vh'}>
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'flex-start'}
                alignItems={'center'}
                width={'100%'}
                padding={'2vh'}
            >
                <ArrowBackIcon
                    sx={{
                        '&:hover': {
                            color: theme.palette.primary.textWhite,
                            cursor: 'pointer',
                        },
                    }}
                    onClick={() => navigate(-1)}
                />
            </Box>
            <Box
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDirection={'column'}
            >
                <Box
                    width={'100%'}
                    height={'100%'}
                    p={4}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Box
                        bgcolor={theme.palette.primary.supportingWhite}
                        borderRadius={2}
                        width={'100%'}
                        height={'100%'}
                        p={4}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <PDFDownloadLink
                            document={<EstimatePDF estimateData={estimate} />}
                            fileName={
                                estimate.address +
                                '_estimate_id_' +
                                estimate.estimate_id +
                                '.pdf'
                            }
                        >
                            {loading ? (
                                <Typography variant="h6">
                                    Loading document...
                                </Typography>
                            ) : (
                                <Typography variant="h6">
                                    Download Estimate
                                </Typography>
                            )}
                        </PDFDownloadLink>
                        <Box padding={2}>
                            <Button
                                variant="contained"
                                padding={2}
                                onClick={() => {
                                    setViewEstimate(!viewEstimate)
                                }}
                            >
                                {viewEstimate
                                    ? 'Hide Estimate'
                                    : 'View Estimate'}
                            </Button>
                        </Box>
                    </Box>
                </Box>

                {viewEstimate ? (
                    <PDFViewer
                        //if the screen size is less than 600 px, make the width 400 px, if it's more than 600 px make it 800 px
                        width={window.innerWidth < 600 ? 400 : 800}
                        height={window.innerHeight}
                        showToolbar={true}
                    >
                        <EstimatePDF estimateData={estimate} />
                    </PDFViewer>
                ) : null}
            </Box>
        </Box>
    )
}
