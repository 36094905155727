/*This will be the page for contractors to create a new route.
It will display a table with all of the customers that the contractor has that have an open job.
The contractor should be able to select customers from this table to add to the route.
The table should have the following columns:
- Customer Name
- Customer Address


*/

import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { DndProvider } from 'react-dnd'
import update from 'immutability-helper'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ConfirmRoutePopover from '../../modals/ConfirmRoutePopover/ConfirmRoutePopover'

import ReorderableRow from './ReorderableRow'

import { useAuth0 } from '@auth0/auth0-react'
import { Box } from '@mui/system'
import { Checkbox, TableContainer, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import {
    Table,
    TableBody,
    TableCell,
    TableRow,
    TableHead,
    Grid,
    Button,
} from '@mui/material'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import { ArrowDownward } from '@mui/icons-material'
import Alert from '@mui/material/Alert'

const useStyles = makeStyles((theme) => ({
    scrollToSaveButton: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(4),
        //make it a circle
        borderRadius: 4,
        //make it a little bigger
        width: 'auto',
        height: 'auto',
        //make it a little more visible
        opacity: 1,

        backgroundColor: theme.palette.primary.backup,
        color: theme.palette.common.white,
        '&:hover': {
            backgroundColor: theme.palette.primary.dark,
        },
    },
}))

export default function CreateANewRoute({
    contractorID,
    setToOptimizedRoute,
    toggle,
    setBackToRoutes,
}) {
    const { getAccessTokenSilently } = useAuth0()
    const location = useLocation()
    const theme = useTheme()
    const classes = useStyles()

    const [proposedRoute, setProposedRoute] = useState([])
    const [confirmRoutePopoverVisible, setConfirmRoutePopoverVisible] =
        useState(false)

    const [customers, setCustomers] = useState()

    const [createRouteFormStatus, setCreateRouteFormStatus] = useState('Create')

    const handleAddCustomerToRoute = (customer) => {
        if (proposedRoute.includes(customer)) {
            setProposedRoute(proposedRoute.filter((c) => c !== customer))
        } else {
            setProposedRoute([...proposedRoute, customer])
        }
    }

    const moveRow = useCallback(
        (dragIndex, hoverIndex) => {
            const dragRow = proposedRoute[dragIndex]
            setProposedRoute(
                update(proposedRoute, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragRow],
                    ],
                })
            )
        },
        [proposedRoute]
    )

    const tempAddy = '5912 Concord Blvd Inver Grove Heights, MN 55076'

    const optimizeRoute = async () => {
        axios
            .post(
                //http://maps.googleapis.com/maps/api/directions/json?waypoints=optimize:true|Barossa+Valley,SA|Clare,SA|Connawarra,SA|McLaren+Vale,SA&
                `https://routes.googleapis.com/directions/v2:computeRoutes?key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
                {
                    origin: {
                        address: tempAddy, //route?.route_customers[0]?.address,
                    },
                    destination: {
                        address: tempAddy, //route?.route_customers[0]?.address,
                    },
                    intermediates: [
                        //loop through the waypoints and return the address
                        proposedRoute.map((customer) => {
                            return {
                                address: customer.address,
                            }
                        }),
                    ],

                    travelMode: 'DRIVE',

                    optimizeWaypointOrder: true,
                },
                {
                    headers: {
                        'X-Goog-FieldMask': '*',
                    },
                }
            )
            .then((response) => {
                //get the returned waypoints and update the proposedRoute with the order using the optimizeIntermediateWaypointIndex, which is an ordered array of the index of the address
                //from the proposedRoute array
                if (!response.data.routes) {
                    setCreateRouteFormStatus('Attempted')
                    setToOptimizedRoute()

                    return
                }

                const optimizedRoute =
                    response.data.routes[0].optimizedIntermediateWaypointIndex
                const newProposedRoute = []
                optimizedRoute.forEach((index) => {
                    newProposedRoute.push(proposedRoute[index])
                })
                setProposedRoute(newProposedRoute)
                setCreateRouteFormStatus('Optimized')
                setToOptimizedRoute()
            })
    }

    const handleScrollToSave = () => {
        //scroll to the bottom of the page in a smooth manner
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: 'smooth',
        })
    }

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
            title: 'Create A New Route',
        })
    }, [location])

    useEffect(() => {
        const getCustomers = async () => {
            const accessToken = await getAccessTokenSilently()

            contractorID &&
                axios
                    .get(
                        `${process.env.REACT_APP_BACKEND_DB}/getCustomersWithOpenJobs/${contractorID}`,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    )
                    .then((response) => {
                        setCustomers(response.data)
                    })
        }

        getCustomers()
    }, [contractorID, getAccessTokenSilently])

    return (
        <Grid container spacing={2} color={theme.palette.primary.textWhite}>
            <Grid item xs={12}>
                <Box>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'flex-start'}
                        alignItems={'center'}
                        width={'100%'}
                    ></Box>
                    <Typography
                        variant="h3"
                        component="div"
                        textAlign={'center'}
                    >
                        Create a Route
                    </Typography>
                </Box>
            </Grid>
            {confirmRoutePopoverVisible && (
                <ConfirmRoutePopover
                    proposedRoute={proposedRoute}
                    setPopoverVisible={setConfirmRoutePopoverVisible}
                    contractorID={contractorID}
                    setBackToRoutes={setBackToRoutes}
                ></ConfirmRoutePopover>
            )}
            {toggle === 'Create A New Route' && (
                <Grid item xs={12}>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Typography
                            variant="h5"
                            component="div"
                            textAlign={'center'}
                            padding={2}
                            sx={{ flexGrow: 1, fontWeight: 'bold' }}
                        >
                            Customers With Open Jobs
                        </Typography>
                    </Box>

                    {customers &&
                        customers
                            ?.sort((a, b) => {
                                return b.user_id - a.user_id
                            })
                            .map((customer, index) => (
                                <Box
                                    key={index}
                                    sx={{
                                        margin: '10px',
                                        padding: '10px',
                                        backgroundColor:
                                            theme.palette.primary
                                                .supportingWhite,
                                        color: theme.palette.primary.textBlack,
                                        borderRadius: 2,
                                        border: '1px solid black',
                                    }}
                                >
                                    <Grid container>
                                        <Grid item xs={12} sm={9}>
                                            <Typography
                                                style={{
                                                    fontSize: '1.5rem',
                                                    fontWeight: 'bold',
                                                }}
                                            >
                                                {customer.address}
                                            </Typography>
                                            <Typography
                                                style={{
                                                    fontSize: '1rem',

                                                    fontWeight: 'medium',
                                                }}
                                            >
                                                {customer.first_name +
                                                    ' ' +
                                                    customer.last_name}
                                            </Typography>
                                            <Typography
                                                style={{
                                                    fontSize: '1rem',

                                                    fontWeight: 'medium',
                                                }}
                                            >
                                                {customer.phone_number}
                                            </Typography>
                                            <Typography
                                                style={{
                                                    fontWeight: 'light',
                                                    fontSize: '1rem',
                                                    marginBottom: '10px',
                                                }}
                                            >
                                                {customer.email}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} sm={3}>
                                            <Box
                                                height={'100%'}
                                                width={'100%'}
                                                display={'flex'}
                                                justifyContent={'center'}
                                                alignItems={'center'}
                                            >
                                                <Box padding={2}>
                                                    <Typography variant="h6">
                                                        Add To Route
                                                    </Typography>
                                                </Box>
                                                <Checkbox
                                                    sx={{
                                                        //When checked make color primary
                                                        color: theme.palette
                                                            .primary.backup,
                                                    }}
                                                    onChange={() => {
                                                        handleAddCustomerToRoute(
                                                            customer
                                                        )
                                                    }}
                                                />
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            ))}

                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        padding={2}
                    >
                        <Button
                            variant={'contained'}
                            onClick={() => {
                                optimizeRoute()
                            }}
                        >
                            Optimize & Continue
                        </Button>
                    </Box>
                    {/*
                        Below button is a hovering "Scroll to bottom"
                        */}
                    <Button
                        variant="contained"
                        className={classes.scrollToSaveButton}
                        onClick={handleScrollToSave}
                    >
                        <ArrowDownward />
                        Save
                    </Button>
                </Grid>
            )}
            {toggle === 'Optimized Route' && (
                <Grid item xs={12}>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        width={'100%'}
                        color={theme.palette.primary.textWhite}
                    >
                        <Box padding={2}>
                            <Box padding={2}>
                                <Typography
                                    variant="h5"
                                    component="div"
                                    textAlign={'center'}
                                    padding={'1rem'}
                                    sx={{ flexGrow: 1, fontWeight: 'bold' }}
                                >
                                    Proposed Optimized Route
                                </Typography>
                            </Box>
                        </Box>
                        {proposedRoute.length > 0 && (
                            <Box>
                                <TableContainer>
                                    <DndProvider backend={HTML5Backend}>
                                        <Table>
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell
                                                        sx={{
                                                            color: theme.palette
                                                                .primary
                                                                .textWhite,
                                                        }}
                                                    >
                                                        ID
                                                    </TableCell>
                                                    <TableCell
                                                        sx={{
                                                            color: theme.palette
                                                                .primary
                                                                .textWhite,
                                                        }}
                                                    >
                                                        Address
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody
                                                //make text white
                                                color={
                                                    theme.palette.primary
                                                        .textWhite
                                                }
                                            >
                                                {proposedRoute.map(
                                                    (customer, index) => (
                                                        <ReorderableRow
                                                            key={
                                                                customer.user_id
                                                            }
                                                            index={index}
                                                            id={
                                                                customer.user_id
                                                            }
                                                            details={
                                                                customer.address
                                                            }
                                                            moveRow={moveRow}
                                                        />
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </DndProvider>
                                </TableContainer>
                            </Box>
                        )}
                        {createRouteFormStatus === 'Attempted' ? (
                            <Box padding={2}>
                                <Alert severity="warning">
                                    Unable to Optimize Route, you can still save
                                    the route as is or manually reorder the
                                    route.
                                </Alert>
                            </Box>
                        ) : (
                            <Box padding={2}>
                                <Alert severity="success">
                                    Route Optimized
                                </Alert>
                            </Box>
                        )}
                        {proposedRoute.length > 0 ? (
                            <Box
                                display={'flex'}
                                flexDirection={'row'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Box padding={2}>
                                    <Button
                                        variant={'contained'}
                                        onClick={() => {
                                            setConfirmRoutePopoverVisible(
                                                !confirmRoutePopoverVisible
                                            )
                                        }}
                                    >
                                        Save / Start
                                    </Button>
                                </Box>
                            </Box>
                        ) : (
                            <Typography
                                variant="body1"
                                component="div"
                                textAlign={'center'}
                                padding={'1rem'}
                            >
                                Add Customers To Save Route
                            </Typography>
                        )}
                    </Box>
                </Grid>
            )}
        </Grid>
    )
}
