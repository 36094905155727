import React, { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'

// components
import { TableCell, TableRow } from '@mui/material'
import theme from '../../../theme'
// local components

const DraggableTypes = {
    REORDERABLE_ROW: 'reorderable_row',
}

const style = {
    padding: '10px',
    marginBottom: '5px',
    cursor: 'move',
}

const ReorderableRow = ({ id, details, index, moveRow }) => {
    const ref = useRef(null)
    const [, drop] = useDrop({
        accept: DraggableTypes.REORDERABLE_ROW,
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index

            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }

            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()

            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

            // Determine mouse position
            const clientOffset = monitor.getClientOffset()

            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top

            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%

            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }

            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }

            // Time to actually perform the action
            moveRow(dragIndex, hoverIndex)

            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag] = useDrag({
        item: { id, index },
        type: 'reorderable_row',
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    })

    const opacity = isDragging ? 0 : 1
    drag(drop(ref))
    return (
        <TableRow
            ref={ref}
            style={{ ...style, opacity }}
            sx={{
                color: theme.palette.primary.textWhite,
            }}
        >
            <TableCell
                sx={{
                    color: theme.palette.primary.textWhite,
                }}
            >
                {id}
            </TableCell>
            <TableCell
                sx={{
                    color: theme.palette.primary.textWhite,
                }}
            >
                {details}
            </TableCell>
        </TableRow>
    )
}

export default ReorderableRow
