//A popover that will be displayed on the customer detail screen when the contractor clicks on the "Add Charge" button.
//The popover will have the following fields:
// - Charge Amount
// - Charge Date
// - Service Performed
// - Due Date
//

//The popover will also have a "Submit" button that will send the data to the database and close the popover.
//The popover will also have a "Cancel" button that will close the popover.
//
//Import the addChargePopover.css file into this file.
//
//When a user clicks outside the popover, the popover will close.
//

import React, { useState } from 'react'

import axios from 'axios'
import { Close } from '@mui/icons-material'
import { Box } from '@mui/system'
import {
    TextField,
    Typography,
    Button,
    Grid,
    Checkbox,
    CircularProgress,
} from '@mui/material'
import Success from '../../Components/FormStatusIcons/Success'
import { useAuth0 } from '@auth0/auth0-react'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'

export default function CreatePaymentPopover(props) {
    const { getAccessTokenSilently } = useAuth0()
    const {
        setPopoverVisible,
        customerID,
        invoice,
        andMarkPaid,
        formStatus,
        setFormStatus,
        getContractorDashboard,
    } = props

    const [payment_amount, setPaymentAmount] = useState('')
    const [payment_date, setPaymentDate] = useState(dayjs(new Date()))
    const [payment_notes, setPaymentNotes] = useState('')
    const [payment_method, setPaymentMethod] = useState('')
    const [sendEmailReceipt, setSendEmailReceipt] = useState(false)

    const logPayment = (payment) => {
        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()

            setFormStatus('Generating Payment')

            axios
                .post(
                    `${process.env.REACT_APP_BACKEND_DB}/createPayment`,
                    {
                        user_id: customerID,
                        payment_amount: payment.payment_amount,
                        payment_date: payment.payment_date,
                        payment_notes: payment.payment_notes,
                        payment_method: payment.payment_method,
                        send_email_receipt: sendEmailReceipt,
                        invoice_id: invoice?.invoice_id,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then((response) => {
                    setFormStatus('Payment Generated')
                    if (andMarkPaid) {
                        andMarkPaid()
                    } else {
                        setTimeout(() => {
                            setPopoverVisible(false)
                            setFormStatus('')
                            getContractorDashboard()
                        }, 1000)
                    }
                })
        }
        getJWT()
    }

    const handleSubmit = () => {
        const payment = {
            payment_amount: payment_amount || invoice?.total_amount,
            payment_date: payment_date || new Date(),
            payment_notes:
                payment_notes || 'Paying invoice #' + invoice?.invoice_id,
            payment_method: payment_method,
        }
        logPayment(payment)
    }

    return (
        <Box
            position={'fixed'}
            top={'0'}
            left={'0'}
            width={'100%'}
            height={'100%'}
            backgroundColor={'rgba(0, 0, 0, 0.5)'}
            sx={{
                backdropFilter: 'blur(5px)',
            }}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            zIndex={'2'}
        >
            <Box
                width={'100%'}
                height={'100%'}
                position={'absolute'}
                top={'0'}
                left={'0'}
                onClick={() => setPopoverVisible(false)}
            ></Box>
            <Box
                zIndex={'1'}
                position={'relative'}
                width={'auto'}
                height={'auto'}
                maxHeight={'95vh'}
                maxWidth={'95vw'}
                backgroundColor={'white'}
                borderRadius={'10px'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                padding={'5vh'}
                overflow={'auto'}
            >
                <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                    alignItems={'flex-start'}
                    width={'100%'}
                    paddingBottom={'10px'}
                >
                    <Close
                        position={'static'}
                        color={'black'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={() => setPopoverVisible(false)}
                    />
                </Box>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Typography variant="h3">Add Payment</Typography>
                    </Grid>
                    <Grid item xs={6} md={9}>
                        <TextField
                            label={'Payment Amount'}
                            variant={'outlined'}
                            type="number"
                            fullWidth
                            value={payment_amount}
                            onChange={(e) => setPaymentAmount(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={6} md={3}>
                        <DatePicker
                            label="Payment Date"
                            value={payment_date}
                            onChange={(newValue) => setPaymentDate(newValue)}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={'Payment Notes'}
                            variant={'outlined'}
                            fullWidth
                            value={payment_notes}
                            onChange={(e) => setPaymentNotes(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label={'Payment Method'}
                            variant={'outlined'}
                            fullWidth
                            value={payment_method}
                            onChange={(e) => setPaymentMethod(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Box display={'flex'} flexDirection={'row'}>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Typography
                                    variant="body1"
                                    sx={{
                                        fontSize: '1rem',
                                        fontWeight: 'light',
                                        color: 'black',
                                    }}
                                >
                                    Send Email Receipt
                                </Typography>
                            </Box>
                            <Checkbox
                                checked={sendEmailReceipt}
                                onChange={(e) =>
                                    setSendEmailReceipt(e.target.checked)
                                }
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Box
                    display={'flex'}
                    flexDirection={'row'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100%'}
                    marginTop={'10px'}
                >
                    {formStatus === 'Generating Payment' ? (
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            onClick={handleSubmit}
                            disabled
                        >
                            <CircularProgress size={24}></CircularProgress>{' '}
                            Submitting
                        </Button>
                    ) : (
                        <Button
                            variant={'contained'}
                            color={'primary'}
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    )}
                </Box>

                {formStatus === 'Payment Generated' ? (
                    <Box padding={1}>
                        <Success
                            title={'Payment Generated'}
                            message={'Payment has been generated'}
                        ></Success>
                    </Box>
                ) : null}
            </Box>
        </Box>
    )
}
