//A popover that will be displayed on the customer detail screen when the contractor clicks on the "Add Charge" button.
//The popover will have the following fields:
// - Charge Amount
// - Charge Date
// - Service Performed
// - Due Date
//

//The popover will also have a "Submit" button that will send the data to the database and close the popover.
//The popover will also have a "Cancel" button that will close the popover.
//
//Import the addChargePopover.css file into this file.
//
//When a user clicks outside the popover, the popover will close.
//

import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { Close } from '@mui/icons-material'
import { Box } from '@mui/system'
import {
    Grid,
    Typography,
    Button,
    ToggleButtonGroup,
    ToggleButton,
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    TextField,
} from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import { useTheme } from '@mui/material/styles'

export default function AddChargePopover(props) {
    const theme = useTheme()
    const { getAccessTokenSilently } = useAuth0()
    const { setPopoverVisible, customerID, isBulk, contractorID } = props
    const [listOfCustomers, setListOfCustomers] = useState([{}])
    const [listOfCustomersToAddChargesTo, setListOfCustomersToAddChargesTo] =
        useState([])

    const [chargeAmount, setChargeAmount] = useState('')
    const [chargeDate, setChargeDate] = useState(dayjs(new Date()))
    const [servicePerformed, setServicePerformed] = useState('')
    const [toggle, setToggle] = useState(true)

    const handleToggle = (event, newToggle) => {
        if (newToggle === true) {
            setListOfCustomersToAddChargesTo([])
        }
        setToggle(newToggle)
    }

    const handleAddCustomer = (customer) => {
        //Add the customer to the listOfCustomersToAddChargesTo and remove them from the listOfCustomers
        setListOfCustomersToAddChargesTo([
            ...listOfCustomersToAddChargesTo,
            customer,
        ])
        setListOfCustomers(
            listOfCustomers.filter(
                (customerToRemove) =>
                    customerToRemove.user_id !== customer.user_id
            )
        )
    }

    const handleRemoveCustomer = (customer) => {
        //Add the customer to the listOfCustomers and remove them from the listOfCustomersToAddChargesTo
        setListOfCustomers([...listOfCustomers, customer])
        setListOfCustomersToAddChargesTo(
            listOfCustomersToAddChargesTo.filter(
                (customerToRemove) =>
                    customerToRemove.user_id !== customer.user_id
            )
        )
    }

    useEffect(() => {
        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()

            if (isBulk && toggle) {
                axios
                    .get(
                        `${process.env.REACT_APP_BACKEND_DB}/getCustomers/${contractorID}`,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    )
                    .then((response) => {
                        setListOfCustomers(response.data)
                    })
            }
        }
        getJWT()
    }, [isBulk, toggle, contractorID, getAccessTokenSilently])

    const handleSubmit = () => {
        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()

            if (isBulk && toggle) {
                //Get all customers for this contractor
                listOfCustomers.forEach((customer) => {
                    if (customer.user_id !== null) {
                        axios.post(
                            `${process.env.REACT_APP_BACKEND_DB}/addCharge`,
                            {
                                user_id: customer.user_id,
                                amount: chargeAmount,
                                service_date: chargeDate,
                                service_performed: servicePerformed,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            }
                        )
                    }
                })
                setPopoverVisible(false)
            } else if (isBulk && !toggle) {
                listOfCustomersToAddChargesTo.forEach((customer) => {
                    if (customer.user_id !== null) {
                        axios.post(
                            `${process.env.REACT_APP_BACKEND_DB}/addCharge`,
                            {
                                user_id: customer.user_id,
                                amount: chargeAmount,
                                service_date: chargeDate,
                                service_performed: servicePerformed,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            }
                        )
                    }
                })
                setPopoverVisible(false)
            } else {
                axios
                    .post(
                        `${process.env.REACT_APP_BACKEND_DB}/addCharge`,
                        {
                            user_id: customerID,
                            amount: chargeAmount,
                            service_date: chargeDate,
                            service_performed: servicePerformed,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    )
                    .then(() => {
                        setPopoverVisible(false)
                    })
            }
        }
        getJWT()
    }

    return (
        <Box
            position={'fixed'}
            top={'0'}
            left={'0'}
            width={'100%'}
            height={'100%'}
            backgroundColor={'rgba(0, 0, 0, 0.5)'}
            sx={{
                backdropFilter: 'blur(5px)',
            }}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            zIndex={'2'}
        >
            <Box
                width={'100%'}
                height={'100%'}
                position={'absolute'}
                top={'0'}
                left={'0'}
                onClick={() => setPopoverVisible(false)}
            ></Box>
            <Box
                zIndex={'1'}
                position={'relative'}
                width={'auto'}
                height={'auto'}
                maxHeight={'95vh'}
                maxWidth={'95vw'}
                backgroundColor={'white'}
                borderRadius={'10px'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                padding={'5vh'}
                overflow={'auto'}
            >
                <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                    alignItems={'flex-start'}
                    width={'100%'}
                    paddingBottom={'10px'}
                >
                    <Close
                        position={'static'}
                        color={'black'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={() => setPopoverVisible(false)}
                    />
                </Box>
                {/*TODO: Form Validation */}
                <Box textAlign={'center'}>
                    {isBulk ? (
                        <Box>
                            {toggle ? (
                                <Box>
                                    <Typography variant="h3">
                                        Add Bulk Charge
                                    </Typography>
                                    <Typography variant="body1">
                                        Add this charge to all your accounts
                                    </Typography>
                                </Box>
                            ) : (
                                <Box>
                                    <Typography variant="h3">
                                        Add Bulk Charge
                                    </Typography>
                                    <Typography variant="body1">
                                        Add Bulk Charges to select accounts
                                    </Typography>
                                </Box>
                            )}
                            <Box padding={'1vh'}>
                                {
                                    //A toggle button that will allow the user to select whether they want to add a charge to all their customers or just one customer.
                                }
                                <ToggleButtonGroup
                                    value={toggle}
                                    exclusive
                                    onChange={handleToggle}
                                    aria-label="text alignment"
                                >
                                    <ToggleButton
                                        value={true}
                                        aria-label="Add to all customers"
                                    >
                                        <Typography variant="h6">
                                            All Customers
                                        </Typography>
                                    </ToggleButton>
                                    <ToggleButton
                                        value={false}
                                        aria-label="Add to select customers"
                                    >
                                        <Typography variant="h6">
                                            Select Customers
                                        </Typography>
                                    </ToggleButton>
                                </ToggleButtonGroup>
                            </Box>
                        </Box>
                    ) : (
                        <Box>
                            <Typography variant="h3">Add Charge</Typography>
                        </Box>
                    )}
                </Box>
                <Box>
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        width={'100%'}
                        padding={2}
                    >
                        <Grid container spacing={2}>
                            <Grid
                                item
                                xs={6}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                color={theme.palette.primary.textWhite}
                            >
                                <TextField
                                    type="number"
                                    label="Charge Amount"
                                    value={chargeAmount}
                                    onChange={(e) =>
                                        setChargeAmount(e.target.value)
                                    }
                                    fullWidth
                                />
                            </Grid>
                            <Grid
                                item
                                xs={6}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <DatePicker
                                    label="Charge Date"
                                    value={chargeDate}
                                    onChange={(newValue) =>
                                        setChargeDate(newValue)
                                    }
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <TextField
                                    label="Service Performed"
                                    value={servicePerformed}
                                    onChange={(e) =>
                                        setServicePerformed(e.target.value)
                                    }
                                    fullWidth
                                />
                            </Grid>
                        </Grid>

                        {listOfCustomersToAddChargesTo.length > 0 ? (
                            <Box padding={'1vh'}>
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    backgroundColor={
                                        theme.palette.primary.textWhite
                                    }
                                    borderRadius={3}
                                >
                                    <Typography variant="h6">
                                        Add to these customers
                                    </Typography>
                                    <TableContainer>
                                        <Table>
                                            {
                                                //A table with a the following header columns: "Name, Address"
                                            }
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>
                                                        Address
                                                    </TableCell>
                                                    <TableCell>
                                                        Action
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {
                                                //A table body with a row for each customer
                                            }
                                            <TableBody>
                                                {listOfCustomersToAddChargesTo.map(
                                                    (customer) => {
                                                        return (
                                                            <TableRow
                                                                key={
                                                                    customer.user_id
                                                                }
                                                            >
                                                                <TableCell>
                                                                    {
                                                                        customer.first_name
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        customer.address
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                        onClick={() => {
                                                                            handleRemoveCustomer(
                                                                                customer
                                                                            )
                                                                        }}
                                                                    >
                                                                        Remove
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                        ) : null}
                        <Box padding={1}>
                            <Button variant="contained" onClick={handleSubmit}>
                                Add Charges
                            </Button>
                        </Box>

                        <Box>
                            {!toggle ? (
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    alignItems={'center'}
                                    justifyContent={'center'}
                                    backgroundColor={
                                        theme.palette.primary.textWhite
                                    }
                                    borderRadius={3}
                                >
                                    <Typography variant="h6">
                                        Select Customers To Charge
                                    </Typography>
                                    <TableContainer>
                                        <Table>
                                            {
                                                //A table with a the following header columns: "Name, Address"
                                            }
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>
                                                        Address
                                                    </TableCell>
                                                    <TableCell>
                                                        Action
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            {
                                                //A table body with a row for each customer
                                            }
                                            <TableBody>
                                                {listOfCustomers.map(
                                                    (customer) => {
                                                        return (
                                                            <TableRow
                                                                key={
                                                                    customer.user_id
                                                                }
                                                            >
                                                                <TableCell>
                                                                    {
                                                                        customer.first_name
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    {
                                                                        customer.address
                                                                    }
                                                                </TableCell>
                                                                <TableCell>
                                                                    <Button
                                                                        onClick={() => {
                                                                            handleAddCustomer(
                                                                                customer
                                                                            )
                                                                        }}
                                                                    >
                                                                        Add
                                                                    </Button>
                                                                </TableCell>
                                                            </TableRow>
                                                        )
                                                    }
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            ) : null}
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
