//This is the 404 page. It should have as simple a design as possible, and
//should be easy to navigate back to the home page from here.
//It should display 404 in the center of the page, and have a button to
//navigate back to the home page

import React, { useEffect } from 'react'
import { Typography, Box } from '@mui/material'
import { Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import ReactGA from 'react-ga4'
import { useLocation } from 'react-router-dom'

export default function Error404() {
    const theme = useTheme()
    const location = useLocation()

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
            title: '404',
        })
    }, [location])

    return (
        <Box
            height={'80vh'}
            bgcolor={theme.palette.primary.supportingWhite}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            alignItems={'center'}
        >
            <Box
                p={'10vh'}
                spacing={2}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Typography
                    variant="h2"
                    component="div"
                    textAlign={'center'}
                    sx={{ flexGrow: 1, fontWeight: 'bold' }}
                >
                    404
                </Typography>

                <Typography
                    variant="h4"
                    component="div"
                    textAlign={'center'}
                    sx={{ flexGrow: 1, fontWeight: 'bold' }}
                >
                    Page Not Found
                </Typography>

                <Typography
                    variant="h6"
                    component="div"
                    textAlign={'center'}
                    sx={{ flexGrow: 1, fontWeight: 'bold' }}
                >
                    We're sorry, but we couldn't find the page you were looking
                    for.
                </Typography>

                <Button
                    variant="contained"
                    href="/"
                    color="primary"
                    size="large"
                >
                    Home
                </Button>
            </Box>
        </Box>
    )
}
