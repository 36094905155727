import React, { Fragment } from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

const borderColor = '#3778C2'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#3778C2',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '80%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },

    amount: {
        width: '20%',
        textAlign: 'right',
        paddingRight: 8,
    },
})

const EstimateTableRow = ({ charges }) => {
    //loop through the charges and create a row for each charge. You'll need to parse the charge string to an object
    const rows = charges?.map((charge, i) => {
        const chargeObj = JSON.parse(charge)
        return (
            <View style={styles.row} key={`ETR${i}`}>
                <Text style={styles.description}>{chargeObj.description}</Text>
                <Text style={styles.amount}>
                    {new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    }).format(chargeObj.amount)}
                </Text>
            </View>
        )
    })

    return <Fragment>{rows}</Fragment>
}

export default EstimateTableRow
