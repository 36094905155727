import React from 'react'
import { Alert } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

export default function Success(props) {
    const { title } = props
    return (
        <Alert
            iconMapping={{
                success: <CheckCircleOutlineIcon fontSize="inherit" />,
            }}
        >
            {title}
        </Alert>
    )
}
