import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

const borderColor = '#3778C2'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#3778C2',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontSize: 12,
        fontStyle: 'bold',
    },
    description: {
        width: '85%',
        textAlign: 'right',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingRight: 8,
    },
    total: {
        width: '15%',
        textAlign: 'right',
        paddingRight: 8,
    },
    totalCancelled: {
        width: '25%',
        textAlign: 'right',
        paddingRight: 8,
    },
})

const EstimateTableFooter = ({ estimate }) => {
    const total = estimate?.charges?.reduce((acc, charge) => {
        const chargeObj = JSON.parse(charge)
        return acc + chargeObj.amount
    }, 0)

    return (
        <View style={styles.row}>
            <Text style={styles.description}>Estimate Total</Text>
            <Text style={styles.total}>
                {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(total)}
            </Text>
        </View>
    )
}

export default EstimateTableFooter
