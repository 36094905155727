//Create a static footer element that will have a link to the about us page
//as well as our hours, a small map of our location, our phone and our email
//The footer should be on every page
//The footer should be responsive but static to the bottom of the content

import React from 'react'
import { Box } from '@mui/system'
import { Typography, Grid, Divider } from '@mui/material'
import { Link } from 'react-router-dom'
import { Copyright } from '@mui/icons-material'
import { useTheme } from '@mui/material/styles'
import logo from '../../images/ARC.png'

export default function Footer() {
    const theme = useTheme()
    return (
        <Box p={2} boxShadow={3} width={'100vw'} height={'auto'} pb={5}>
            <Grid
                container
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Grid
                    item
                    xs={12}
                    md={4}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                >
                    <Box
                        p={2}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography
                            textAlign={'center'}
                            variant="h4"
                            color={theme.palette.primary.textWhite}
                        >
                            {' '}
                            Hours
                        </Typography>
                        <Divider
                            width={'25%'}
                            color={theme.palette.primary.textWhite}
                        />
                        <Typography
                            textAlign={'center'}
                            variant="h6"
                            color={theme.palette.primary.textWhite}
                            paddingTop={1}
                            sx={{ fontSize: '1rem' }}
                        >
                            Monday - Friday: 8am - 6pm
                        </Typography>
                        <Typography
                            textAlign={'center'}
                            variant="h6"
                            color={theme.palette.primary.textWhite}
                            sx={{ fontSize: '1rem' }}
                        >
                            Saturday: 9am - 12pm & By Appointment
                        </Typography>
                        <Typography
                            textAlign={'center'}
                            variant="h6"
                            color={theme.palette.primary.textWhite}
                            sx={{ fontSize: '1rem' }}
                        >
                            Sunday: By Appointment Only
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={4}>
                    <Box
                        p={2}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <img
                            width={'200px'}
                            height={'200px'}
                            src={logo}
                            loading="eager"
                            title="All Round Contracting LLC"
                            alt="All Round Contracting LLC"
                        />
                    </Box>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Box
                        p={2}
                        width={'100%'}
                        overflow={'wrap'}
                        sx={{ overflowWrap: 'anywhere' }}
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Typography
                            textAlign={'center'}
                            variant="h4"
                            color={theme.palette.primary.textWhite}
                        >
                            Contact Us
                        </Typography>
                        <Divider
                            width={'30%'}
                            color={theme.palette.primary.textWhite}
                        />
                        <Typography
                            textAlign={'center'}
                            variant="h6"
                            color={theme.palette.primary.textWhite}
                            paddingTop={1}
                            sx={{ fontSize: '1rem' }}
                        >
                            P: 651-239-4444
                        </Typography>
                        <Typography
                            textAlign={'center'}
                            variant="h6"
                            color={theme.palette.primary.textWhite}
                            //make the font smaller
                            sx={{ fontSize: '1rem' }}
                        >
                            E:{' '}
                            <Link
                                style={{
                                    color: theme.palette.primary.textWhite,
                                    textDecoration: 'none',
                                }}
                                href="mailto:

                        "
                            >
                                {'contactus@allroundcontractingllc.com'}
                            </Link>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
            <Box>
                <Typography
                    textAlign={'center'}
                    variant="body1"
                    color={theme.palette.primary.textWhite}
                >
                    All Round Contracting LLC <Copyright fontSize="inherit" />{' '}
                    2024
                </Typography>
            </Box>
        </Box>
    )
}
