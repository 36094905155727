//A popover that will display on the contractor dashboard when the user clicks on the "Add Customer" button.
//The popover will have the following fields for a new customer:
// - First Name
// - Last Name
// - Address
// - Email
// - Phone

// The popover will also have a "Submit" button that will send the data to the database and close the popover.
// The popover will also have a "Cancel" button that will close the popover.
//
//Import the addUserPopover.css file into this file.

//When a user clicks outside the popover, the popover will close.

import React, { useState } from 'react'
import './addUserPopover.css'
import axios from 'axios'
import { Box } from '@mui/system'
import { Typography, Button, Grid, TextField, Checkbox } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import PlacesAutocomplete from 'react-places-autocomplete'
import { useTheme } from '@mui/material/styles'
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles({
    whiteBorder: {
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'white',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
            },
        },
    },
})

export default function AddUserPopover(props) {
    const { getAccessTokenSilently } = useAuth0()
    const { setPopoverVisible, contractorID } = props
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [address, setAddress] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [pricing, setPricing] = useState({})
    const [sendSignUpEmail, setSendSignUpEmail] = useState(false)
    const [propertyNotes, setPropertyNotes] = useState('')
    const theme = useTheme()
    const classes = useStyles()

    const [addPricing, setAddPricing] = useState(false)

    const cleanEmail = (email) => {
        let cleanedEmail = email.trim()
        return cleanedEmail
    }

    const cleanPhoneNumber = (phoneNumber) => {
        let cleanedPhoneNumber = phoneNumber.replace(/\D/g, '')
        return cleanedPhoneNumber
    }

    const handleSelect = async (value) => {
        setAddress(value)
    }

    const handleSubmit = () => {
        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()

            axios
                .post(
                    `${process.env.REACT_APP_BACKEND_DB}/addUser`,
                    {
                        first_name: firstName,
                        last_name: lastName,
                        address: address,
                        email: email,
                        phone_number: phoneNumber,
                        owned_by_contractor_user_id: contractorID,
                        role: 'customer',
                        sendSignUpEmail: sendSignUpEmail,
                        property_notes: propertyNotes,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then((response) => {
                    if (addPricing) {
                        axios.post(
                            `${process.env.REACT_APP_BACKEND_DB}/addPricing`,
                            {
                                user_id: response.data.user_id,
                                less_than_six: pricing.less_than_six,
                                six_to_twelve: pricing.six_to_twelve,
                                greater_than_twelve:
                                    pricing.greater_than_twelve,
                                monthly: pricing.monthly,
                                salting_price: pricing.salting_price,
                            },
                            {
                                headers: {
                                    Authorization: `Bearer ${accessToken}`,
                                },
                            }
                        )
                    }
                    setPopoverVisible('View Customers')
                })
        }
        getJWT()
    }

    return (
        <Box color={theme.palette.primary.textWhite} padding={2}>
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                padding={1}
            >
                <Typography variant="h3">Add Customer</Typography>
            </Box>
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                padding={2}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            className={classes.whiteBorder}
                            InputLabelProps={{
                                style: {
                                    color: theme.palette.primary.textWhite,
                                },
                            }}
                            inputProps={{
                                style: {
                                    color: theme.palette.primary.textWhite,
                                },
                            }}
                            fullWidth
                            label="First Name"
                            variant="outlined"
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            className={classes.whiteBorder}
                            InputLabelProps={{
                                style: {
                                    color: theme.palette.primary.textWhite,
                                },
                            }}
                            inputProps={{
                                style: {
                                    color: theme.palette.primary.textWhite,
                                },
                            }}
                            fullWidth
                            label="Last Name"
                            variant="outlined"
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            className={classes.whiteBorder}
                            InputLabelProps={{
                                style: {
                                    color: theme.palette.primary.textWhite,
                                },
                            }}
                            inputProps={{
                                style: {
                                    color: theme.palette.primary.textWhite,
                                },
                            }}
                            fullWidth
                            label="Email"
                            variant="outlined"
                            value={email}
                            onChange={(e) =>
                                setEmail(cleanEmail(e.target.value))
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            className={classes.whiteBorder}
                            InputLabelProps={{
                                style: {
                                    color: theme.palette.primary.textWhite,
                                },
                            }}
                            inputProps={{
                                style: {
                                    color: theme.palette.primary.textWhite,
                                },
                            }}
                            fullWidth
                            label="Phone Number"
                            variant="outlined"
                            value={phoneNumber}
                            onChange={(e) =>
                                setPhoneNumber(cleanPhoneNumber(e.target.value))
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PlacesAutocomplete
                            value={address}
                            onChange={setAddress}
                            onSelect={handleSelect}
                        >
                            {({
                                getInputProps,
                                suggestions,
                                getSuggestionItemProps,
                                loading,
                            }) => (
                                <div>
                                    <TextField
                                        className={classes.whiteBorder}
                                        InputLabelProps={{
                                            style: {
                                                color: theme.palette.primary
                                                    .textWhite,
                                            },
                                        }}
                                        inputProps={{
                                            style: {
                                                color: theme.palette.primary
                                                    .textWhite,
                                            },
                                        }}
                                        fullWidth
                                        label="Address"
                                        variant="outlined"
                                        {...getInputProps({
                                            placeholder: 'Search Places ...',
                                        })}
                                    />
                                    <div>
                                        {loading ? <div>...loading</div> : null}

                                        {suggestions.map((suggestion) => {
                                            const style = {
                                                backgroundColor:
                                                    suggestion.active
                                                        ? '#41b6e6'
                                                        : '#fff',
                                                //make hand pointer when hovering over suggestion
                                                cursor: 'pointer',
                                            }

                                            return (
                                                <div
                                                    {...getSuggestionItemProps(
                                                        suggestion,
                                                        {
                                                            style,
                                                        }
                                                    )}
                                                >
                                                    {suggestion.description}
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )}
                        </PlacesAutocomplete>
                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            className={classes.whiteBorder}
                            InputLabelProps={{
                                style: {
                                    color: theme.palette.primary.textWhite,
                                },
                            }}
                            inputProps={{
                                style: {
                                    color: theme.palette.primary.textWhite,
                                },
                            }}
                            fullWidth
                            multiline
                            rows={3}
                            label="Property Notes"
                            variant="outlined"
                            value={propertyNotes}
                            onChange={(e) => setPropertyNotes(e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Checkbox
                            sx={{ color: theme.palette.primary.textWhite }}
                            checked={addPricing}
                            onChange={(e) => setAddPricing(e.target.checked)}
                        />
                        <Typography variant="body1">
                            Add Pricing for this customer
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Checkbox
                            sx={{ color: theme.palette.primary.textWhite }}
                            checked={sendSignUpEmail}
                            onChange={(e) =>
                                setSendSignUpEmail(e.target.checked)
                            }
                        />
                        <Typography variant="body1">
                            Send Sign Up Email
                        </Typography>
                    </Grid>
                    {addPricing && (
                        <Grid item xs={12}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">
                                        Pricing
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        className={classes.whiteBorder}
                                        InputLabelProps={{
                                            style: {
                                                color: theme.palette.primary
                                                    .textWhite,
                                            },
                                        }}
                                        inputProps={{
                                            style: {
                                                color: theme.palette.primary
                                                    .textWhite,
                                            },
                                        }}
                                        fullWidth
                                        label="Less than 6 inches"
                                        variant="outlined"
                                        value={pricing.less_than_six}
                                        onChange={(e) =>
                                            setPricing({
                                                ...pricing,
                                                less_than_six: e.target.value,
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        className={classes.whiteBorder}
                                        InputLabelProps={{
                                            style: {
                                                color: theme.palette.primary
                                                    .textWhite,
                                            },
                                        }}
                                        inputProps={{
                                            style: {
                                                color: theme.palette.primary
                                                    .textWhite,
                                            },
                                        }}
                                        fullWidth
                                        label="6 to 12 inches"
                                        variant="outlined"
                                        value={pricing.six_to_twelve}
                                        onChange={(e) =>
                                            setPricing({
                                                ...pricing,
                                                six_to_twelve: e.target.value,
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        className={classes.whiteBorder}
                                        InputLabelProps={{
                                            style: {
                                                color: theme.palette.primary
                                                    .textWhite,
                                            },
                                        }}
                                        inputProps={{
                                            style: {
                                                color: theme.palette.primary
                                                    .textWhite,
                                            },
                                        }}
                                        fullWidth
                                        label="Greater than 12 inches"
                                        variant="outlined"
                                        value={pricing.greater_than_twelve}
                                        onChange={(e) =>
                                            setPricing({
                                                ...pricing,
                                                greater_than_twelve:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        className={classes.whiteBorder}
                                        InputLabelProps={{
                                            style: {
                                                color: theme.palette.primary
                                                    .textWhite,
                                            },
                                        }}
                                        inputProps={{
                                            style: {
                                                color: theme.palette.primary
                                                    .textWhite,
                                            },
                                        }}
                                        fullWidth
                                        label="Monthly"
                                        variant="outlined"
                                        value={pricing.monthly}
                                        onChange={(e) =>
                                            setPricing({
                                                ...pricing,
                                                monthly: e.target.value,
                                            })
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        className={classes.whiteBorder}
                                        InputLabelProps={{
                                            style: {
                                                color: theme.palette.primary
                                                    .textWhite,
                                            },
                                        }}
                                        inputProps={{
                                            style: {
                                                color: theme.palette.primary
                                                    .textWhite,
                                            },
                                        }}
                                        fullWidth
                                        label="Salting Price"
                                        variant="outlined"
                                        value={pricing.salting_price}
                                        onChange={(e) =>
                                            setPricing({
                                                ...pricing,
                                                salting_price: e.target.value,
                                            })
                                        }
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </Box>
            <Box
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
                width={'100%'}
                padding={2}
            >
                <Button
                    variant="contained"
                    padding={2}
                    onClick={() => handleSubmit()}
                >
                    Submit
                </Button>
                <Box padding={2}>
                    <Button
                        variant="contained"
                        padding={2}
                        onClick={() => setPopoverVisible('View Customers')}
                    >
                        Cancel
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}
