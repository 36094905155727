//take in a user_id and return a MUI table of the users pricing information

import { Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { useAuth0 } from '@auth0/auth0-react'
import { Add } from '@mui/icons-material'
import AddPricingPopover from '../../../utils/modals/addPricingPopover/addPricingPopover'
import { useTheme } from '@mui/system'

export default function CustomerPricing(props) {
    const CurrencyFormat = require('react-currency-format')
    const theme = useTheme()

    const { getAccessTokenSilently } = useAuth0()
    const { customer } = props
    const [customerPricing, setCustomerPricing] = useState()
    const [addPricingPopoverVisible, setAddPricingPopoverVisible] =
        useState(false)
    useEffect(() => {
        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()

            customer &&
                axios
                    .get(
                        `${process.env.REACT_APP_BACKEND_DB}/getPricing/${customer?.user_id}`,
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    )
                    .then((response) => {
                        setCustomerPricing(response.data)
                    })
        }
        getJWT()
    }, [customer, getAccessTokenSilently, addPricingPopoverVisible])

    return (
        <Grid container direction="column" padding={2}>
            <Grid item xs={12}>
                <Box display={'flex'} flexDirection={'row'}>
                    <Box
                        display={'flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Typography
                            variant="h4"
                            sx={{
                                fontSize: '2rem',
                                fontWeight: 'title',
                                color: theme.palette.primary.textWhite,
                            }}
                        >
                            Pricing
                        </Typography>
                        <Add
                            sx={{
                                cursor: 'pointer',
                                '&:hover': {
                                    color: theme.palette.primary.textWhite,
                                },
                                fontSize: '1.25rem',
                                fontWeight: 'light',
                                color: theme.palette.primary.textWhite,
                            }}
                            onClick={() => {
                                setAddPricingPopoverVisible(true)
                            }}
                        ></Add>
                    </Box>
                </Box>
            </Grid>

            <Grid container item xs={12}>
                <Typography
                    variant="h5"
                    sx={{
                        fontSize: '1.25rem',
                        fontWeight: 'title',
                        color: theme.palette.primary.textWhite,
                    }}
                    paddingRight={1}
                >
                    Less than 6":{' '}
                </Typography>
                <Typography
                    variant="h5"
                    sx={{
                        fontSize: '1.25rem',
                        fontWeight: 'light',
                        color: theme.palette.primary.textWhite,
                    }}
                >
                    {' '}
                    <CurrencyFormat
                        prefix={'$'}
                        value={customerPricing?.less_than_six || 0}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </Typography>
            </Grid>
            <Grid container item xs={12}>
                <Typography
                    variant="h5"
                    sx={{
                        fontSize: '1.25rem',
                        fontWeight: 'title',
                        color: theme.palette.primary.textWhite,
                    }}
                    paddingRight={1}
                >
                    6" to 12":{' '}
                </Typography>
                <Typography
                    variant="h5"
                    sx={{
                        fontSize: '1.25rem',
                        fontWeight: 'light',
                        color: theme.palette.primary.textWhite,
                    }}
                >
                    {' '}
                    <CurrencyFormat
                        prefix={'$'}
                        value={customerPricing?.six_to_twelve || 0}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </Typography>
            </Grid>
            <Grid container item xs={12}>
                <Typography
                    variant="h5"
                    sx={{
                        fontSize: '1.25rem',
                        fontWeight: 'title',
                        color: theme.palette.primary.textWhite,
                    }}
                    paddingRight={1}
                >
                    12"+:{' '}
                </Typography>
                <Typography
                    variant="h5"
                    sx={{
                        fontSize: '1.25rem',
                        fontWeight: 'light',
                        color: theme.palette.primary.textWhite,
                    }}
                >
                    {' '}
                    <CurrencyFormat
                        prefix={'$'}
                        value={customerPricing?.greater_than_twelve || 0}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </Typography>
            </Grid>
            <Grid container item xs={12}>
                <Typography
                    variant="h5"
                    sx={{
                        fontSize: '1.25rem',
                        fontWeight: 'title',
                        color: theme.palette.primary.textWhite,
                    }}
                    paddingRight={1}
                >
                    Monthly:{' '}
                </Typography>
                <Typography
                    variant="h5"
                    sx={{
                        fontSize: '1.25rem',
                        fontWeight: 'light',
                        color: theme.palette.primary.textWhite,
                    }}
                >
                    {' '}
                    <CurrencyFormat
                        prefix={'$'}
                        value={customerPricing?.monthly || 0}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </Typography>
            </Grid>

            <Grid container item xs={12}>
                <Typography
                    variant="h5"
                    sx={{
                        fontSize: '1.25rem',
                        fontWeight: 'title',
                        color: theme.palette.primary.textWhite,
                    }}
                    paddingRight={1}
                >
                    Salt application:
                </Typography>
                <Typography
                    variant="h5"
                    sx={{
                        fontSize: '1.25rem',
                        fontWeight: 'light',
                        color: theme.palette.primary.textWhite,
                    }}
                >
                    {' '}
                    <CurrencyFormat
                        prefix={'$'}
                        value={customerPricing?.salting_price || 0}
                        displayType={'text'}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale={true}
                    />
                </Typography>
            </Grid>
            {addPricingPopoverVisible && (
                //A popover that allows the user to add pricing information

                <AddPricingPopover
                    user_id={customer?.user_id}
                    setPopoverVisible={setAddPricingPopoverVisible}
                    currentPricing={customerPricing}
                ></AddPricingPopover>
            )}
        </Grid>
    )
}
