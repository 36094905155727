import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 12,
        justifyContent: 'flex-start',
        width: '50%',
    },
    billTo: {
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique',
    },
})

const BillTo = ({ invoice }) => (
    <View style={styles.headerContainer}>
        <Text style={styles.billTo}>Bill To:</Text>
        <Text>{invoice?.first_name + ' ' + invoice?.last_name}</Text>
        <Text>{invoice?.address}</Text>
        <Text>{invoice?.phone}</Text>
        <Text>{invoice?.email}</Text>
    </View>
)

export default BillTo
