import React, { useState } from 'react'
import axios from 'axios'
import { Close } from '@mui/icons-material'
import { Box } from '@mui/system'
import { TextField, Typography, Button, Grid } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

export default function AddCustomerInteractionPopover(props) {
    const { getAccessTokenSilently } = useAuth0()
    const { setPopoverVisible, customerID } = props
    const [interaction_date, setInteractionDate] = useState(dayjs(new Date()))
    const [interaction_type, setInteractionType] = useState('')
    const [interaction_notes, setInteractionNotes] = useState('')

    const handleSubmit = () => {
        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()

            axios
                .post(
                    `${process.env.REACT_APP_BACKEND_DB}/addInteraction`,
                    {
                        user_id: customerID,
                        interaction_date: interaction_date,
                        interaction_type: interaction_type,
                        interaction_notes: interaction_notes,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then(() => {
                    setPopoverVisible(false)
                })
        }
        getJWT()
    }

    return (
        <Box
            position={'fixed'}
            top={'0'}
            left={'0'}
            width={'100%'}
            height={'100%'}
            backgroundColor={'rgba(0, 0, 0, 0.5)'}
            sx={{
                backdropFilter: 'blur(5px)',
            }}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            zIndex={'2'}
        >
            <Box
                width={'100%'}
                height={'100%'}
                position={'absolute'}
                top={'0'}
                left={'0'}
                onClick={() => setPopoverVisible(false)}
            ></Box>
            <Box
                zIndex={'1'}
                position={'relative'}
                width={'auto'}
                height={'auto'}
                backgroundColor={'white'}
                borderRadius={'10px'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                padding={'5vh'}
            >
                <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                    alignItems={'flex-start'}
                    width={'100%'}
                    marginBottom={'10px'}
                >
                    <Close
                        position={'static'}
                        color={'black'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={() => setPopoverVisible(false)}
                    />
                </Box>
                {/*TODO: Form Validation */}
                <Box textAlign={'center'}>
                    <Box mb={'2.5vh'}>
                        <Typography variant="h3">Add Interaction</Typography>
                    </Box>
                </Box>
                <Grid container spacing={2}>
                    <Grid
                        item
                        xs={12}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <DatePicker
                            label="Interaction Date"
                            value={interaction_date}
                            onChange={(newValue) =>
                                setInteractionDate(newValue)
                            }
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <TextField
                            label="Interaction Type"
                            value={interaction_type}
                            onChange={(e) => setInteractionType(e.target.value)}
                            fullWidth
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <TextField
                            label="Interaction Notes"
                            value={interaction_notes}
                            onChange={(e) =>
                                setInteractionNotes(e.target.value)
                            }
                            fullWidth
                            multiline
                            rows={4}
                        />
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleSubmit}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    )
}
