import React from 'react'
import { View, StyleSheet } from '@react-pdf/renderer'
import EstimateTableHeader from './EstimateTableHeader'
import EstimateTableRow from './EstimateTableRow'
import EstimateTableFooter from './EstimateTableFooter'

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 24,
        borderWidth: 1,
        borderColor: '#3778C2',
    },
})

const EstimateItemsTable = ({ estimate }) => (
    <View style={styles.tableContainer}>
        <EstimateTableHeader />
        <EstimateTableRow charges={estimate?.charges} />
        <EstimateTableFooter estimate={estimate} />
    </View>
)

export default EstimateItemsTable
