import React from 'react'
import { Close } from '@mui/icons-material'
import { Box } from '@mui/system'

export default function CustomPopover(props) {
    const { setPopoverVisible } = props
    return (
        <Box
            position={'fixed'}
            top={'0'}
            left={'0'}
            width={'100%'}
            height={'100%'}
            backgroundColor={'rgba(0, 0, 0, 0.5)'}
            sx={{
                backdropFilter: 'blur(5px)',
            }}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            zIndex={'2'}
        >
            <Box
                width={'100%'}
                height={'100%'}
                position={'absolute'}
                top={'0'}
                left={'0'}
                onClick={() => setPopoverVisible(false)}
            ></Box>
            <Box
                zIndex={'1'}
                position={'relative'}
                width={'auto'}
                height={'auto'}
                backgroundColor={'white'}
                borderRadius={'10px'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                padding={'2vh'}
                maxHeight={'90vh'}
                maxWidth={'95vw'}
                //scroll
            >
                <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                    alignItems={'flex-start'}
                    width={'100%'}
                    marginBottom={'10px'}
                >
                    <Close
                        position={'static'}
                        color={'black'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={() => setPopoverVisible(false)}
                    />
                </Box>

                <Box
                    textAlign={'center'}
                    width={'100%'}
                    overflow={'auto'}
                    maxHeight={'80vh'}
                    //scroll
                >
                    {props.children}
                </Box>
            </Box>
        </Box>
    )
}
