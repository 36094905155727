//A component that will be on the admin page that will display a list of all customers
//That have a customer sub and an email but no other information.
//The component should use all MUI icons and fields and no className styling.

import React, { useState, useEffect } from 'react'

import axios from 'axios'
import { Grid, Typography } from '@mui/material'
import { Box } from '@mui/system'
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import { Button } from 'react-bootstrap'
import { PlusOne } from '@mui/icons-material'
import AdminFinishOnboardingPopover from '../../../utils/modals/AdminFinishOnboardingPopover/AdminFinishOnboardingPopover'
import { useAuth0 } from '@auth0/auth0-react'

export default function UnregisteredUsers() {
    const { getAccessTokenSilently } = useAuth0()
    const [listOfUnregisteredUsers, setListOfUnregisteredUsers] = useState([])
    const [
        adminFinishOnboardingPopoverDisplayed,
        setAdminFinishOnboardingPopoverDisplayed,
    ] = useState(false)
    const [editingUser, setEditingUser] = useState({})

    useEffect(() => {
        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()
            axios
                .get(
                    `${process.env.REACT_APP_BACKEND_DB}/getCustomersUnfinishedRegistration`,

                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then((response) => {
                    setListOfUnregisteredUsers(response.data)
                })
        }
        getJWT()
    }, [getAccessTokenSilently])

    const handleClick = (user) => {
        setAdminFinishOnboardingPopoverDisplayed(
            !adminFinishOnboardingPopoverDisplayed
        )
        setEditingUser(user)
    }

    //Display the list of unregistered users in a MUI table using theme
    return (
        <Box
            w={'vh'}
            h={'vw'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-between'}
            alignItems={'center'}
            p={'5vh'}
        >
            <Typography variant="h3">Unregistered Users</Typography>
            <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <TableContainer>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Typography variant="h6">ID</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h6">Email</Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h6">
                                        Auth0 customer_sub
                                    </Typography>
                                </TableCell>
                                <TableCell>
                                    <Typography variant="h6">
                                        Finish Onboarding
                                    </Typography>
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listOfUnregisteredUsers.map((user) => {
                                //return a table using MUI table elements
                                return (
                                    <TableRow key={user?.user_id}>
                                        <TableCell>
                                            <Typography variant="h6">
                                                {user?.user_id}
                                            </Typography>
                                        </TableCell>

                                        <TableCell>
                                            <Typography variant="h6">
                                                {user?.email}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography variant="h6">
                                                {user?.customer_sub}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Button
                                                onClick={() => {
                                                    handleClick(user)
                                                }}
                                            >
                                                <PlusOne></PlusOne>
                                            </Button>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                {adminFinishOnboardingPopoverDisplayed && (
                    <AdminFinishOnboardingPopover
                        adminFinishOnboardingPopoverDisplayed={
                            adminFinishOnboardingPopoverDisplayed
                        }
                        setAdminFinishOnboardingPopoverDisplayed={
                            setAdminFinishOnboardingPopoverDisplayed
                        }
                        user={editingUser}
                    />
                )}
            </Grid>
        </Box>
    )
}
