import React from 'react'
import { Alert } from '@mui/material'
import { InfoRounded } from '@mui/icons-material'

export default function Info(props) {
    const { title } = props
    return (
        <Alert icon={<InfoRounded fontSize="inherit" />} severity="info">
            {title}
        </Alert>
    )
}
