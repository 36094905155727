import React, { Fragment } from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import moment from 'moment'

const styles = StyleSheet.create({
    invoiceNoTogetherContainer: {
        flexDirection: 'row',
        marginTop: 12,
        justifyContent: 'flex-end',
    },
    invoiceNoContainer: {
        flexDirection: 'column',
        marginTop: 12,
        justifyContent: 'flex-end',
    },
    container: {
        flexDirection: 'row',
        marginTop: 12,
        justifyContent: 'space-between',
    },
    invoiceNoContainerStarter: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    invoiceDate: {
        fontSize: 12,
        fontStyle: 'bold',
    },
    label: {
        width: 60,
    },
    companyLabel: {
        width: 150,
    },
    companyLabelEmail: {
        width: 'auto',
    },
    payTo: {
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique',
    },
})

const InvoiceNo = ({ invoice }) => (
    <Fragment>
        <View style={styles.container}>
            <View style={styles.invoiceNoContainerStarter}>
                <Text style={styles.payTo}>Pay To:</Text>
                <Text style={styles.companyLabel}>
                    All Round Contracting LLC
                </Text>
                <Text style={styles.companyLabel}>651-239-4444</Text>
                <Text style={styles.companyLabelEmail}>
                    connor@allroundcontractingllc.com
                </Text>
                <Text style={styles.companyLabel}>PO Box 2621</Text>
                <Text style={styles.companyLabel}>
                    Inver Grove Heights, MN 55076
                </Text>
            </View>
            <View style={styles.invoicedNoContainer}>
                <View style={styles.invoiceNoTogetherContainer}>
                    <Text style={styles.label}>Invoice No:</Text>
                    <Text style={styles.invoiceDate}>{invoice.invoice_id}</Text>
                </View>

                <View style={styles.invoiceDateContainer}>
                    <Text style={styles.label}>Invoiced: </Text>
                    <Text>
                        {moment
                            .utc(invoice.date_generated)

                            .format('MM/DD/YYYY')}
                    </Text>
                </View>

                <View style={styles.invoiceDateContainer}>
                    <Text style={styles.label}>Due on: </Text>
                    <Text>
                        {moment
                            .utc(invoice.due_date)

                            .format('MM/DD/YYYY')}
                    </Text>
                </View>
            </View>
        </View>
    </Fragment>
)

export default InvoiceNo
