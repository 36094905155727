//A popover that mimics the addUserPopover.js file. The only difference is that this popover will pop up when the user clicks on the "Remove Customer" button.
// The popover should have a message, along with a "Delete" button and a "Cancel" button.
//
//Import the confirmDeletePopover.css file into this file.
//
//When a user clicks outside the popover, the popover will close.
//

import React, { useState } from 'react'
import { Close } from '@mui/icons-material'
import { Box, Button, CircularProgress, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import axios from 'axios'
import CreatePaymentPopover from '../CreatePaymentPopover/CreatePaymentPopover'
import { useAuth0 } from '@auth0/auth0-react'
import Success from '../../Components/FormStatusIcons/Success'

export default function MarkAsPaidLogInvoicePromptPopover(props) {
    const { getAccessTokenSilently } = useAuth0()
    const {
        setPopoverVisible,
        invoiceToEdit,
        customerID,
        getContractorDashboard,
    } = props

    const [needsPayment, setNeedsPayment] = useState(false)
    const [formStatus, setFormStatus] = useState('')

    const theme = useTheme()

    const handleMarkAsPaid = () => {
        const getJWT = async () => {
            setFormStatus('Marking Invoice as Paid')
            const accessToken = await getAccessTokenSilently()

            axios
                .post(
                    `${process.env.REACT_APP_BACKEND_DB}/updateInvoiceToPaid`,
                    {
                        invoice_id: invoiceToEdit.invoice_id,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then(() => {
                    setFormStatus('Invoice Marked as Paid')
                    setTimeout(() => {
                        setFormStatus('')
                        getContractorDashboard()
                        setPopoverVisible(false)
                    }, 1000)
                })
        }
        getJWT()
    }

    const handleLogPayment = () => {
        //TODO open payment modal
        setNeedsPayment(true)
    }

    return (
        <Box
            position={'fixed'}
            top={'0'}
            left={'0'}
            width={'100%'}
            height={'100%'}
            backgroundColor={'rgba(0, 0, 0, 0.5)'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            zIndex={'2'}
        >
            <Box
                width={'100%'}
                height={'100%'}
                position={'absolute'}
                top={'0'}
                left={'0'}
                onClick={() => setPopoverVisible(false)}
            ></Box>
            <Box>
                {!needsPayment ? (
                    <Box
                        zIndex={'3'}
                        width={'auto'}
                        borderRadius={2}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        bgcolor={theme.palette.primary.textWhite}
                        p={'5vh'}
                    >
                        <Box
                            display={'flex'}
                            justifyContent={'flex-end'}
                            alignItems={'flex-start'}
                            width={'100%'}
                            marginBottom={'10px'}
                        >
                            <Close
                                width={'100%'}
                                height={'100%'}
                                display={'flex'}
                                onClick={() => setPopoverVisible(false)}
                            />
                        </Box>

                        <Box>Do you want to record a payment?</Box>
                        <Box
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            width={'100%'}
                        >
                            <Box p={'1vh'}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleLogPayment()}
                                    disabled={
                                        formStatus === 'Marking Invoice as Paid'
                                    }
                                >
                                    Yes
                                </Button>
                            </Box>
                            <Box p={'1vh'}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => handleMarkAsPaid()}
                                    disabled={
                                        formStatus === 'Marking Invoice as Paid'
                                    }
                                >
                                    No, just mark as paid
                                </Button>
                            </Box>
                        </Box>
                        {
                            //if the formStatus is Marking Invoice as Paid then display the message "Marking Invoice as Paid"
                            //if the formStatus is Invoice Marked as Paid then display the message "Invoice Marked as Paid"

                            formStatus === 'Marking Invoice as Paid' ? (
                                <Box
                                    display={'flex'}
                                    alignItems={'center'}
                                    padding={1}
                                >
                                    <CircularProgress
                                        size={24}
                                    ></CircularProgress>{' '}
                                    <Typography variant="h6">
                                        Marking Invoice as Paid
                                    </Typography>
                                </Box>
                            ) : formStatus === 'Invoice Marked as Paid' ? (
                                <Box padding={1}>
                                    <Success title={'Invoice Marked as Paid'} />
                                </Box>
                            ) : null
                        }
                    </Box>
                ) : (
                    <Box
                        zIndex={'3'}
                        width={'auto'}
                        borderRadius={2}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        bgcolor={theme.palette.primary.textWhite}
                        p={'5vh'}
                    >
                        <CreatePaymentPopover
                            setPopoverVisible={setPopoverVisible}
                            customerID={customerID}
                            invoice={invoiceToEdit}
                            andMarkPaid={handleMarkAsPaid}
                            formStatus={formStatus}
                            setFormStatus={setFormStatus}
                            getContractorDashboard={getContractorDashboard}
                        />
                    </Box>
                )}
            </Box>
        </Box>
    )
}
