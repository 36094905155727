import React, { Fragment } from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import moment from 'moment'

const borderColor = '#3778C2'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#3778C2',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        fontStyle: 'bold',
    },
    description: {
        width: '50%',
        textAlign: 'left',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        paddingLeft: 8,
    },

    date: {
        width: '30%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        textAlign: 'right',
        paddingRight: 8,
    },
    amount: {
        width: '20%',
        textAlign: 'right',
        paddingRight: 8,
    },
})

const InvoiceTableRow = ({ charges }) => {
    const rows = charges.map((charge) => (
        <View style={styles.row} key={charge.charge_id.toString()}>
            <Text style={styles.description}>{charge.service_performed}</Text>
            <Text style={styles.date}>
                {moment.utc(charge.date_serviced).format('MM/DD/YYYY')}
            </Text>

            <Text style={styles.amount}>
                {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                }).format(charge.amount)}
            </Text>
        </View>
    ))

    return <Fragment>{rows}</Fragment>
}

export default InvoiceTableRow
