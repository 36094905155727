// Code to display the Save the Bees page
import React, { useState, useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import { useMediaQuery } from '@mui/material'
import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Grid,
    Typography,
    CardMedia,
} from '@mui/material'
import { Helmet } from 'react-helmet'
import { useLocation } from 'react-router-dom'
import ReactGA from 'react-ga4'
import snowRemoval from '../../utils/images/generic_snow_removal/IMG_5870.jpeg'

const config = {
    title: 'Why do we need Bee-Friendly Lawns in Inver Grove Heights?',
    sections: [
        {
            title: 'Support the bees, support your lawn',
            content:
                "Bee-friendly lawns are an eco-conscious choice that supports local pollinators like bees in Inver Grove Heights. By planting diverse flowering plants, you provide essential food and habitats for bees while enjoying a beautiful, low-maintenance lawn that thrives in Minnesota's climate.",
            image: 'https://beelab.umn.edu/sites/beelab.umn.edu/files/styles/folwell_full/public/2021-05/bee%20lawn%20header%20rachel%20urick.jpg?itok=dCOdY2CM', // Boilerplate image URL
        },

        {
            title: 'Native Plants for Pollinators in Inver Grove Heights Landscaping',
            content:
                'Native plants like wildflowers, clover, and bee balm are ideal and bee-friendly in Inver Grove Heights landscaping. These plants are adapted to the local soil and weather conditions, making them resilient and sustainable. They also provide nectar and pollen for native bee species, contributing to the health of the local ecosystem.',
            image: 'https://www.shutterstock.com/image-photo/drone-fly-hoverfly-bee-mimic-600nw-1692984964.jpg', // Image of native plants
        },

        {
            title: 'Get Started with Your Bee-Friendly Lawn Today',
            content:
                'At All Round Contracting LLC, we specialize in landscaping that benefits both your property and the environment. From planting bee lawns to snow removal, we offer services to make your outdoor space beautiful and functional from the ground up, year `round.',
            image: snowRemoval,
        },
    ],
    buttonText: 'Contact Us for More Information',
    buttonLink: '/contactUs', // Add your contact link here
}

const SaveTheBees = () => {
    const [contentConfig] = useState(config)
    const theme = useTheme()
    const location = useLocation()
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: location.pathname,
            title: 'Save the Bees',
        })
    }, [location])

    return (
        <Container maxWidth="lg">
            <Helmet>
                <meta name="description" content="Save the Bees" />
                <meta name="title" content="Save the Bees"></meta>
                <meta
                    property="og:image"
                    content="https://allroundcontractingllc.com/static/media/ARC.fce879f1b4533f68cf02.png"
                />
                <meta property="og:title" content="Save the Bees" />
                <meta property="og:description" content="Save the Bees" />
                <meta
                    property="og:url"
                    content="https://allroundcontractingllc.com"
                />
                <meta property="og:type" content="website" />
                <title id="Save the Bees">Save the Bees</title>

                <link
                    rel="canonical"
                    href="https://allroundcontractingllc.com"
                />
            </Helmet>

            <Box my={4} textAlign="center">
                <Typography
                    variant={isMobile ? 'h4' : 'h3'}
                    component="h1"
                    gutterBottom
                    color={theme.palette.primary.textWhite}
                >
                    {contentConfig.title}
                </Typography>
            </Box>

            <Grid container spacing={4}>
                {contentConfig.sections.map((section, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <Card>
                            <CardMedia
                                component="img"
                                height={isMobile ? '200' : '300'}
                                image={section.image}
                                alt={section.title}
                            />
                            <CardContent>
                                <Typography
                                    variant="h5"
                                    component="h2"
                                    gutterBottom
                                >
                                    {section.title}
                                </Typography>
                                <Typography variant="body1">
                                    {section.content}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <Box textAlign="center" my={4}>
                <Button
                    variant="contained"
                    color="primary"
                    href={contentConfig.buttonLink}
                >
                    {contentConfig.buttonText}
                </Button>
            </Box>
        </Container>
    )
}
export default SaveTheBees
