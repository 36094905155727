//This will be the sign up page for the application,
//When a user signs up, it will add the user to the users table and the customer table.
//The user will be redirected to the customer dashboard.
//The user must enter the last 4 digits of their phone number
//as well as their last name to sign up.

//Once the user has signed up, they will be required to link

import React, { useEffect } from 'react'
import './SignUp.css'
import { useAuth0 } from '@auth0/auth0-react'
import { useParams } from 'react-router'
import Loading from '../Loading/Loading'
import ReactGA from 'react-ga4'

export default function SignUp() {
    const { loginWithRedirect } = useAuth0()
    const { queryString } = useParams()

    useEffect(() => {
        ReactGA.send({
            hitType: 'pageview',
            page: `/EmailSignUpRegister/${queryString}`,
            title: 'Email Sign Up Register',
        })
    }, [queryString])

    return (
        loginWithRedirect({
            //redirectURI
            authorizationParams: {
                redirect_uri: window.location.origin + '/dashboard',
                screen_hint: 'signup',
                signUpQueryString: queryString,
            },
        }) && <Loading />
    )
}
