import React from 'react'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import { CircularProgress } from '@mui/material'
import Grid from '@mui/material/Grid'
import moment from 'moment'
import { useTheme } from '@mui/material/styles'
import { Button } from '@mui/material'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

const CustomerMessages = ({ messages, isLoading, handleMarkAsRead }) => {
    const theme = useTheme()

    const clickViewMessage = (message) => {
        console.log('Viewing message:', message)
    }

    const [readFilter, setReadFilter] = React.useState('all')

    const handleFilterChange = (event, newFilter) => {
        if (newFilter !== null) {
            setReadFilter(newFilter)
        }
    }

    const filterMessages = (messages) => {
        if (!messages) return []
        if (readFilter === 'all') return messages
        return messages.filter((message) => {
            return (
                message.read_status === (readFilter === 'unread' ? false : true)
            )
        })
    }

    const filteredMessages = filterMessages(messages)

    if (!messages || isLoading) {
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    padding={2}
                >
                    <Typography
                        variant="h4"
                        color={theme.palette.primary.textWhite}
                    >
                        Messages
                    </Typography>
                </Box>
                <CircularProgress />
            </Box>
        )
    }

    return (
        <Box>
            <Box
                display={'flex'}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                padding={2}
            >
                <Typography
                    variant="h4"
                    color={theme.palette.primary.textWhite}
                >
                    Messages
                </Typography>
                <Typography
                    variant="h6"
                    color={theme.palette.primary.textWhite}
                    style={{ marginRight: '10px' }}
                >
                    Filter by:
                </Typography>

                <ToggleButtonGroup
                    value={readFilter}
                    exclusive
                    onChange={handleFilterChange}
                    style={{ backgroundColor: 'black' }}
                >
                    <ToggleButton
                        value="all"
                        style={{
                            backgroundColor:
                                readFilter === 'all' ? 'gray' : 'black',
                            color: readFilter === 'all' ? 'white' : 'gray',
                        }}
                    >
                        All
                    </ToggleButton>
                    <ToggleButton
                        value="read"
                        style={{
                            backgroundColor:
                                readFilter === 'read' ? 'gray' : 'black',
                            color: readFilter === 'read' ? 'white' : 'gray',
                        }}
                    >
                        Read
                    </ToggleButton>
                    <ToggleButton
                        value="unread"
                        style={{
                            backgroundColor:
                                readFilter === 'unread' ? 'gray' : 'black',
                            color: readFilter === 'unread' ? 'white' : 'gray',
                        }}
                    >
                        Unread
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            {filteredMessages?.map((message, index) => (
                <Card
                    key={index}
                    style={{
                        margin: '10px',
                        border: '1px solid black',
                        //hover effect
                    }}
                >
                    <CardContent
                        sx={{
                            /* 
                            Add back in when hover effect is needed to click
                            '&:hover': {
                                backgroundColor: 'lightgray',
                                cursor: 'pointer',
                            },*/
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            position: 'relative',
                        }}
                    >
                        <Grid container>
                            <Grid
                                item
                                xs={12}
                                sm={4}
                                onClick={() => clickViewMessage(message)}
                            >
                                <Typography
                                    variant="h6"
                                    style={{
                                        fontWeight: 'bold',
                                    }}
                                >
                                    Date:{' '}
                                    {moment
                                        .utc(message.message_date)
                                        .format('MM/DD/YYYY')}
                                </Typography>
                                <Typography>
                                    {message.sender_first_name &&
                                    message.sender_last_name
                                        ? `Sent By: ${message.sender_first_name} ${message.sender_last_name}`
                                        : message.sender_first_name
                                        ? `Sent By: ${message.sender_first_name}`
                                        : 'Sender Name: N/A'}
                                </Typography>
                                <Typography>
                                    Read Status:{' '}
                                    {message.read_status ? 'Read' : 'Unread'}
                                </Typography>

                                {message.read_status ? null : (
                                    <Box
                                        display={'flex'}
                                        flexDirection={'row'}
                                        alignItems={'flex-start'}
                                        justifyContent={'left'}
                                        height={'100%'}
                                    >
                                        <Box paddingTop={2}>
                                            <Button
                                                onClick={() =>
                                                    handleMarkAsRead(message)
                                                }
                                                variant="contained"
                                                size="small"
                                            >
                                                Mark as Read
                                            </Button>
                                        </Box>
                                    </Box>
                                )}
                            </Grid>

                            <Grid
                                item
                                xs={12}
                                sm={8}
                                justifyContent={'right'}
                                alignItems={'flex-end'}
                            >
                                <Typography
                                    variant="h6"
                                    style={{
                                        fontWeight: 'bold',
                                        marginBottom: '10px',
                                    }}
                                >
                                    {message.message}
                                </Typography>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            ))}
        </Box>
    )
}

export default CustomerMessages
