//this will be the popover displayed to create an invoice

import React from 'react'
import {
    Checkbox,
    ToggleButton,
    ToggleButtonGroup,
    Typography,
    CircularProgress,
} from '@mui/material'
import { Box, Button } from '@mui/material'
import { FormControl } from '@mui/material'
import { Close } from '@mui/icons-material'
import Success from '../../Components/FormStatusIcons/Success'
import theme from '../../../theme'
import moment from 'moment'
import { Grid } from '@mui/material'
import { useMemo } from 'react'
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'

export default function CreateInvoicePopover(props) {
    const { createInvoicePopover, setCreateInvoicePopover, customerDetail } =
        props

    const [listOfCharges, setListOfCharges] = React.useState([])
    const [chargeCardFee, setChargeCardFee] = React.useState(false)
    const [sendEmailNotification, setSendEmailNotification] =
        React.useState(false)
    const [formStatus, setFormStatus] = React.useState('')
    const { getAccessTokenSilently } = useAuth0()

    const chargesSet = useMemo(() => new Set(listOfCharges), [listOfCharges])

    const handleAddToListOfCharges = (charge) => {
        //use the has method to check if the charge is in the set
        if (chargesSet.has(charge)) {
            chargesSet.delete(charge)
        } else {
            chargesSet.add(charge)
        }
        //convert the set to an array and set the state
        setListOfCharges(Array.from(chargesSet))
    }

    const generateAnInvoice = async () => {
        const accessToken = await getAccessTokenSilently()

        setFormStatus('Generating Invoice')
        //Get the list of charges
        //Loop through the list of charges and add them to the invoice
        //Add the invoice to the database
        //Update the invoice list
        let totalAmount = 0
        let listOfChargeIDs = []
        var date = new Date()
        date.setDate(date.getDate() + 15)
        listOfCharges.forEach((charge) => {
            totalAmount += charge.amount
            listOfChargeIDs.push(charge.charge_id)
        })

        axios
            .post(
                `${process.env.REACT_APP_BACKEND_DB}/createInvoice`,
                {
                    user_id: customerDetail.user_id,
                    total_amount: totalAmount,
                    charges: listOfChargeIDs,
                    date_generated: new Date(),
                    invoice_status: 'unpaid',
                    //Add a date 30 days from today
                    due_date: date,
                    charge_card_fee: chargeCardFee,
                    send_email_notification: sendEmailNotification,
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                }
            )
            .then(() => {
                setFormStatus('Invoice Generated')
                setTimeout(() => {
                    setCreateInvoicePopover(!createInvoicePopover)
                    setFormStatus('')
                }, 2000)
            })
    }

    return (
        <Box
            position={'fixed'}
            top={'0'}
            left={'0'}
            width={'100%'}
            height={'100%'}
            backgroundColor={'rgba(0, 0, 0, 0.5)'}
            sx={{
                backdropFilter: 'blur(5px)',
            }}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            zIndex={'2'}
        >
            <Box
                width={'100%'}
                height={'100%'}
                position={'absolute'}
                top={'0'}
                left={'0'}
                onClick={() => setCreateInvoicePopover(!createInvoicePopover)}
            ></Box>
            <Box
                zIndex={'1'}
                position={'relative'}
                width={'auto'}
                height={'auto'}
                maxHeight={'95vh'}
                maxWidth={'95vw'}
                backgroundColor={'white'}
                borderRadius={'10px'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                padding={2}
                overflow={'scroll'}
            >
                <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                    alignItems={'flex-start'}
                    width={'100%'}
                    paddingBottom={'10px'}
                >
                    <Close
                        position={'static'}
                        color={'black'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={() =>
                            setCreateInvoicePopover(!createInvoicePopover)
                        }
                    />
                </Box>
                <Box pb={'2vh'}>
                    <Typography variant="h2" textAlign={'center'}>
                        Create Invoice
                    </Typography>
                </Box>

                <Box
                    width={'100%'}
                    height={'100%'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <FormControl
                        sx={{
                            color: theme.palette.primary.textBlack,
                        }}
                    >
                        {customerDetail?.charges
                            // ?.filter((charge) => charge.has_been_invoiced) //This line is commented out to show all charges
                            ?.sort((a, b) => {
                                return (
                                    new Date(b.date_serviced) -
                                    new Date(a.date_serviced)
                                )
                            })
                            .map((charge, index) => (
                                <Box key={index} padding={1}>
                                    <Box
                                        borderRadius={4}
                                        border={1}
                                        padding={1}
                                        backgroundColor={
                                            theme.palette.primary
                                                .supportingWhite
                                        }
                                    >
                                        <Grid container>
                                            <Grid item xs={12} sm={6}>
                                                <Typography
                                                    variant="h6"
                                                    style={{
                                                        fontWeight: 'bold',
                                                        marginBottom: '10px',
                                                    }}
                                                >
                                                    Charge #{charge.charge_id}
                                                </Typography>
                                                <Typography>
                                                    Service Performed:{' '}
                                                    {charge.service_performed}
                                                </Typography>
                                                <Typography>
                                                    Date Serviced:{' '}
                                                    {moment
                                                        .utc(
                                                            charge.date_serviced
                                                        )
                                                        .format('MM/DD/YYYY')}
                                                </Typography>
                                            </Grid>

                                            <Grid
                                                container
                                                item
                                                xs={12}
                                                sm={6}
                                                justifyContent={'right'}
                                                alignItems={'flex-end'}
                                            >
                                                <Grid item xs={12}>
                                                    <Typography
                                                        display={'flex'}
                                                        flexDirection={'row'}
                                                        justifyContent={'right'}
                                                        alignItems={'flex-end'}
                                                        variant="h6"
                                                        style={{
                                                            fontWeight: 'bold',
                                                            marginBottom:
                                                                '10px',
                                                        }}
                                                    >
                                                        Charge Amount:{' '}
                                                        {new Intl.NumberFormat(
                                                            'en-US',
                                                            {
                                                                style: 'currency',
                                                                currency: 'USD',
                                                            }
                                                        ).format(charge.amount)}
                                                    </Typography>
                                                    <Box
                                                        display={'flex'}
                                                        flexDirection={'row'}
                                                        alignItems={'center'}
                                                        justifyContent={
                                                            'flex-end'
                                                        }
                                                    >
                                                        <Typography
                                                            paddingRight={1}
                                                        >
                                                            Charge Status:{' '}
                                                        </Typography>
                                                        <Typography
                                                            display={'flex'}
                                                            flexDirection={
                                                                'row'
                                                            }
                                                            justifyContent={
                                                                'right'
                                                            }
                                                            alignItems={
                                                                'flex-end'
                                                            }
                                                        >
                                                            {charge.has_been_invoiced ? (
                                                                <span
                                                                    style={{
                                                                        color: theme
                                                                            .palette
                                                                            .primary
                                                                            .successGreen,
                                                                        fontWeight:
                                                                            'bold',
                                                                        fontSize:
                                                                            '1.2em',
                                                                    }}
                                                                >
                                                                    [INVOICED]
                                                                </span>
                                                            ) : (
                                                                <span
                                                                    style={{
                                                                        color: theme
                                                                            .palette
                                                                            .primary
                                                                            .cautionYellow,
                                                                        fontWeight:
                                                                            'bold',
                                                                        fontSize:
                                                                            '1.2em',
                                                                    }}
                                                                >
                                                                    [PENDING]
                                                                </span>
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Box
                                                        display={'flex'}
                                                        flexDirection={'row'}
                                                        alignItems={'flex-end'}
                                                        justifyContent={'right'}
                                                        height={'100%'}
                                                    >
                                                        <Box padding>
                                                            <Typography
                                                                variant="body1"
                                                                style={{
                                                                    fontWeight:
                                                                        'bold',
                                                                }}
                                                            >
                                                                Add to Invoice
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            padding
                                                            display={'flex'}
                                                            flexDirection={
                                                                'column'
                                                            }
                                                            alignItems={
                                                                'center'
                                                            }
                                                            justifyContent={
                                                                'center'
                                                            }
                                                            height={'100%'}
                                                        >
                                                            <Checkbox
                                                                checked={chargesSet.has(
                                                                    charge
                                                                )}
                                                                onChange={() =>
                                                                    handleAddToListOfCharges(
                                                                        charge
                                                                    )
                                                                }
                                                            ></Checkbox>
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>
                            ))}

                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            width={'auto'}
                            height={'auto'}
                            padding={2}
                        >
                            <Box>
                                <Typography variant="body1">
                                    Charge Card Fee?
                                </Typography>
                            </Box>
                            <ToggleButtonGroup
                                value={chargeCardFee}
                                exclusive
                                onChange={
                                    //Toggle the charge card fee
                                    (event, newAlignment) => {
                                        setChargeCardFee(newAlignment)
                                    }
                                }
                                aria-label="text alignment"
                                size={'small'}
                            >
                                <ToggleButton value={true}>Yes</ToggleButton>
                                <ToggleButton value={false}>No</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            width={'auto'}
                            height={'auto'}
                            padding={2}
                        >
                            <Box>
                                <Typography variant="body1">
                                    Email Notification?
                                </Typography>
                            </Box>
                            <ToggleButtonGroup
                                value={sendEmailNotification}
                                exclusive
                                onChange={
                                    //Toggle the charge card fee
                                    (event, newAlignment) => {
                                        setSendEmailNotification(newAlignment)
                                    }
                                }
                                aria-label="text alignment"
                                size={'small'}
                            >
                                <ToggleButton value={true}>Yes</ToggleButton>
                                <ToggleButton value={false}>No</ToggleButton>
                            </ToggleButtonGroup>
                        </Box>

                        {formStatus === 'Generating Invoice' ? (
                            <Button
                                variant="contained"
                                onClick={() => generateAnInvoice()}
                                color="primary"
                                disabled={true}
                            >
                                <CircularProgress size={24} /> Generating
                                Invoice
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                onClick={() => generateAnInvoice()}
                                color="primary"
                                disabled={formStatus === 'Generating Invoice'}
                            >
                                Generate Invoice
                            </Button>
                        )}
                        {formStatus === 'Invoice Generated' ? (
                            <Box padding={2}>
                                <Success title={'Invoice Generated'}></Success>
                            </Box>
                        ) : null}
                    </FormControl>
                </Box>
            </Box>
        </Box>
    )
}
