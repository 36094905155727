//A modal that will be displayed on the admin page to add a contractor to the contractors table.

// The modal will have the following fields for a new contractor:
// - First Name
// - Last Name
// - Email
// - Phone
// - Address

//
// Import the addContractorModal.css file into this file.
//
// When a user clicks outside the modal, the modal will close.
//
// The modal will have a "Submit" button that will send the data to the database and close the modal.
//
// The modal will have a "Cancel" button that will close the modal.
//

import React, { useState } from 'react'
import './AddContractorModal.css'
import axios from 'axios'
import { Close } from '@mui/icons-material'
import { useAuth0 } from '@auth0/auth0-react'

export default function AddContractorModal(props) {
    const { addContractorModalVisible, setAddContractorModalVisible } = props
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [address, setAddress] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const { getAccessTokenSilently } = useAuth0()

    const handleSubmit = () => {
        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()

            //TODO update this to use the new backend route
            axios
                .post(
                    `${process.env.REACT_APP_BACKEND_DB}/addContractor`,
                    {
                        first_name: firstName,
                        last_name: lastName,
                        address: address,
                        email: email,
                        phone_number: phone,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then(() => {
                    setAddContractorModalVisible(!addContractorModalVisible)
                })
        }
        getJWT()
    }

    return (
        <div className="addContractorModal">
            <div
                className="addContractorModalBackground"
                onClick={() =>
                    setAddContractorModalVisible(!addContractorModalVisible)
                }
            ></div>

            <div className="addContractorModalContent">
                <Close
                    className="addContractorModalCancel"
                    onClick={() =>
                        setAddContractorModalVisible(!addContractorModalVisible)
                    }
                />

                <div className="addContractorModalTitle">
                    <h1>Add Contractor</h1>
                </div>

                <div className="addContractorModalBody">
                    <div className="addContractorModalInput">
                        <label>First Name:</label>
                        <input
                            type="text"
                            onChange={(e) => setFirstName(e.target.value)}
                        />
                    </div>
                    <div className="addContractorModalInput">
                        <label>Last Name:</label>
                        <input
                            type="text"
                            onChange={(e) => setLastName(e.target.value)}
                        />
                    </div>
                    <div className="addContractorModalInput">
                        <label>Address:</label>
                        <input
                            type="text"
                            onChange={(e) => setAddress(e.target.value)}
                        />
                    </div>
                    <div className="addContractorModalInput">
                        <label>Email:</label>
                        <input
                            type="text"
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="addContractorModalInput">
                        <label>Phone:</label>
                        <input
                            type="text"
                            onChange={(e) => setPhone(e.target.value)}
                        />
                    </div>
                    <div className="addContractorModalSubmit">
                        <button onClick={handleSubmit}>Submit</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
