export const USER_TYPES = {
    CUSTOMER: 'customer',
    CONTRACTOR: 'contractor',
    ADMIN: 'admin',
    UNREGISTERED: null || undefined,
}

export const COMPONENTS = {
    customerDetail: 'customerDetail',
    routes: 'routes',
    routeDetail: 'routeDetail',
    routeInProgress: 'routeInProgress',
    pdf: 'pdf',
    createANewRoute: 'createANewRoute',
    dashboard: 'dashboard',
    pdfView: 'pdfView',
    estimate: 'estimate',
}

export const THEME_COLORS = {
    background: '#232323',
    main: '#B1C1D8',
    backup: '#27374E',
    textWhite: '#E1E1E1',
    supportingWhite: '#EEEEEE',
    textBlack: '#000000',
    supportingBlack: '#717171',
    errorRed: '#bd353b',
    successGreen: '#4BB543',
    cautionYellow: '#F7CF69',
    disabledGray: '#c4c4c4',
    ARCGreen: '#48b355',
    ARCGreenLight: '#6fcf7b',
    ARCBlue: '#005fa1',
    ARCBlueLight: '#0076c0',
}
