/*This will be the page where an unauthenticated user can select from a list 
of services and get an estimate for the service.
It will collect similar information as the contact us page, first name, last name, email, phone, address.
The services offered should be configurable in a variable at the top of the file and should be an array of objects with a name, photo and a price.
The user should be able to select a service and see the price of the service.
The user should be able to select multiple services and see the total price of the services selected.
Once the user has selected the services they want, they should be able to submit the form and pay for the services.
The user should be able to pay for the services with a credit card and the payment should be processed with stripe.
Once the payment is processed, the user should be redirected to a page that shows the services they selected and the total price of the services.
Along with a message that says "Thank you for your purchase. You will receive an email with the details of your purchase shortly."
and a link to create an account.

The page should be styled with MUI and should use all MUI components and no className styling.*/

import React, { useEffect, useState } from 'react'

import {
    Box,
    Button,
    CircularProgress,
    Grid,
    TableFooter,
    Typography,
    TextField,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import axios from 'axios'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import CheckoutForm from '../PayInvoice/CheckoutForm'
import lawn from '../../images/backyard.jpeg'
import sprinkler from '../../images/sprinkler.jpeg'
import snow from '../../images/generic_snow_removal/IMG_3498.jpeg'
import sprinklerBlowout from '../../images/sprinkler_blowout.jpg'
import { Card, CardContent, CardMedia } from '@mui/material'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import CurrencyFormat from 'react-currency-format'
import CustomPopover from '../../modals/CustomPopover/CustomPopover'
import Failed from '../FormStatusIcons/Failed'
import { useAuth0 } from '@auth0/auth0-react'
import { useParams } from 'react-router-dom'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import PlacesAutocomplete from 'react-places-autocomplete'
import ReactGA from 'react-ga4'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY)

export default function OrderService() {
    const theme = useTheme()
    const { loginWithRedirect, isAuthenticated } = useAuth0()

    const [selectedServices, setSelectedServices] = useState([])
    const [totalPrice, setTotalPrice] = useState(0)
    const [clientSecret, setClientSecret] = useState('')
    const [checkoutPopoverVisible, setCheckoutPopoverVisible] = useState(false)
    const [businessAddress] = useState(
        '5912 Concord Blvd, Inver Grove Heights MN 55076'
    )
    const [checkoutPopoverStatus, setCheckoutPopoverStatus] = useState('idle')
    const [userInfo, setUserInfo] = useState({
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        address: '',
        property_notes: '',
    })
    const { success } = useParams()
    const [formStatus, setFormStatus] = useState('idle')
    const { user, getAccessTokenSilently } = useAuth0()

    const returnURL = user
        ? process.env.REACT_APP_ENV_ADDRESS + '/dashboard'
        : process.env.REACT_APP_ENV_ADDRESS + '/orderService/success'

    const [services] = useState([
        {
            name: 'Snowplowing Deposit',
            description:
                'Place a deposit for snowplowing services for the upcoming winter. We will contact you to set up a plan.',
            disclaimer: 'Deposit only',
            photo: snow,
            price: 100,
        },
        {
            name: 'Sprinkler Blowout',
            description:
                'We will blow out your sprinkler system to prevent freezing.',
            disclaimer: 'Up to 12 zones',
            photo: sprinklerBlowout,
            price: 100,
        },
        {
            name: 'Sprinkler Startup',
            description:
                'We will start up your sprinkler system and ensure it is running properly.',
            disclaimer: 'Up to 12 zones',
            photo: sprinkler,
            price: 75,
        },
        {
            name: 'Aeration',
            description:
                'We will aerate your lawn to help it breathe and grow.',
            disclaimer: 'Price is based on a standard 1/4 acre lot.',
            photo: lawn,
            price: 125,
        },
    ])

    const handleSelect = async (value) => {
        setUserInfo({ ...userInfo, address: value })
    }

    const handleCheckout = () => {
        if (user) {
            setCheckoutPopoverVisible(true)
            createCheckoutSession()
        } else {
            setCheckoutPopoverStatus('User Info')
            setCheckoutPopoverVisible(true)
        }
    }

    const createCheckoutSession = async () => {
        setFormStatus('Creating Checkout Session')
        try {
            axios
                .post(`${process.env.REACT_APP_BACKEND_DB}/orderService`, {
                    selectedServices: selectedServices,
                    totalPrice: totalPrice,
                    userInfo: userInfo,
                    customer_sub: user?.sub,
                })
                .then((response) => {
                    setClientSecret(response?.data?.clientSecret)
                    setCheckoutPopoverStatus('Payment')
                    setFormStatus('Checkout Session Created')
                })
        } catch (error) {
            console.error(error)
            setFormStatus('Error')
        }
    }

    /*
    A function named handleUserContinue. This function will be called when the user clicks the continue button on the user info screen.
    This function will set the checkoutPopoverStatus to 'Payment' only if the user info fields are not empty strings and not undefined.

    */
    const handleUserContinue = async () => {
        if (
            userInfo.first_name !== '' &&
            userInfo.last_name !== '' &&
            userInfo.email !== '' &&
            userInfo.phone !== '' &&
            userInfo.address !== ''
        ) {
            //use the google maps api to check the distance from the user's address to the business address
            //if the distance is too far, display a message that says "We are sorry
            //but we are unable to service your area at this time. Please contact us for a custom quote."

            try {
                axios
                    .post(
                        `https://routes.googleapis.com/directions/v2:computeRoutes?key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
                        {
                            origin: {
                                address: userInfo.address,
                            },
                            destination: {
                                address: businessAddress,
                            },
                        },
                        {
                            headers: {
                                'X-Goog-FieldMask': '*',
                            },
                        }
                    )
                    .then((response) => {
                        if (
                            response.data.routes[0].legs[0].distanceMeters >
                            40000
                        ) {
                            setFormStatus(
                                'We are sorry but it looks like you might be outside our service area. Please contact us for a custom quote.'
                            )
                        } else if (
                            response.data.routes[0].legs[0].distanceMeters >
                                15000 &&
                            selectedServices.some(
                                (service) =>
                                    service.name === 'Snowplowing Deposit'
                            )
                        ) {
                            setFormStatus(
                                'We are sorry but it looks like you might be outside our snowplowing route. Please contact us.'
                            )
                        } else {
                            createCheckoutSession()
                        }
                    })
            } catch (error) {
                setFormStatus(
                    'There was an error checking the address, please try again later.'
                )
            }
        } else {
            setFormStatus('Please fill out all required fields.')
        }
    }

    useEffect(() => {
        success === 'success'
            ? ReactGA.send({
                  hitType: 'pageview',
                  page: '/OrderService/success',
                  title: 'Order Service Success',
              })
            : ReactGA.send({
                  hitType: 'pageview',
                  page: '/OrderService',
                  title: 'Order Service',
              })
    }, [success])

    //if the user is logged in, get the information by the users sub

    useEffect(() => {
        const getUserInfo = async () => {
            try {
                const accessToken = await getAccessTokenSilently()
                const response = await axios.get(
                    `${process.env.REACT_APP_BACKEND_DB}/getSingleUserBySub/${user?.sub}`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                setUserInfo({
                    first_name: response.data.first_name,
                    last_name: response.data.last_name,
                    email: response.data.email,
                    phone: response.data.phone,
                    address: response.data.address,
                    property_notes: response.data.property_notes,
                })
            } catch (error) {
                console.error(error)
            }
        }

        user && getUserInfo()
    }, [user, getAccessTokenSilently])

    return (
        <Box
            width={'auto'}
            height={'auto'}
            display={'flex'}
            flexDirection={'column'}
        >
            {checkoutPopoverVisible && (
                <CustomPopover setPopoverVisible={setCheckoutPopoverVisible}>
                    <Box
                        padding={1}
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        maxWidth={'100%'}
                    >
                        <Typography variant="h3">Checkout</Typography>
                        {!isAuthenticated && (
                            <Box
                                padding={1}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                                onClick={() => {
                                    loginWithRedirect({
                                        //redirectURI
                                        authorizationParams: {
                                            redirect_uri:
                                                window.location.origin +
                                                '/orderService',
                                        },
                                    })
                                }}
                            >
                                <Button
                                    //not contained
                                    variant="text"
                                >
                                    Already a customer? Sign in
                                </Button>
                            </Box>
                        )}

                        {
                            //User Info Screen
                            checkoutPopoverStatus === 'User Info' ? (
                                //display a MUI grid organized form with the following fields: first name, last name, email, phone, address, property notes.

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            error={
                                                formStatus ===
                                                    'Please fill out all required fields.' &&
                                                userInfo.first_name === ''
                                            }
                                            placeholder="First Name"
                                            required
                                            helperText="First Name"
                                            value={userInfo.first_name}
                                            onChange={(e) =>
                                                setUserInfo({
                                                    ...userInfo,
                                                    first_name: e.target.value,
                                                })
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            error={
                                                formStatus ===
                                                    'Please fill out all required fields.' &&
                                                userInfo.last_name === ''
                                            }
                                            required
                                            placeholder="Last Name"
                                            helperText="Last Name"
                                            value={userInfo.last_name}
                                            onChange={(e) =>
                                                setUserInfo({
                                                    ...userInfo,
                                                    last_name: e.target.value,
                                                })
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            fullWidth
                                            required
                                            placeholder="Email"
                                            error={
                                                formStatus ===
                                                    'Please fill out all required fields.' &&
                                                userInfo.email === ''
                                            }
                                            helperText="Email"
                                            value={userInfo.email}
                                            onChange={(e) =>
                                                setUserInfo({
                                                    ...userInfo,
                                                    email: e.target.value,
                                                })
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            placeholder="Phone Number"
                                            fullWidth
                                            type="number"
                                            error={
                                                formStatus ===
                                                    'Please fill out all required fields.' &&
                                                userInfo.phone === ''
                                            }
                                            required
                                            helperText="Phone Number"
                                            value={userInfo.phone}
                                            onChange={(e) =>
                                                setUserInfo({
                                                    ...userInfo,
                                                    phone: e.target.value,
                                                })
                                            }
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <PlacesAutocomplete
                                            value={userInfo.address}
                                            onChange={(address) =>
                                                setUserInfo({
                                                    ...userInfo,
                                                    address: address,
                                                })
                                            }
                                            onSelect={handleSelect}
                                        >
                                            {({
                                                getInputProps,
                                                suggestions,
                                                getSuggestionItemProps,
                                                loading,
                                            }) => (
                                                <Box>
                                                    <TextField
                                                        fullWidth
                                                        required
                                                        error={
                                                            formStatus ===
                                                                'Please fill out all required fields.' &&
                                                            userInfo.address ===
                                                                ''
                                                        }
                                                        helperText="Address"
                                                        {...getInputProps({
                                                            placeholder:
                                                                'Address',
                                                        })}
                                                    />
                                                    <Box
                                                        sx={{
                                                            //rounded corners
                                                            borderRadius: '5px',
                                                            //border color

                                                            position:
                                                                'absolute',

                                                            //center the suggestions box

                                                            left: '5%',

                                                            zIndex: '3',
                                                            backgroundColor:
                                                                theme.palette
                                                                    .primary
                                                                    .light,
                                                            width: '90%',

                                                            maxHeight: '20vh',
                                                            overflowY: 'scroll',
                                                        }}
                                                    >
                                                        {loading ? (
                                                            <Box>
                                                                ...loading
                                                            </Box>
                                                        ) : null}

                                                        {suggestions.map(
                                                            (suggestion) => {
                                                                const style =
                                                                    suggestion.active
                                                                        ? {
                                                                              backgroundColor:
                                                                                  theme
                                                                                      .palette
                                                                                      .primary
                                                                                      .textWhite,
                                                                              cursor: 'pointer',
                                                                          }
                                                                        : {
                                                                              backgroundColor:
                                                                                  theme
                                                                                      .palette
                                                                                      .primary
                                                                                      .light,
                                                                              cursor: 'pointer',
                                                                          }
                                                                return (
                                                                    <Box
                                                                        key={
                                                                            suggestion.placeId
                                                                        }
                                                                        {...getSuggestionItemProps(
                                                                            suggestion,
                                                                            {
                                                                                style,
                                                                            }
                                                                        )}
                                                                    >
                                                                        {
                                                                            suggestion.description
                                                                        }
                                                                    </Box>
                                                                )
                                                            }
                                                        )}
                                                    </Box>
                                                </Box>
                                            )}
                                        </PlacesAutocomplete>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            placeholder="Property Notes"
                                            multiline
                                            rows={3}
                                            helperText="Property Notes"
                                            value={userInfo.property_notes}
                                            onChange={(e) =>
                                                setUserInfo({
                                                    ...userInfo,
                                                    property_notes:
                                                        e.target.value,
                                                })
                                            }
                                        />
                                    </Grid>

                                    {formStatus ===
                                        'We are sorry but it looks like you might be outside our service area. Please contact us for a custom quote.' && (
                                        <Grid item xs={12}>
                                            <Failed error={formStatus} />
                                        </Grid>
                                    )}
                                    {formStatus ===
                                        'We are sorry but it looks like you might be outside our snowplowing route. Please contact us.' && (
                                        <Grid item xs={12}>
                                            <Failed error={formStatus} />
                                        </Grid>
                                    )}

                                    {formStatus ===
                                        'Please fill out all required fields.' && (
                                        <Grid item xs={12}>
                                            <Failed error={formStatus} />
                                        </Grid>
                                    )}

                                    {formStatus ===
                                        'There was an error checking the address, please try again later.' && (
                                        <Grid item xs={12}>
                                            <Failed error={formStatus} />
                                        </Grid>
                                    )}

                                    <Grid item xs={12}>
                                        {formStatus ===
                                        'Creating Checkout Session' ? (
                                            <Box
                                                padding={1}
                                                display={'flex'}
                                                justifyContent={'center'}
                                                alignItems={'center'}
                                            >
                                                <CircularProgress />
                                            </Box>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleUserContinue}
                                            >
                                                Continue
                                            </Button>
                                        )}
                                    </Grid>
                                </Grid>
                            ) : checkoutPopoverStatus === 'Payment' ? (
                                formStatus === 'Creating Checkout Session' ? (
                                    <Box
                                        padding={1}
                                        display={'flex'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                    >
                                        <CircularProgress />
                                    </Box>
                                ) : (
                                    clientSecret && (
                                        <Box
                                            display={'flex'}
                                            flexDirection={'column'}
                                            justifyContent={'center'}
                                            alignItems={'center'}
                                        >
                                            {selectedServices.length > 0 && (
                                                <Grid
                                                    item
                                                    xs={12}
                                                    padding={1}
                                                    display={'flex'}
                                                >
                                                    <Box
                                                        padding={1}
                                                        border={1}
                                                        borderRadius={2}
                                                    >
                                                        <Typography
                                                            variant="h6"
                                                            display={'flex'}
                                                            justifyContent={
                                                                'center'
                                                            }
                                                            alignItems={
                                                                'center'
                                                            }
                                                        >
                                                            Selected Services:
                                                        </Typography>
                                                        <Table size="small">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        Service
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        Price
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {selectedServices.map(
                                                                    (
                                                                        service,
                                                                        index
                                                                    ) => {
                                                                        return (
                                                                            <TableRow
                                                                                key={
                                                                                    index
                                                                                }
                                                                            >
                                                                                <TableCell>
                                                                                    {
                                                                                        service.name
                                                                                    }
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <CurrencyFormat
                                                                                        value={
                                                                                            service.price
                                                                                        }
                                                                                        displayType={
                                                                                            'text'
                                                                                        }
                                                                                        thousandSeparator={
                                                                                            true
                                                                                        }
                                                                                        prefix={
                                                                                            '$'
                                                                                        }
                                                                                    />
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    }
                                                                )}
                                                            </TableBody>
                                                            <TableFooter>
                                                                <TableRow>
                                                                    <TableCell>
                                                                        <Typography variant="h6">
                                                                            Total
                                                                            Price:
                                                                        </Typography>
                                                                    </TableCell>
                                                                    <TableCell>
                                                                        <Typography variant="h6">
                                                                            $
                                                                            {
                                                                                totalPrice
                                                                            }
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableFooter>
                                                        </Table>
                                                    </Box>
                                                </Grid>
                                            )}
                                            <Elements
                                                options={{
                                                    clientSecret,
                                                    appearance: {
                                                        theme: 'stripe',
                                                        variables: {
                                                            colorPrimary:
                                                                theme.palette
                                                                    .primary
                                                                    .main,
                                                        },
                                                    },
                                                }}
                                                stripe={stripePromise}
                                            >
                                                <CheckoutForm
                                                    returnURL={returnURL}
                                                    clientSecret={clientSecret}
                                                />
                                            </Elements>
                                        </Box>
                                    )
                                )
                            ) : null
                        }
                    </Box>
                </CustomPopover>
            )}
            {success === 'success' ? (
                <Box
                    padding={1}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    height={'75vh'}
                    width={'75vw'}
                >
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'center'}
                        alignItems={'center'}
                        backgroundColor={theme.palette.primary.textWhite}
                        paddingTop={10}
                        paddingBottom={10}
                        paddingLeft={5}
                        paddingRight={5}
                        borderRadius={2}
                    >
                        <Typography
                            variant="h1"
                            fontSize={48}
                            textAlign={'center'}
                        >
                            Thank you for your purchase!
                        </Typography>
                        <Box padding={1}>
                            <CheckCircleOutlineIcon
                                style={{
                                    fontSize: 60,
                                    color: theme.palette.primary.successGreen,
                                }}
                                color="primary"
                            />
                        </Box>
                        <Typography
                            variant="h2"
                            fontSize={20}
                            textAlign={'center'}
                        >
                            You will receive an email to complete your account
                            setup shortly.
                        </Typography>
                        <Typography
                            variant="h2"
                            fontSize={16}
                            textAlign={'center'}
                        >
                            Please reach out to us if you have any questions!
                        </Typography>
                        <Box
                            padding={1}
                            display={'flex'}
                            justifyContent={'center'}
                            alignItems={'center'}
                            flexDirection={'row'}
                        >
                            <Box padding={1}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        window.location.href = '/'
                                    }}
                                >
                                    Home
                                </Button>
                            </Box>
                            <Box padding={1}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={() => {
                                        window.location.href = '/contactUs'
                                    }}
                                >
                                    Contact Us
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <Grid
                    container
                    //background color theme
                    bgcolor={theme.palette.primary.textWhite}
                    padding={2}
                >
                    <Grid
                        item
                        xs={12}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Typography
                            variant="h1"
                            fontSize={48}
                            color={theme.palette.primary.textBlack}
                            padding={1}
                        >
                            Order Services
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        display={'flex'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Typography
                            variant="h2"
                            fontSize={18}
                            textAlign={'center'}
                        >
                            Choose from a list of pre-priced services. If you
                            don't see what you need, please contact us for a
                            custom quote!
                        </Typography>
                    </Grid>

                    {services.map((service, index) => {
                        return (
                            <Grid
                                item
                                xs={12}
                                md={4}
                                lg={3}
                                key={index}
                                display={'flex'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                {
                                    //display the service name, photo and price using a MUI card
                                }
                                <Card
                                    sx={{
                                        width: 345,
                                        borderRadius: 2,
                                        padding: 1,
                                        margin: 1,
                                        backgroundColor:
                                            theme.palette.primary
                                                .supportingWhite,
                                    }}
                                >
                                    <CardMedia
                                        component="img"
                                        sx={{ maxWidth: 345, maxHeight: 200 }}
                                        image={service.photo}
                                        alt={service.name + ' photo'}
                                    />
                                    <CardContent>
                                        <Typography
                                            gutterBottom
                                            variant="h5"
                                            component="div"
                                        >
                                            {service.name}
                                        </Typography>
                                        <Typography
                                            gutterBottom
                                            variant="h6"
                                            fontSize={16}
                                            component="div"
                                        >
                                            {service.description}
                                        </Typography>

                                        <Typography
                                            variant="body1"
                                            color="text.secondary"
                                        >
                                            <CurrencyFormat
                                                value={service.price}
                                                displayType={'text'}
                                                thousandSeparator={true}
                                                prefix={'$'}
                                            />
                                        </Typography>
                                        <Typography
                                            gutterBottom
                                            variant="body1"
                                            fontSize={12}
                                            component="div"
                                        >
                                            {service.disclaimer}
                                        </Typography>

                                        {!selectedServices.includes(service) ? (
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundColor:
                                                        theme.palette.primary
                                                            .backup,
                                                    color: theme.palette.primary
                                                        .textWhite,
                                                }}
                                                onClick={() => {
                                                    setSelectedServices([
                                                        ...selectedServices,
                                                        service,
                                                    ])
                                                    setTotalPrice(
                                                        totalPrice +
                                                            service.price
                                                    )
                                                }}
                                            >
                                                Add
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    backgroundColor:
                                                        theme.palette.primary
                                                            .main,
                                                    color: theme.palette.primary
                                                        .textBlack,
                                                }}
                                                onClick={() => {
                                                    setSelectedServices(
                                                        selectedServices.filter(
                                                            (selectedService) =>
                                                                selectedService !==
                                                                service
                                                        )
                                                    )
                                                    setTotalPrice(
                                                        totalPrice -
                                                            service.price
                                                    )
                                                }}
                                            >
                                                Remove
                                            </Button>
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>
                        )
                    })}

                    {
                        /*
                    Display the services selected by the customer and the total price of the services selected.
                    */

                        selectedServices.length > 0 && (
                            <Grid
                                item
                                xs={12}
                                padding={1}
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                            >
                                <Typography
                                    variant="h6"
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    Selected Services:
                                </Typography>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Service</TableCell>
                                            <TableCell>Price</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {selectedServices.map(
                                            (service, index) => {
                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell>
                                                            {service.name}
                                                        </TableCell>
                                                        <TableCell>
                                                            <CurrencyFormat
                                                                value={
                                                                    service.price
                                                                }
                                                                displayType={
                                                                    'text'
                                                                }
                                                                thousandSeparator={
                                                                    true
                                                                }
                                                                prefix={'$'}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                )
                                            }
                                        )}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TableCell>
                                                <Typography variant="h6">
                                                    Total Price:
                                                </Typography>
                                            </TableCell>
                                            <TableCell>
                                                <Typography variant="h6">
                                                    ${totalPrice}
                                                </Typography>
                                            </TableCell>
                                        </TableRow>
                                    </TableFooter>
                                </Table>

                                <Box
                                    padding={1}
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    <Button
                                        variant="contained"
                                        sx={{
                                            backgroundColor:
                                                theme.palette.primary.backup,
                                            color: theme.palette.primary
                                                .textWhite,
                                        }}
                                        onClick={() => {
                                            handleCheckout()
                                        }}
                                    >
                                        Checkout
                                    </Button>
                                </Box>
                            </Grid>
                        )
                    }
                </Grid>
            )}
        </Box>
    )
}
