import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'
import moment from 'moment'

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 12,
        justifyContent: 'flex-start',
        width: '50%',
    },
    CreatedFor: {
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique',
    },
    invoiceNoTogetherContainer: {
        flexDirection: 'row',
        marginTop: 12,
        justifyContent: 'flex-end',
    },
    invoiceNoContainer: {
        flexDirection: 'column',
        marginTop: 12,
        justifyContent: 'flex-end',
    },
    container: {
        flexDirection: 'row',
        marginTop: 12,
        justifyContent: 'space-between',
    },
    invoiceNoContainerStarter: {
        flexDirection: 'column',
        justifyContent: 'flex-start',
    },
    invoiceDateContainer: {
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    invoiceDate: {
        fontSize: 12,
        fontStyle: 'bold',
    },
    label: {
        width: 60,
    },
    companyLabel: {
        width: 'auto',
        maxWidth: 250,
    },
    companyLabelEmail: {
        width: 'auto',
    },
    payTo: {
        paddingBottom: 3,
        fontFamily: 'Helvetica-Oblique',
    },
})

const CreatedFor = ({ estimate }) => (
    <View style={styles.container}>
        <View style={styles.invoiceNoContainerStarter}>
            <Text style={styles.CreatedFor}>Created for:</Text>
            <Text>{estimate?.first_name + ' ' + estimate?.last_name}</Text>
            <Text>{estimate?.address}</Text>
            <Text>{estimate?.phone}</Text>
            <Text>{estimate?.email}</Text>
            <Text>
                Generated on:{' '}
                {moment.utc(estimate?.date_generated).format('MM/DD/YYYY')}
            </Text>
        </View>
        <View style={styles.invoiceDateContainer}>
            <View>
                <Text style={styles.payTo}>Created by:</Text>

                {
                    //if the estimate has an estimate_created_by.first_name and last_name, display it, otherwise display the company name

                    estimate?.estimate_created_by?.first_name &&
                    estimate?.estimate_created_by?.last_name ? (
                        <Text style={styles.companyLabel}>
                            {estimate?.estimate_created_by?.first_name +
                                ' ' +
                                estimate?.estimate_created_by?.last_name}
                        </Text>
                    ) : (
                        <Text style={styles.companyLabel}>
                            All Round Contracting LLC
                        </Text>
                    )
                }

                <Text style={styles.companyLabel}>651-239-4444</Text>

                <Text style={styles.companyLabelEmail}>
                    connor@allroundcontractingllc.com
                </Text>

                <Text style={styles.companyLabel}>PO Box 2621</Text>
                <Text style={styles.companyLabel}>
                    Inver Grove Heights, MN 55076
                </Text>
            </View>
        </View>
    </View>
)

export default CreatedFor
