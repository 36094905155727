import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
    titleContainer: {
        marginTop: 12,
    },
    reportTitle: {
        marginTop: 25,
        fontSize: 12,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    howToSignUp: {
        fontSize: 12,
        textAlign: 'center',
        paddingTop: 10,
    },
    aThankYou: {
        fontSize: 12,
        textAlign: 'center',
    },
    termsAndConditions: {
        fontSize: 8,
        textAlign: 'center',
        paddingTop: 10,
    },
})

const EstimateThankYouMsg = () => (
    <View style={styles.titleContainer}>
        <Text style={styles.aThankYou}>
            Thank you for thinking of All Round Contracting LLC for your
            contracting needs. Any estimate over $1,500 requires a minimum
            deposit of 20%. The remaining balance is due upon completion of the
            project. We accept cash, checks, zelle, venmo and credit cards. A
            fee will be added to all credit card transactions. If you wish to
            proceed with the project please confirm the estimate with your All
            Round Contracting LLC representative. We will then schedule a time
            to begin the project. Please feel free to contact us with any
            questions or concerns!
        </Text>

        <Text style={styles.termsAndConditions}>
            Estimates are valid for 15 days and subject to change based on
            availability of materials and labor.
        </Text>
        <Text style={styles.reportTitle}>*** Thank You ***</Text>
    </View>
)

export default EstimateThankYouMsg
