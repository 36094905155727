import React from 'react'
import { Alert } from '@mui/material'
import { Error } from '@mui/icons-material'

export default function Failed(props) {
    const { error } = props
    return (
        <Alert icon={<Error fontSize="inherit" />} severity="error">
            {error}
        </Alert>
    )
}
