//A popover that mimics the addUserPopover.js file. The only difference is that this popover will pop up when the user clicks on the "Remove Customer" button.
// The popover should have a message, along with a "Delete" button and a "Cancel" button.
//
//Import the confirmDeletePopover.css file into this file.
//
//When a user clicks outside the popover, the popover will close.
//

import React from 'react'
import { Close } from '@mui/icons-material'
import { Box, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'

export default function ConfirmCancelPopover(props) {
    const { setPopoverVisible, cancelMessage, cancelFunction } = props

    const theme = useTheme()

    const handleCancel = () => {
        cancelFunction()
        setPopoverVisible(false)
    }

    return (
        <Box
            position={'fixed'}
            top={'0'}
            left={'0'}
            width={'100%'}
            height={'100%'}
            backgroundColor={'rgba(0, 0, 0, 0.5)'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            zIndex={'2'}
        >
            <Box
                width={'100%'}
                height={'100%'}
                position={'absolute'}
                top={'0'}
                left={'0'}
                onClick={() => setPopoverVisible(false)}
            ></Box>
            <Box
                zIndex={'3'}
                width={'auto'}
                borderRadius={2}
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'space-between'}
                alignItems={'center'}
                bgcolor={theme.palette.primary.textWhite}
                p={'5vh'}
            >
                <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                    alignItems={'flex-start'}
                    width={'100%'}
                    marginBottom={'10px'}
                >
                    <Close
                        width={'100%'}
                        height={'100%'}
                        display={'flex'}
                        onClick={() => setPopoverVisible(false)}
                    />
                </Box>

                <Box>{cancelMessage}</Box>
                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    width={'100%'}
                >
                    <Box p={'1vh'}>
                        <Button
                            variant="contained"
                            color="primary"
                            sx={{
                                '&:hover': {
                                    backgroundColor:
                                        theme.palette.primary.errorRed,
                                },
                            }}
                            onClick={() => handleCancel()}
                        >
                            Confirm Cancel
                        </Button>
                    </Box>
                    <Box p={'1vh'}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setPopoverVisible(false)}
                        >
                            Exit
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
