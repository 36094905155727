import React, { useState, useEffect } from 'react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import CheckoutForm from './CheckoutForm'
import axios from 'axios'
import { Box } from '@mui/system'
import CircularProgress from '@mui/material/CircularProgress'

import { useTheme } from '@mui/material/styles'

import { useAuth0 } from '@auth0/auth0-react'

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUB_KEY)

export default function PayInvoice(props) {
    const theme = useTheme()
    const [clientSecret, setClientSecret] = useState('')
    const { invoice } = props
    const [isLoading, setIsLoading] = useState(false)
    const { getAccessTokenSilently } = useAuth0()

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        //Fetch using the backend route and the backend address to create a payment intent

        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()
            setIsLoading(true)
            if (invoice.total_amount) {
                axios
                    .post(
                        `${process.env.REACT_APP_BACKEND_DB}/create-payment-intent`,
                        {
                            invoice: invoice,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${accessToken}`,
                            },
                        }
                    )

                    .then((data) => {
                        setClientSecret(data.data.clientSecret)
                        setIsLoading(false)
                    })
            }
        }
        getJWT()
    }, [invoice, getAccessTokenSilently])

    const appearance = {
        theme: 'stripe',
        variables: {
            colorPrimary: theme.palette.primary.main,
        },
    }
    const options = {
        clientSecret,
        appearance,
    }
    const returnURL = process.env.REACT_APP_ENV_ADDRESS + '/dashboard'

    return (
        <Box width={'auto'} height={'auto'}>
            {clientSecret && (
                <Box>
                    <Elements options={options} stripe={stripePromise}>
                        <CheckoutForm
                            returnURL={returnURL}
                            clientSecret={clientSecret}
                            invoice={invoice}
                        />
                    </Elements>
                </Box>
            )}
            {isLoading && (
                <Box
                    display={'flex'}
                    flexDirection={'column'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <CircularProgress />
                </Box>
            )}
        </Box>
    )
}
