import React, { useState } from 'react'
import axios from 'axios'
import { Close } from '@mui/icons-material'
import { Box } from '@mui/system'
import {
    Input,
    InputLabel,
    Typography,
    Button,
    FormControl,
    Select,
    MenuItem,
    CircularProgress,
} from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'
import Failed from '../../Components/FormStatusIcons/Failed'

export default function AddJobPopover(props) {
    const { getAccessTokenSilently } = useAuth0()
    const { setPopoverVisible, customerID } = props

    const [start_date, setStartDate] = useState(dayjs(new Date()))
    const [end_date, setEndDate] = useState(dayjs(new Date()))
    const [job_status, setJobStatus] = useState('')
    const [job_notes, setJobNotes] = useState('')
    const [job_description, setJobDescription] = useState('')
    const [submitting, setSubmitting] = useState(false)
    const [error, setError] = useState(null)

    const handleSubmit = () => {
        setSubmitting(true)
        const getJWT = async () => {
            const accessToken = await getAccessTokenSilently()
            if (!job_description || !job_status || !start_date || !end_date) {
                setError('Please fill out all fields')
                setSubmitting(false)
                return
            }

            axios
                .post(
                    `${process.env.REACT_APP_BACKEND_DB}/addJob`,
                    {
                        user_id: customerID,
                        start_date: start_date,
                        end_date: end_date,
                        job_status: job_status,
                        job_notes: job_notes,
                        job_description: job_description,
                    },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then(() => {
                    setPopoverVisible(false)
                    setSubmitting(false)
                })
        }
        getJWT()
    }

    return (
        <Box
            position={'fixed'}
            top={'0'}
            left={'0'}
            width={'100%'}
            height={'100%'}
            backgroundColor={'rgba(0, 0, 0, 0.5)'}
            sx={{
                backdropFilter: 'blur(5px)',
            }}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            zIndex={'2'}
        >
            <Box
                width={'100%'}
                height={'100%'}
                position={'absolute'}
                top={'0'}
                left={'0'}
                onClick={() => setPopoverVisible(false)}
            ></Box>
            <Box
                zIndex={'1'}
                position={'relative'}
                width={'auto'}
                height={'auto'}
                backgroundColor={'white'}
                borderRadius={'10px'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                padding={'5vh'}
            >
                <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                    alignItems={'flex-start'}
                    width={'100%'}
                    marginBottom={'10px'}
                >
                    <Close
                        position={'static'}
                        color={'black'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={() => setPopoverVisible(false)}
                    />
                </Box>
                {/*TODO: Form Validation */}
                <Box textAlign={'center'}>
                    <Box mb={'2.5vh'}>
                        <Typography variant="h3">Add Job</Typography>
                    </Box>
                </Box>
                <Box>
                    <Box>
                        <InputLabel>Job Description</InputLabel>
                        <Input
                            fullWidth
                            type="text"
                            placeholder="Type, for example: Snow Removal"
                            onChange={(e) => setJobDescription(e.target.value)}
                        />
                    </Box>
                    <Box>
                        <InputLabel>Job Notes</InputLabel>
                        <Input
                            fullWidth
                            type="text"
                            placeholder="EX: Customer wants to be notified before arrival"
                            onChange={(e) => setJobNotes(e.target.value)}
                        />
                    </Box>
                    <Box>
                        <InputLabel>Start Date:</InputLabel>
                        <DatePicker
                            value={start_date}
                            onChange={(e) => setStartDate(e)}
                        />
                    </Box>
                    <Box>
                        <InputLabel>End Date:</InputLabel>
                        <DatePicker
                            value={end_date}
                            onChange={(e) => setEndDate(e)}
                        />
                    </Box>
                    <Box>
                        <InputLabel>Job Status:</InputLabel>
                        {/*
                        
                        Make a MUI dropdown menu with the following options:
                        - In Progress
                        - Completed
                        - Cancelled
                        - Pending
                        - Scheduled
                        - Other
                        
                        */}
                        <FormControl fullWidth>
                            <InputLabel>Status</InputLabel>
                            <Select
                                id="demo-simple-select"
                                value={job_status}
                                label="Age"
                                onChange={(e) => setJobStatus(e.target.value)}
                            >
                                <MenuItem value={'Completed'}>
                                    Completed
                                </MenuItem>
                                <MenuItem value={'Cancelled'}>
                                    Cancelled
                                </MenuItem>
                                <MenuItem value={'In Progress'}>
                                    In Progress
                                </MenuItem>
                                <MenuItem value={'Pending'}>Pending</MenuItem>
                                <MenuItem value={'Scheduled'}>
                                    Scheduled
                                </MenuItem>
                                <MenuItem value={'Other'}>Other</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Box mt={'2.5vh'}>
                        {error ? <Failed error={error}></Failed> : null}
                        {submitting ? (
                            <Typography variant={'h4'}>
                                <CircularProgress size={24} /> Submitting Job...
                            </Typography>
                        ) : (
                            <Button onClick={handleSubmit}>Submit</Button>
                        )}
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
