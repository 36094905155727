//This file will be used on the contractor page to link accounts
//when a customer registers for an account but there is already an account
//with the email, address, or phone number they entered. This will then
//link the accounts together so that the customer can see all of their
//information in one place. On this page the contractor should be able to pick between
//the accounts to link together. The contractor should also be able to see
//the information for each account.
//Ultimately the account that was originally set up should be the one that
//is kept and the other account should be deleted. The contractor should
//be able to see the information for both accounts so that they can make
//the decision about which account information to keep.

import React, { useEffect, useState } from 'react'

import axios from 'axios'

import { Close } from '@mui/icons-material'
import { Box } from '@mui/system'
import { useTheme } from '@mui/material/styles'

import {
    Typography,
    Button,
    TableContainer,
    Grid,
    ToggleButtonGroup,
} from '@mui/material'
import Success from '../../Components/FormStatusIcons/Success'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

import { useAuth0 } from '@auth0/auth0-react'
import { ToggleButton } from '@mui/material'
import Failed from '../../Components/FormStatusIcons/Failed'

export default function LinkAccountsPopover(props) {
    const { getAccessTokenSilently } = useAuth0()
    const theme = useTheme()

    const { setPopoverVisible, customers } = props

    const [formStatus, setFormStatus] = useState('Awaiting Users')

    const [customerUser, setCustomerUser] = useState({})
    const [contractorUser, setContractorUser] = useState({})
    const [updatedUser, setUpdatedUser] = useState({})
    const [errorStatus, setErrorStatus] = useState('')

    const [listOfUsers, setListOfUsers] = useState([])

    //this function should be able to take in a phone number and remove all of the non-numeric characters to compare it to the phone number in the database

    const handleLinkAccount = async () => {
        if (
            !updatedUser.first_name ||
            !updatedUser.last_name ||
            !updatedUser.email ||
            !updatedUser.phone_number ||
            !updatedUser.address
        ) {
            setErrorStatus('Please Fill Out All Fields')
            return
        } else {
            setErrorStatus('')
        }
        setFormStatus('Linking Account')

        const token = await getAccessTokenSilently()
        axios
            .post(
                `${process.env.REACT_APP_BACKEND_DB}/mergeUsers`,
                {
                    user_id: contractorUser.user_id,
                    customerUser_user_id: customerUser.user_id,
                    first_name: updatedUser.first_name,
                    last_name: updatedUser.last_name,
                    email_address: updatedUser.email,
                    phone_number: updatedUser.phone_number,
                    address: updatedUser.address,
                    customer_sub: customerUser.customer_sub,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
            .then((response) => {
                setFormStatus('Accounts Linked')
                setTimeout(() => {
                    setPopoverVisible(false)
                }, 1000)
            })
            .catch((error) => {
                console.log(error)
                setFormStatus('Error Linking Accounts')
            })
    }

    useEffect(() => {
        //Get the list of users who havent finished registration

        const getListOfUsers = async () => {
            setFormStatus('Searching For Users')
            const accessToken = await getAccessTokenSilently()

            axios
                .get(
                    `${process.env.REACT_APP_BACKEND_DB}/getCustomersUnfinishedRegistrationWithSub`,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then((response) => {
                    setListOfUsers(response.data)
                    setFormStatus('Awaiting Link Data')
                })
                .catch((error) => {
                    console.log(error)
                    setFormStatus('No Users Found')
                })
        }

        getListOfUsers()
    }, [getAccessTokenSilently])

    return (
        <Box
            position={'fixed'}
            top={'0'}
            left={'0'}
            width={'100%'}
            height={'100%'}
            backgroundColor={'rgba(0, 0, 0, 0.5)'}
            display={'flex'}
            justifyContent={'center'}
            alignItems={'center'}
            zIndex={'2'}
            sx={{ backdropFilter: 'blur(5px)' }}
        >
            <Box
                width={'auto'}
                height={'100%'}
                position={'absolute'}
                top={'0'}
                left={'0'}
                onClick={() => setPopoverVisible(false)}
            ></Box>
            <Box
                zIndex={'1'}
                position={'relative'}
                width={'auto'}
                height={'auto'}
                maxHeight={'95vh'}
                maxWidth={'95vw'}
                backgroundColor={'white'}
                borderRadius={'10px'}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                padding={'5vh'}
                overflow={'auto'}
            >
                <Box
                    display={'flex'}
                    justifyContent={'flex-end'}
                    alignItems={'flex-start'}
                    width={'100%'}
                    marginBottom={'10px'}
                >
                    <Close
                        position={'static'}
                        color={'black'}
                        fontWeight={'bold'}
                        cursor={'pointer'}
                        onClick={() => setPopoverVisible(false)}
                    />
                </Box>

                {formStatus === 'Awaiting Link Data' ? (
                    <Box>
                        <Box textAlign={'center'}>
                            <Box mb={'2.5vh'}>
                                <Typography variant="h3">
                                    Customer Created Accounts
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell>First Name</TableCell>
                                            <TableCell>Last Name</TableCell>
                                            <TableCell>Email Address</TableCell>
                                            <TableCell>Phone Number</TableCell>
                                            <TableCell>Address</TableCell>
                                            <TableCell>Link</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {listOfUsers.map((user) => {
                                            return (
                                                <TableRow key={user.user_id}>
                                                    <TableCell>
                                                        {user.user_id}
                                                    </TableCell>

                                                    <TableCell>
                                                        {user.first_name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {user.last_name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {user.email}
                                                    </TableCell>
                                                    <TableCell>
                                                        {user.phone_number}
                                                    </TableCell>
                                                    <TableCell>
                                                        {user.address}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => {
                                                                setCustomerUser(
                                                                    user
                                                                )

                                                                setFormStatus(
                                                                    'User Selected, Awaiting Contractor Account'
                                                                )
                                                            }}
                                                        >
                                                            Select
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                ) : (
                    <></>
                )}
                {formStatus === 'Searching For Users' ? (
                    <Typography variant="h6">Searching for Users</Typography>
                ) : (
                    <></>
                )}
                {formStatus === 'Users Selected' ? (
                    <Box
                        display={'flex'}
                        flexDirection={'column'}
                        justifyContent={'space-between'}
                        alignItems={'center'}
                    >
                        <Box textAlign={'center'}>
                            <Box mb={'2.5vh'}>
                                <Typography variant="h3">
                                    Link These Users
                                </Typography>
                            </Box>
                        </Box>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box
                                    display={'flex'}
                                    flexDirection={'column'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                >
                                    <Typography variant="h6">
                                        Merge Users
                                    </Typography>
                                    <Typography variant="body1">
                                        Select the information you would like to
                                        keep
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'center'}
                                alignItems={'center'}
                            >
                                <Box padding={'10px'}>
                                    <ToggleButtonGroup
                                        //on change select between customer and contractor
                                        value={
                                            updatedUser.first_name_selected_value
                                        }
                                        exclusive
                                        onChange={(e) => {
                                            //on change the updatedUser.first_name state to be the customerUser.first_name
                                            setUpdatedUser({
                                                ...updatedUser,
                                                first_name:
                                                    e.target.value ===
                                                    'customer'
                                                        ? customerUser.first_name
                                                        : contractorUser.first_name,
                                                first_name_selected_value:
                                                    e.target.value,
                                            })
                                        }}
                                    >
                                        <ToggleButton value={'customer'}>
                                            {customerUser.first_name}
                                        </ToggleButton>
                                        <ToggleButton value={'contractor'}>
                                            {contractorUser.first_name}
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                                <Box padding={'10px'}>
                                    <ToggleButtonGroup
                                        //on change select between customer and contractor
                                        value={
                                            updatedUser.last_name_selected_value
                                        }
                                        exclusive
                                        onChange={(e) => {
                                            //on change the updatedUser.last_name state to be the customerUser.last_name
                                            setUpdatedUser({
                                                ...updatedUser,
                                                last_name:
                                                    e.target.value ===
                                                    'customer'
                                                        ? customerUser.last_name
                                                        : contractorUser.last_name,
                                                last_name_selected_value:
                                                    e.target.value,
                                            })
                                        }}
                                    >
                                        <ToggleButton value={'customer'}>
                                            {customerUser.last_name}
                                        </ToggleButton>
                                        <ToggleButton value={'contractor'}>
                                            {contractorUser.last_name}
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                                <Box padding={'10px'}>
                                    <ToggleButtonGroup
                                        //on change select between customer and contractor
                                        value={updatedUser.email_selected_value}
                                        exclusive
                                        onChange={(e) => {
                                            //on change the updatedUser.email state to be the customerUser.email
                                            setUpdatedUser({
                                                ...updatedUser,
                                                email:
                                                    e.target.value ===
                                                    'customer'
                                                        ? customerUser.email
                                                        : contractorUser.email,
                                                email_selected_value:
                                                    e.target.value,
                                            })
                                        }}
                                    >
                                        <ToggleButton value={'customer'}>
                                            {customerUser.email}
                                        </ToggleButton>
                                        <ToggleButton value={'contractor'}>
                                            {contractorUser.email}
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                                <Box padding={'10px'}>
                                    <ToggleButtonGroup
                                        //on change select between customer and contractor
                                        value={
                                            updatedUser.phone_number_selected_value
                                        }
                                        exclusive
                                        onChange={(e) => {
                                            //on change the updatedUser.phone_number state to be the customerUser.phone_number
                                            setUpdatedUser({
                                                ...updatedUser,
                                                phone_number:
                                                    e.target.value ===
                                                    'customer'
                                                        ? customerUser.phone_number
                                                        : contractorUser.phone_number,
                                                phone_number_selected_value:
                                                    e.target.value,
                                            })
                                        }}
                                    >
                                        <ToggleButton value={'customer'}>
                                            {customerUser.phone_number}
                                        </ToggleButton>
                                        <ToggleButton value={'contractor'}>
                                            {contractorUser.phone_number}
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                                <Box padding={'10px'}>
                                    <ToggleButtonGroup
                                        //on change select between customer and contractor
                                        value={
                                            updatedUser.address_selected_value
                                        }
                                        exclusive
                                        onChange={(e) => {
                                            //on change the updatedUser.address state to be the customerUser.address
                                            setUpdatedUser({
                                                ...updatedUser,
                                                address:
                                                    e.target.value ===
                                                    'customer'
                                                        ? customerUser.address
                                                        : contractorUser.address,
                                                address_selected_value:
                                                    e.target.value,
                                            })
                                        }}
                                    >
                                        <ToggleButton value={'customer'}>
                                            {customerUser.address}
                                        </ToggleButton>
                                        <ToggleButton value={'contractor'}>
                                            {contractorUser.address}
                                        </ToggleButton>
                                    </ToggleButtonGroup>
                                </Box>
                                <Box padding={'10px'}>
                                    <Box
                                        padding={'10px'}
                                        display={'flex'}
                                        flexDirection={'column'}
                                        justifyContent={'center'}
                                        alignItems={'center'}
                                        backgroundColor={
                                            theme.palette.primary.textWhite
                                        }
                                        borderRadius={'5px'}
                                    >
                                        <Typography variant="h6">
                                            - Other Customer Info -
                                        </Typography>
                                        <Typography variant="h6">
                                            ID: {contractorUser.user_id}
                                        </Typography>
                                        <Typography variant="h6">
                                            Customer Sub:{' '}
                                            {customerUser.customer_sub}
                                        </Typography>
                                        <Typography variant="h6">
                                            Owned by:{' '}
                                            {
                                                contractorUser.owned_by_contractor_user_id
                                            }
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            {/*
                            
                            <Grid item xs={6}>
                                <Typography variant="h6">
                                    Customer Created User
                                </Typography>
                                <Typography variant="h6">
                                    ID: {customerUser.user_id}
                                </Typography>
                                <Box>
                                    <InputLabel>First Name:</InputLabel>
                                    <Input
                                        type="text"
                                        placeholder={customerUser.first_name}
                                        onChange={(e) =>
                                            setCustomerUser({
                                                ...customerUser,
                                                first_name: e.target.value,
                                            })
                                        }
                                    />
                                </Box>
                                <Box>
                                    <InputLabel>Last Name:</InputLabel>
                                    <Input
                                        type="text"
                                        placeholder={customerUser.last_name}
                                        onChange={(e) =>
                                            setCustomerUser({
                                                ...customerUser,
                                                last_name: e.target.value,
                                            })
                                        }
                                    />
                                </Box>
                                <Box>
                                    <InputLabel>Email Address:</InputLabel>
                                    <Input
                                        type="text"
                                        placeholder={customerUser.email}
                                        onChange={(e) =>
                                            setCustomerUser({
                                                ...customerUser,
                                                email: e.target.value,
                                            })
                                        }
                                    />
                                </Box>
                                <Box>
                                    <InputLabel>Phone Number:</InputLabel>
                                    <Input
                                        type="tel"
                                        placeholder={customerUser.phone_number}
                                        onChange={(e) =>
                                            setCustomerUser({
                                                ...customerUser,
                                                phone_number: e.target.value,
                                            })
                                        }
                                    />
                                </Box>
                                <Box>
                                    <InputLabel>Address:</InputLabel>
                                    <Input
                                        type="text"
                                        placeholder={customerUser.address}
                                        onChange={(e) =>
                                            setCustomerUser({
                                                ...customerUser,
                                                address: e.target.value,
                                            })
                                        }
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography variant="h6">
                                    Contractor Created User
                                </Typography>
                                <Typography variant="h6">
                                    New ID: {contractorUser.user_id}
                                </Typography>
                                <Box>
                                    <InputLabel>First Name:</InputLabel>
                                    <Input
                                        type="text"
                                        placeholder={contractorUser.first_name}
                                        disabled={true}
                                    />
                                </Box>
                                <Box>
                                    <InputLabel>Last Name:</InputLabel>
                                    <Input
                                        type="text"
                                        placeholder={contractorUser.last_name}
                                        disabled={true}
                                    />
                                </Box>
                                <Box>
                                    <InputLabel>Email Address:</InputLabel>
                                    <Input
                                        type="text"
                                        placeholder={contractorUser.email}
                                        disabled={true}
                                    />
                                </Box>
                                <Box>
                                    <InputLabel>Phone Number:</InputLabel>
                                    <Input
                                        type="tel"
                                        placeholder={
                                            contractorUser.phone_number
                                        }
                                        disabled={true}
                                    />
                                </Box>
                                <Box>
                                    <InputLabel>Address:</InputLabel>
                                    <Input
                                        type="text"
                                        placeholder={contractorUser.address}
                                        disabled={true}
                                    />
                                </Box>
                            </Grid>
                                    */}
                        </Grid>
                        <Box
                            display={'flex'}
                            flexDirection={'column'}
                            justifyContent={'center'}
                            alignItems={'center'}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleLinkAccount}
                            >
                                Merge Accounts
                            </Button>
                            {errorStatus !== '' ? (
                                <Failed error={errorStatus}></Failed>
                            ) : null}
                        </Box>
                    </Box>
                ) : (
                    <></>
                )}
                {formStatus === 'Linking Account' ? (
                    <Typography variant="h6">Linking Account</Typography>
                ) : (
                    <></>
                )}
                {formStatus === 'No Users Found' ? (
                    <Typography variant="h6">No Users Found</Typography>
                ) : (
                    <></>
                )}

                {formStatus === 'Users Found' ? (
                    <Typography variant="h6">
                        Users Found... Loading info for Users
                    </Typography>
                ) : (
                    <></>
                )}
                {formStatus === 'User Selected, Awaiting Contractor Account' ? (
                    <Box>
                        <Box textAlign={'center'}>
                            <Box mb={'2.5vh'}>
                                <Typography variant="h3">
                                    Contractor Accounts
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <TableContainer>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>ID</TableCell>
                                            <TableCell>First Name</TableCell>
                                            <TableCell>Last Name</TableCell>
                                            <TableCell>Email Address</TableCell>
                                            <TableCell>Phone Number</TableCell>
                                            <TableCell>Address</TableCell>
                                            <TableCell>Link</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {customers?.map((user) => {
                                            return (
                                                <TableRow key={user.user_id}>
                                                    <TableCell>
                                                        {user.user_id}
                                                    </TableCell>
                                                    <TableCell>
                                                        {user.first_name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {user.last_name}
                                                    </TableCell>
                                                    <TableCell>
                                                        {user.email}
                                                    </TableCell>
                                                    <TableCell>
                                                        {user.phone_number}
                                                    </TableCell>
                                                    <TableCell>
                                                        {user.address}
                                                    </TableCell>
                                                    <TableCell>
                                                        <Button
                                                            variant="contained"
                                                            onClick={() => {
                                                                setContractorUser(
                                                                    user
                                                                )
                                                                setFormStatus(
                                                                    'Users Selected'
                                                                )
                                                            }}
                                                        >
                                                            Select
                                                        </Button>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                ) : (
                    <></>
                )}
                {formStatus === 'Invoice Successfully Generated' ? (
                    <Success title={'Invoice Generated'}></Success>
                ) : (
                    <></>
                )}
            </Box>
        </Box>
    )
}
