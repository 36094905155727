import React from 'react'
import { Text, View, StyleSheet } from '@react-pdf/renderer'

const styles = StyleSheet.create({
    titleContainer: {
        marginTop: 5,
        //a full width box with a black border
        borderWidth: 1,
        borderColor: 'black',
        borderStyle: 'solid',
        borderRadius: 5,
        padding: 2,
        width: '100%',
        minHeight: 50,
    },
    aThankYou: {
        fontSize: 12,
        textAlign: 'center',
    },
    notesLabel: {
        fontSize: 12,
        fontWeight: 'bold',
        position: 'absolute',
        padding: 2,
        top: 0,
        left: 0,
        paddingRight: 10,
    },
})

const EstimateNotes = ({ estimate }) =>
    estimate.notes ? (
        <View style={styles.titleContainer}>
            <Text style={styles.notesLabel}>Estimate Notes:</Text>
            <Text style={styles.aThankYou}>{estimate.notes}</Text>
        </View>
    ) : null

export default EstimateNotes
