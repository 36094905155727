import React from 'react'

import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Typography from '@mui/material/Typography'

import Box from '@mui/material/Box'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useTheme } from '@mui/material/styles'
import { CircularProgress } from '@mui/material'
import Grid from '@mui/material/Grid'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

const CustomerInvoices = ({ invoices, isLoading }) => {
    const navigate = useNavigate()
    const theme = useTheme()
    const [paidFilter, setPaidFilter] = React.useState('unpaid')

    const handleFilterChange = (event, newFilter) => {
        if (newFilter !== null) {
            setPaidFilter(newFilter)
        }
    }

    const filterInvoices = (invoices) => {
        if (!invoices) return []
        if (paidFilter === 'all') return invoices
        return invoices.filter((invoice) => {
            return invoice.invoice_status === paidFilter
        })
    }

    const filteredInvoices = filterInvoices(invoices)

    if (!invoices || isLoading)
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Box
                    display={'flex'}
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    padding={2}
                >
                    <Typography
                        variant="h4"
                        color={theme.palette.primary.textWhite}
                    >
                        Invoices
                    </Typography>
                </Box>
                <CircularProgress />
            </Box>
        )
    return (
        <Box>
            <Box
                display={'flex'}
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                padding={2}
            >
                <Typography
                    variant="h4"
                    color={theme.palette.primary.textWhite}
                >
                    Invoices
                </Typography>
            </Box>
            <Box
                display={'flex'}
                flexDirection="row"
                justifyContent="center"
                alignItems="center"
                padding={2}
            >
                <Typography
                    variant="h6"
                    color={theme.palette.primary.textWhite}
                    style={{ marginRight: '10px' }}
                >
                    Filter by:
                </Typography>

                <ToggleButtonGroup
                    value={paidFilter}
                    exclusive
                    onChange={handleFilterChange}
                    style={{ backgroundColor: 'black' }}
                >
                    <ToggleButton
                        value="all"
                        style={{
                            backgroundColor:
                                paidFilter === 'all' ? 'gray' : 'black',
                            color: paidFilter === 'all' ? 'white' : 'gray',
                        }}
                    >
                        All
                    </ToggleButton>
                    <ToggleButton
                        value="paid"
                        style={{
                            backgroundColor:
                                paidFilter === 'paid' ? 'gray' : 'black',
                            color: paidFilter === 'paid' ? 'white' : 'gray',
                        }}
                    >
                        Paid
                    </ToggleButton>
                    <ToggleButton
                        value="unpaid"
                        style={{
                            backgroundColor:
                                paidFilter === 'unpaid' ? 'gray' : 'black',
                            color: paidFilter === 'unpaid' ? 'white' : 'gray',
                        }}
                    >
                        Unpaid
                    </ToggleButton>
                </ToggleButtonGroup>
            </Box>
            {!filteredInvoices.length ? (
                <Typography
                    variant="body1"
                    textAlign={'center'}
                    color={theme.palette.primary.textWhite}
                >
                    No Results Found
                </Typography>
            ) : null}

            {filteredInvoices
                ?.sort((a, b) => {
                    return (
                        new Date(b.date_generated) - new Date(a.date_generated)
                    )
                })
                .map((invoice, index) => (
                    <Card
                        key={index}
                        style={{
                            margin: '10px',
                            padding: '10px',
                            border: '1px solid black',
                            //hover effect
                        }}
                    >
                        <CardContent
                            sx={{
                                '&:hover': {
                                    backgroundColor: 'lightgray',
                                    cursor: 'pointer',
                                },
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'flex-start',
                                padding: '10px',
                                position: 'relative',
                            }}
                            onClick={() =>
                                navigate(
                                    `/pdf/${invoice.user_id}/${invoice.invoice_id}`
                                )
                            }
                        >
                            <Grid container>
                                <Grid item xs={12} sm={9}>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            fontWeight: 'bold',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        Invoice #{invoice.invoice_id}
                                    </Typography>
                                    <Typography>
                                        Date Generated:{' '}
                                        {moment
                                            .utc(invoice.date_generated)
                                            .format('MM/DD/YYYY')}
                                    </Typography>
                                    <Typography>
                                        Due Date:{' '}
                                        {moment
                                            .utc(invoice.due_date)
                                            .format('MM/DD/YYYY')}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={3}>
                                    <Typography
                                        variant="h6"
                                        style={{
                                            fontWeight: 'bold',
                                            marginBottom: '10px',
                                        }}
                                    >
                                        Total Amount:{' '}
                                        {new Intl.NumberFormat('en-US', {
                                            style: 'currency',
                                            currency: 'USD',
                                        }).format(invoice.total_amount)}
                                    </Typography>
                                    <Typography>
                                        Invoice Status:{' '}
                                        {invoice.invoice_status.toUpperCase()}
                                    </Typography>
                                    {invoice.invoice_status === 'paid' ? (
                                        <span
                                            style={{
                                                color: theme.palette.primary
                                                    .successGreen,
                                                fontWeight: 'bold',
                                                fontSize: '1.2em',
                                            }}
                                        >
                                            [PAID]
                                        </span>
                                    ) : invoice.invoice_status === 'unpaid' &&
                                      moment
                                          .utc(invoice.due_date)
                                          .isBefore(moment.utc(new Date())) ? (
                                        <span
                                            style={{
                                                color: theme.palette.primary
                                                    .errorRed,
                                                fontWeight: 'bold',
                                                fontSize: '1.2em',
                                            }}
                                        >
                                            [OVERDUE]
                                        </span>
                                    ) : invoice.invoice_status === 'unpaid' ? (
                                        <span
                                            style={{
                                                color: theme.palette.primary
                                                    .cautionYellow,
                                                fontWeight: 'bold',
                                                fontSize: '1.2em',
                                            }}
                                        >
                                            [PENDING]
                                        </span>
                                    ) : invoice.invoice_status ===
                                      'cancelled' ? (
                                        <span
                                            style={{
                                                color: theme.palette.primary
                                                    .errorRed,
                                                fontWeight: 'bold',
                                                fontSize: '1.2em',
                                            }}
                                        >
                                            [CANCELLED]
                                        </span>
                                    ) : null}
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                ))}
        </Box>
    )
}

export default CustomerInvoices
