import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { Box } from '@mui/system'
import SettingsIcon from '@mui/icons-material/Settings'
import { Typography } from '@mui/material'
import EditCustomerPopover from '../../../modals/EditCustomerPopover/EditCustomerPopover'
import { useTheme } from '@mui/material/styles'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress'

export default function CustomerInfoEditable(props) {
    const { customerID } = props
    const [customer, setCustomer] = useState({})
    const [editUserPopover, setEditUserPopover] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const { getAccessTokenSilently } = useAuth0()
    const theme = useTheme()

    useEffect(() => {
        if (editUserPopover) return

        const getJWT = async () => {
            setIsLoading(true)
            const accessToken = await getAccessTokenSilently()

            axios
                .get(
                    `${process.env.REACT_APP_BACKEND_DB}/getCustomer/` +
                        customerID,

                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then((customer) => {
                    setCustomer(customer?.data)
                    setIsLoading(false)
                })
        }

        getJWT()
    }, [customerID, getAccessTokenSilently, editUserPopover])

    //Return the customer info
    const handleOnClick = () => {
        setEditUserPopover(!editUserPopover)
    }
    if (isLoading)
        return (
            <Box display="flex" justifyContent="center" alignItems="center">
                <CircularProgress />
            </Box>
        )

    return (
        <Box
            p={2}
            width={'auto'}
            maxWidth={'95%'}
            height={'auto'}
            display={'inline-flex'}
            flexDirection={'column'}
            justifyContent={'center'}
            color={theme.palette.primary.textWhite}
        >
            <Box
                display={'flex'}
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Typography
                    textAlign={'center'}
                    variant="h4"
                    color={theme.palette.primary.textWhite}
                >
                    Account Information
                </Typography>

                <Box
                    display={'inline-flex'}
                    flexDirection={'column'}
                    justifyContent={'left'}
                    p={2}
                >
                    <Box
                        display={'inline-flex'}
                        flexDirection={'row'}
                        justifyContent={'left'}
                        alignItems={'center'}
                    >
                        <Typography p={1} variant="h6">
                            User ID:{' '}
                        </Typography>
                        <Typography variant="body1">
                            {customer?.user_id}
                        </Typography>
                    </Box>

                    <Box
                        display={'inline-flex'}
                        flexDirection={'row'}
                        justifyContent={'left'}
                        alignItems={'center'}
                    >
                        <Typography p={1} variant="h6">
                            Name:{' '}
                        </Typography>

                        <Typography variant="body1">
                            {customer?.first_name + ' ' + customer?.last_name}
                        </Typography>
                    </Box>
                    <Box
                        display={'inline-flex'}
                        flexDirection={'row'}
                        justifyContent={'left'}
                        alignItems={'center'}
                    >
                        <Typography p={1} variant="h6">
                            Address:{' '}
                        </Typography>
                        <Typography variant="body1">
                            {customer?.address || 'N/A'}
                        </Typography>
                    </Box>
                    <Box
                        display={'inline-flex'}
                        flexDirection={'row'}
                        justifyContent={'left'}
                        alignItems={'center'}
                    >
                        <Typography p={1} variant="h6">
                            Phone Number:{' '}
                        </Typography>
                        <Typography variant="body1">
                            {customer?.phone_number || 'N/A'}
                        </Typography>
                    </Box>
                    <Box
                        display={'inline-flex'}
                        flexDirection={'row'}
                        justifyContent={'center'}
                        alignItems={'center'}
                    >
                        <Typography p={1} variant="h6">
                            Email:{' '}
                        </Typography>
                        <Typography variant="body1">
                            {customer?.email || 'N/A'}
                        </Typography>
                    </Box>
                </Box>

                <Box
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <Button
                        variant="contained"
                        onClick={handleOnClick}
                        startIcon={<SettingsIcon />}
                        sx={{
                            backgroundColor: theme.palette.primary.backup,
                            color: theme.palette.primary.textWhite,
                        }}
                    >
                        Manage
                    </Button>
                </Box>

                {editUserPopover && (
                    <EditCustomerPopover
                        customer={customer}
                        setEditUserPopover={setEditUserPopover}
                    />
                )}
            </Box>
        </Box>
    )
}
