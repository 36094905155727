/*

A customer table that will take in a list of customerID's and display 
them in a table. The table will have a column for each of the following:
- Customer ID
- Customer Name
- Customer Address
- Customer Email
- Customer Balance
- Customer Messages
- Open Jobs


Import fonts from Google Fonts and use them for:
- Customer Name
- Customer Address
- Customer Email


Import the customerTable.css file into this file.


*/

import React, { useEffect } from 'react'
import './customerTable.css'
import { Typography, Box, Tooltip, Button } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import Success from '../../../utils/Components/FormStatusIcons/Success'
import { CheckBox, NoAccounts } from '@mui/icons-material'

import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-react'
import CustomPopover from '../../../utils/modals/CustomPopover/CustomPopover'
import { Grid } from '@mui/material'

export default function CustomerTable(props) {
    const {
        customers,
        customerSearch,
        searchActiveCustomers,
        searchPastDueInvoices,
        handleToggle,
        setToCustomerDetail,
    } = props

    const theme = useTheme()
    const { getAccessTokenSilently } = useAuth0()
    const [confirmDropCustomer, setConfirmDropCustomer] = React.useState(false)
    const [customerToDrop, setCustomerToDrop] = React.useState({})
    const [confirmDeleteCustomer, setConfirmDeleteCustomer] =
        React.useState(false)
    const [customerToDelete, setCustomerToDelete] = React.useState({})
    const [filteredCustomers, setFilteredCustomers] = React.useState({})
    const [statusMessage, setStatusMessage] = React.useState('')

    const executeDropCustomer = async () => {
        const accessToken = await getAccessTokenSilently()

        //An axios route to set the owned_by_contractor_user_id to null
        customerToDrop &&
            axios
                .post(
                    `${process.env.REACT_APP_BACKEND_DB}/removeCustomer`,
                    { customerID: customerToDrop.user_id },
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then(() => {
                    setStatusMessage('Customer Dropped')
                    setTimeout(() => {
                        setStatusMessage('')
                        handleToggle('View Customers')
                    }, 1000)
                })
    }

    const executeDeleteCustomer = async () => {
        const accessToken = await getAccessTokenSilently()

        customerToDelete &&
            axios
                .delete(
                    `${process.env.REACT_APP_BACKEND_DB}/deleteCustomer/` +
                        customerToDelete.user_id,
                    {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                )
                .then(() => {
                    setStatusMessage('Customer Deleted')
                    setTimeout(() => {
                        setStatusMessage('')
                        handleToggle('View Customers')
                    }, 1000)
                })
    }

    const handleDropCustomer = (customerID) => {
        setConfirmDropCustomer(true)
        setCustomerToDrop(customerID)
    }

    const handleDeleteCustomer = (customerID) => {
        setConfirmDeleteCustomer(true)
        setCustomerToDelete(customerID)
    }
    const checkUsersJobs = (user) => {
        let jobs = 0
        if (user.jobs) {
            Object.keys(user.jobs).forEach((key) => {
                if (user.jobs[key].job_status === 'In Progress') {
                    jobs++
                }
            })
        }
        if (jobs > 0) {
            return true
        }
        return false
    }

    const checkUserInvoices = (user) => {
        let invoices = 0
        if (user.invoices) {
            Object.keys(user.invoices).forEach((key) => {
                if (user.invoices[key].invoice_status === 'unpaid') {
                    //if the invoice is unpaid and past due
                    if (
                        new Date(user.invoices[key].due_date) <
                        new Date(Date.now())
                    ) {
                        invoices++
                    }
                }
            })
        }
        if (invoices > 0) {
            return true
        }
        return false
    }

    useEffect(() => {
        const filterCustomers = () => {
            let filteredCustomers = {}
            if (searchActiveCustomers && searchPastDueInvoices) {
                filteredCustomers = Object.keys(customers).reduce(
                    (acc, key) => {
                        if (
                            checkUsersJobs(customers[key]) &&
                            checkUserInvoices(customers[key]) &&
                            (customerSearch === '' ||
                                customers[key].first_name
                                    .toLowerCase()
                                    .includes(customerSearch.toLowerCase()) ||
                                customers[key].last_name
                                    .toLowerCase()
                                    .includes(customerSearch.toLowerCase()) ||
                                customers[key].phone_number
                                    .toLowerCase()
                                    .includes(customerSearch.toLowerCase()) ||
                                customers[key].address
                                    .toLowerCase()
                                    .includes(customerSearch.toLowerCase()))
                        ) {
                            acc[key] = customers[key]
                        }
                        return acc
                    },
                    {}
                )
            } else if (searchActiveCustomers) {
                filteredCustomers = Object.keys(customers).reduce(
                    (acc, key) => {
                        if (
                            checkUsersJobs(customers[key]) &&
                            (customerSearch === '' ||
                                customers[key].first_name
                                    .toLowerCase()
                                    .includes(customerSearch.toLowerCase()) ||
                                customers[key].last_name
                                    .toLowerCase()
                                    .includes(customerSearch.toLowerCase()) ||
                                customers[key].phone_number
                                    .toLowerCase()
                                    .includes(customerSearch.toLowerCase()) ||
                                customers[key].address
                                    .toLowerCase()
                                    .includes(customerSearch.toLowerCase()))
                        ) {
                            acc[key] = customers[key]
                        }
                        return acc
                    },
                    {}
                )
            } else if (searchPastDueInvoices) {
                filteredCustomers = Object.keys(customers).reduce(
                    (acc, key) => {
                        if (
                            checkUserInvoices(customers[key]) &&
                            (customerSearch === '' ||
                                customers[key].first_name
                                    .toLowerCase()
                                    .includes(customerSearch.toLowerCase()) ||
                                customers[key].last_name
                                    .toLowerCase()
                                    .includes(customerSearch.toLowerCase()) ||
                                customers[key].phone_number
                                    .toLowerCase()
                                    .includes(customerSearch.toLowerCase()) ||
                                customers[key].address
                                    .toLowerCase()
                                    .includes(customerSearch.toLowerCase()))
                        ) {
                            acc[key] = customers[key]
                        }
                        return acc
                    },
                    {}
                )
            } else {
                filteredCustomers = Object.keys(customers).reduce(
                    (acc, key) => {
                        if (
                            customerSearch === '' ||
                            customers[key].first_name
                                .toLowerCase()
                                .includes(customerSearch.toLowerCase()) ||
                            customers[key].last_name
                                .toLowerCase()
                                .includes(customerSearch.toLowerCase()) ||
                            customers[key].phone_number
                                .toLowerCase()
                                .includes(customerSearch.toLowerCase()) ||
                            customers[key].address
                                .toLowerCase()
                                .includes(customerSearch.toLowerCase())
                        ) {
                            acc[key] = customers[key]
                        }
                        return acc
                    },
                    {}
                )
            }

            setFilteredCustomers(filteredCustomers)
        }
        filterCustomers()
    }, [
        customerSearch,
        searchActiveCustomers,
        searchPastDueInvoices,
        customers,
    ])

    return (
        <Box>
            {confirmDropCustomer && (
                <CustomPopover setPopoverVisible={setConfirmDropCustomer}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography variant="h6">
                            Are you sure you want to drop this customer?
                        </Typography>
                    </Box>

                    <Box display="flex" justifyContent="center">
                        <Box padding>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor:
                                        theme.palette.primary.backup,
                                    color: theme.palette.primary.textWhite,
                                }}
                                onClick={executeDropCustomer}
                            >
                                Confirm Drop
                            </Button>
                        </Box>
                        <Box padding>
                            <Button
                                variant="contained"
                                onClick={() => setConfirmDropCustomer(false)}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                    {statusMessage && <Success title={statusMessage} />}
                </CustomPopover>
            )}
            {confirmDeleteCustomer && (
                <CustomPopover setPopoverVisible={setConfirmDeleteCustomer}>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                    >
                        <Typography variant="h6">
                            Are you sure you want to delete this customer?
                        </Typography>
                    </Box>

                    <Box display="flex" justifyContent="center">
                        <Box padding>
                            <Button
                                variant="contained"
                                sx={{
                                    backgroundColor:
                                        theme.palette.primary.backup,
                                    color: theme.palette.primary.textWhite,
                                }}
                                onClick={executeDeleteCustomer}
                            >
                                Confirm Delete
                            </Button>
                        </Box>
                        <Box padding>
                            <Button
                                variant="contained"
                                onClick={() => setConfirmDeleteCustomer(false)}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Box>
                    {statusMessage && <Success title={statusMessage} />}
                </CustomPopover>
            )}

            {Object.keys(filteredCustomers).length === 0 ? (
                <Grid item xs={12}>
                    <Box
                        padding={2}
                        display="flex"
                        justifyContent="center"
                        width={'100%'}
                    >
                        <Typography
                            variant="body1"
                            color={theme.palette.primary.textWhite}
                        >
                            No results found
                        </Typography>
                    </Box>
                </Grid>
            ) : (
                Object.keys(filteredCustomers).map((key) => {
                    return (
                        <Grid
                            container
                            key={key}
                            display="flex"
                            alignItems="center"
                            justifyContent="space-between"
                            backgroundColor={
                                theme.palette.primary.supportingWhite
                            }
                            borderRadius={1}
                            marginBottom={1}
                        >
                            <Grid item xs={12} md={8}>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    width={'100%'}
                                    sx={{
                                        '&:hover': {
                                            cursor: 'pointer',
                                        },
                                    }}
                                    onClick={() => {
                                        setToCustomerDetail(customers[key])
                                    }}
                                >
                                    {customers[key]
                                        .has_finished_registration ? (
                                        <Box padding={1}>
                                            <Tooltip title="Registered">
                                                <CheckBox
                                                    sx={{
                                                        color: theme.palette
                                                            .primary
                                                            .successGreen,
                                                    }}
                                                />
                                            </Tooltip>
                                        </Box>
                                    ) : (
                                        <Box padding={1}>
                                            <Tooltip title="Not Registered">
                                                <NoAccounts
                                                    sx={{
                                                        color: theme.palette
                                                            .primary.errorRed,
                                                    }}
                                                />
                                            </Tooltip>
                                        </Box>
                                    )}
                                    <Box
                                        display="flex"
                                        flexDirection="column"
                                        alignItems="left"
                                    >
                                        <Typography variant="body1">
                                            {customers[key].first_name &&
                                            customers[key].last_name
                                                ? customers[key].first_name +
                                                  ' ' +
                                                  customers[key].last_name
                                                : 'No Name Found'}
                                        </Typography>
                                        <Typography variant="body2">
                                            {customers[key].address}
                                        </Typography>
                                        <Typography variant="body2">
                                            {customers[key].email}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <Box
                                    display="flex"
                                    justifyContent="flex-end"
                                    alignItems="right"
                                    padding={1}
                                >
                                    <Box padding>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            sx={{
                                                backgroundColor:
                                                    theme.palette.primary
                                                        .backup,
                                                color: theme.palette.primary
                                                    .textWhite,
                                            }}
                                            onClick={() => {
                                                setToCustomerDetail(
                                                    customers[key]
                                                )
                                            }}
                                        >
                                            Open
                                        </Button>
                                    </Box>
                                    <Box padding>
                                        <Button
                                            variant="contained"
                                            size="small"
                                            sx={{
                                                backgroundColor:
                                                    theme.palette.primary
                                                        .backup,
                                                color: theme.palette.primary
                                                    .textWhite,
                                            }}
                                            onClick={() => {
                                                window.open(
                                                    `http://maps.apple.com/?daddr=${customers[key].address}`
                                                )
                                            }}
                                        >
                                            Directions
                                        </Button>
                                    </Box>

                                    <Box padding>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            sx={{
                                                backgroundColor:
                                                    theme.palette.primary
                                                        .backup,
                                                color: theme.palette.primary
                                                    .textWhite,
                                            }}
                                            onClick={() => {
                                                handleDropCustomer(
                                                    customers[key]
                                                )
                                            }}
                                        >
                                            Drop
                                        </Button>
                                    </Box>
                                    <Box padding>
                                        <Button
                                            size="small"
                                            variant="contained"
                                            sx={{
                                                backgroundColor:
                                                    theme.palette.primary
                                                        .backup,
                                                color: theme.palette.primary
                                                    .textWhite,
                                            }}
                                            onClick={() => {
                                                handleDeleteCustomer(
                                                    customers[key]
                                                )
                                            }}
                                        >
                                            Delete
                                        </Button>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    )
                })
            )}
            <Box
                width={'100%'}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Typography
                    variant="h6"
                    color={theme.palette.primary.textWhite}
                >
                    Showing {Object.keys(filteredCustomers)?.length}{' '}
                    {Object.keys(filteredCustomers)?.length === 1
                        ? 'Customer'
                        : 'Customers'}
                </Typography>
            </Box>
        </Box>
    )
}
